import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import { Formik } from "formik";
import * as Yup from "yup";
import Error from "../templates/Errors";
import { Alert } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addFlashMessage } from "../../actions/flashMessages";
import { updateUserData } from "../../actions/userActions";
import { showPageLoader, hidePageLoader } from "../../actions/pageLoader";

let invalidDate = new Date("");
function parseDateFromFormats(formats, parseStrict) {
  return this.transform(function (value, originalValue) {
    if (this.isType(value)) return value;
    console.log(originalValue);
    value = Moment(originalValue, formats, parseStrict);

    return value.isValid() ? value.toDate() : invalidDate;
  });
}
Yup.addMethod(Yup.mixed, "format", parseDateFromFormats);

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Ce champ est obligatoire")
    .email("Entrer une adresse mail valide")
    .max(255, "Must be shorter than 255 characters"),
  username: Yup.string()
    .required("Ce champ est obligatoire")
    .max(160, "Must be shorter than 20 characters"),
  phone_number: Yup.string()
    .required("Ce champ est obligatoire")
    .matches(/^\d*(\.\d+)?$/, "Please entere numbers only")
    .length(10, "Doit contenir 10 chiffres"),
  gender: Yup.string().required("Ce champ est obligatoire"),
  city: Yup.number(),
  address_1: Yup.string()
    .min(0, "Votre mot de passe doit contenir au moins 5 caractères")
    .max(160, "Must be shorter than 80 characters"),
  introduction: Yup.string()
    .min(0, "Votre mot de passe doit contenir au moins 5 caractères")
    .max(600, "Must be shorter than 600 characters"),
  dob: Yup.mixed().format("DD-MM-YYYY", false),
});

class Editprofile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      showSuccess: false,
      goToProfile: false,
      startDate: null,
      image1Url: require("../../assets/images/defoul-photo.jpg"),
      userData: [],
      cities: [],
      dressSizes: [],
      shoeSizes: [],
      colors: [],
    };

    this.props.hidePageLoader();
  }

  checkboxChange(e) {
    this.setState({
      [e.target.name]: e.target.checked ? e.target.value : 0,
    });
  }

  changeImagePreview = (e) => {
    this.setState({
      [e.target.name + "Url"]: URL.createObjectURL(e.target.files[0]),
      [e.target.name + "value"]: this.toBase64(e.target.files[0]),
    });
  };

  setStartDate = (date) => {
    this.setState({
      startDate: date,
    });
  };

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
        this.setState({ image1value: encoded });
      };
      reader.onerror = (error) => reject(error);
    });

  componentDidMount() {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata) {
      let sessionData = udata;
      let dob = null;
      if (
        sessionData.user.dob != null &&
        sessionData.user.dob != "0000-00-00"
      ) {
        dob = new Date(sessionData.user.dob);
        console.log(sessionData.user.dob);
      }
      this.setState({
        userData: sessionData,
        image1Url: sessionData.user.profile_photo
          ? sessionData.user.profile_photo
          : this.state.image1Url,
        startDate: dob,
      });
    } else {
      this.setState({ isLoggedIn: false });
    }

    axios
      .get(window.BASE_API_URL + "public/users/profile_dropdowns")
      .then((response) => {
        this.setState({
          cities: response.data.cities,
          dressSizes: response.data.dress_sizes,
          shoeSizes: response.data.shoe_sizes,
          colors: response.data.colors,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (this.state.isLoggedIn === false) {
      return <Redirect to="/login" />;
    }

    if (this.state.goToProfile === true) {
      return <Redirect to="/profile" />;
    }

    const { cities, userData, dressSizes, shoeSizes } = this.state;
    let { user } = userData;

    if (user) {
      let { user_id } = user;
      return (
        <div>
          <div className="page-wrapper">
            <div className="user-my-address change-pass profile">
              <div className="container">
                <div className="checkout-right-white change-pass">
                  <Formik
                    initialValues={{
                      user_id: user_id,
                      //image1: "",
                      profile_photo: user.profile_photo,
                      dob:
                        user.dob != "null" && user.dob != "0000-00-00"
                          ? user.dob
                          : "",
                      email: user.email,
                      username: user.username,
                      phone_number: user.phone_number,
                      gender: user.gender,
                      city: user.city,
                      address_1: user.address_1,
                      introduction: user.introduction,
                      postcode: user.postcode,
                      size: user.size,
                      shoe_size: user.shoe_size,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);

                      this.props.showPageLoader();

                      values.image1 = this.state.image1value;
                      values.dob = this.state.startDate;

                      axios
                        .post(
                          window.BASE_API_URL + "public/users/update",
                          values
                        )
                        .then((response) => {
                          setSubmitting(false);

                          this.props.updateUserData({
                            userData: response.data,
                          });

                          this.props.addFlashMessage({
                            type: "success",
                            message:
                              "Votre profil a été mis à jour avec succès!",
                          });

                          this.setState(
                            {
                              showSuccess: true,
                              goToProfile: true,
                            },
                            () => this.props.hidePageLoader()
                          );
                        })
                        .catch((err) => {
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      isValid,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <input
                          type="hidden"
                          name="user_id"
                          id="user_id"
                          value={values.user_id}
                        />
                        <input
                          type="hidden"
                          name="profile_photo"
                          id="profile_photo"
                          value={values.profile_photo}
                        />
                        <div className="my-account-profile-img-block">
                          <div className="update-pic-btns">
                            <img
                              src={this.state.image1Url}
                              className="img-responsive img-preview"
                              alt="user-my-profile-defoul"
                            />

                            <div className="profi-delet-icon-circle">
                              <a
                                href="#"
                                className="icon-circpro-edit up-btn"
                                style={{ left: "0px" }}
                              >
                                <i className="fa fa-pencil"></i>

                                <input
                                  id="image1"
                                  name="image1"
                                  type="file"
                                  accept="image/*"
                                  className="attachment_upload"
                                  //value={values.image1}
                                  onChange={this.changeImagePreview}
                                  style={{ height: "40px" }}
                                />
                              </a>
                              <a
                                href="#!"
                                className="icon-circpro-delet"
                                onClick={() => {
                                  values.profile_photo = "";
                                  this.setState({
                                    image1Url: require("../../assets/images/defoul-photo.jpg"),
                                  });
                                }}
                                style={{ marginLeft: "75px" }}
                              >
                                <i className="fa fa-trash"></i>
                              </a>
                            </div>
                          </div>
                          <Error
                            touched={touched.image1}
                            message={errors.image1}
                            style={{ position: "relative", bottom: 0 }}
                          />
                        </div>
                        <div className="clearfix"></div>

                        <div className="">
                          <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                              <h4>Profile</h4>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group">
                                <div className="form-group-label">
                                  Adresse mail<span>*</span>
                                </div>
                                <input
                                  type="email"
                                  placeholder="Adresse mail"
                                  id="email"
                                  name="email"
                                  value={values.email}
                                  onChange={handleChange}
                                />
                                <Error
                                  touched={touched.email}
                                  message={errors.email}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group">
                                <div className="form-group-label">
                                  Nom d'utilisateur<span>*</span>
                                </div>
                                <input
                                  type="text"
                                  placeholder="Nom d'utilisateur"
                                  id="username"
                                  name="username"
                                  value={values.username}
                                  onChange={handleChange}
                                />
                                <Error
                                  touched={touched.username}
                                  message={errors.username}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group">
                                <div className="form-group-label">
                                  Numéro de téléphone<span>*</span>
                                </div>
                                <input
                                  type="text"
                                  id="phone_number"
                                  name="phone_number"
                                  value={values.phone_number}
                                  onChange={handleChange}
                                />
                                <Error
                                  touched={touched.phone_number}
                                  message={errors.phone_number}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group">
                                <div className="form-group-label">
                                  Genre<span>*</span>
                                </div>
                                <select
                                  id="gender"
                                  name="gender"
                                  value={values.gender}
                                  onChange={handleChange}
                                >
                                  <option value="">Genre</option>
                                  <option value="homme">Homme</option>
                                  <option value="femme">Femme</option>
                                </select>
                                <Error
                                  touched={touched.gender}
                                  message={errors.gender}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group location">
                                <div className="location-icon"></div>
                                <div className="form-group-label">
                                  Date de naissance
                                </div>
                                {/* <input
                                  type="text"
                                  id="dob"
                                  name="dob"
                                  value={values.dob}
                                  onChange={handleChange}
                                  placeholder="mm-dd-yyyy"
                                /> */}
                                <DatePicker
                                  selected={this.state.startDate}
                                  onChange={this.setStartDate}
                                  showYearDropdown
                                  dateFormatCalendar="MMMM"
                                  dateFormat="dd-MM-yyyy"
                                  maxDate={new Date()}
                                  yearDropdownItemNumber={60}
                                  scrollableYearDropdown
                                  id="dob"
                                  name="dob"
                                  value={this.state.startDate}
                                  placeholderText="dd-mm-yyyy"
                                  autoComplete="off"
                                />
                                <Error
                                  touched={touched.dob}
                                  message={errors.dob}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group location">
                            <div className="location-icon"></div>
                            <div className="form-group-label">
                              Description de votre dressing
                            </div>
                            <textarea
                              rows="2"
                              className="text-area"
                              id="introduction"
                              name="introduction"
                              value={values.introduction}
                              onChange={handleChange}
                            ></textarea>
                            <Error
                              touched={touched.introduction}
                              message={errors.introduction}
                            />
                          </div>

                          <div className="clearfix"></div>
                          <h4>Mes tailles</h4>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group location">
                                <div className="location-icon"></div>
                                <div className="form-group-label">
                                  Ma taille
                                </div>
                                <select
                                  id="size"
                                  name="size"
                                  value={values.size}
                                  onChange={handleChange}
                                >
                                  <option>Taille</option>
                                  {dressSizes.length
                                    ? dressSizes.map((size) => (
                                        <option
                                          value={size.value}
                                          key={size.size_id}
                                        >
                                          {size.value}
                                        </option>
                                      ))
                                    : null}
                                </select>
                                <Error
                                  touched={touched.size}
                                  message={errors.size}
                                />
                              </div>
                            </div>
                            {/* <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group location">
                                <div className="location-icon"></div>
                                <div className="form-group-label">
                                  Ma pointure
                                </div>
                                <select
                                  id="size2"
                                  name="size2"
                                  value={values.size2}
                                  onChange={handleChange}
                                >
                                  <option>Pointure</option>
                                  {dressSizes.length
                                    ? dressSizes.map((size) => (
                                        <option
                                          value={size.value}
                                          key={size.size_id}
                                        >
                                          {size.value}
                                        </option>
                                      ))
                                    : null}
                                </select>
                                <Error
                                  touched={touched.size2}
                                  message={errors.size2}
                                />
                              </div>
                            </div> */}
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group location">
                                <div className="location-icon"></div>
                                <div className="form-group-label">
                                  Ma pointure
                                </div>
                                <select
                                  id="shoe_size"
                                  name="shoe_size"
                                  value={values.shoe_size}
                                  onChange={handleChange}
                                >
                                  <option>Taille</option>
                                  {shoeSizes.length
                                    ? shoeSizes.map((size) => (
                                        <option
                                          value={size.value}
                                          key={size.size_id}
                                        >
                                          {size.value}
                                        </option>
                                      ))
                                    : null}
                                </select>
                                <Error
                                  touched={touched.shoe_size}
                                  message={errors.shoe_size}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <h4>Mon adresse</h4>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group location">
                                <div className="location-icon"></div>
                                <div className="form-group-label">Adresse</div>
                                <input
                                  type="text"
                                  id="address_1"
                                  name="address_1"
                                  value={values.address_1}
                                  onChange={handleChange}
                                />
                                <Error
                                  touched={touched.address_1}
                                  message={errors.address_1}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group location">
                                <div className="location-icon"></div>
                                <div className="form-group-label">Ville</div>
                                <select
                                  id="city"
                                  name="city"
                                  value={values.city}
                                  onChange={handleChange}
                                >
                                  <option value="">Ville</option>
                                  {cities.length
                                    ? cities.map((city) => (
                                        <option
                                          value={city.city_id}
                                          key={city.city_id}
                                        >
                                          {city.title}
                                        </option>
                                      ))
                                    : null}
                                </select>
                                <Error
                                  touched={touched.city}
                                  message={errors.city}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group location">
                                <div className="location-icon"></div>
                                <div className="form-group-label">
                                  Code postal
                                </div>
                                <input
                                  type="text"
                                  id="postcode"
                                  name="postcode"
                                  value={values.postcode}
                                  onChange={handleChange}
                                />
                                <Error
                                  touched={touched.postcode}
                                  message={errors.postcode}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="clearfix"></div>
                          {isValid == false && (
                            <Alert className="alert alert-danger">
                              Veuillez compléter les champs en rouge
                            </Alert>
                          )}
                          {this.state.showSuccess && (
                            <Alert
                              className="alert alert-success"
                              onClose={() =>
                                this.setState({ showSuccess: false })
                              }
                              dismissible
                            >
                              Détails enregistrés
                            </Alert>
                          )}
                          <div className="sign-up-button-main">
                            <button
                              className="sign-up-butt"
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Mettre à jour
                            </button>
                          </div>
                          {/* <div className="clearfix">&nbsp;</div>
                          <div>
                            {this.state.showSuccess && (
                              <Alert
                                className="alert alert-success"
                                onClose={() =>
                                  this.setState({ showSuccess: false })
                                }
                                dismissible
                              >
                                Details saved. Redirecting...
                              </Alert>
                            )}
                          </div> */}
                        </div>
                      </form>
                    )}
                  </Formik>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "page-loader " +
              (this.props.pageLoading.active === true ? "active" : "")
            }
          >
            <div className=" text-center">
              <i
                className="fa fa-spinner fa-spin"
                style={{ fontSize: "4em" }}
              ></i>
            </div>
          </div>
        </div>
      );
    } else
      return (
        <div>
          <div className="page-wrapper article-details">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 p-4 text-center">
                  <i
                    className="fa fa-spinner fa-spin"
                    style={{ fontSize: "4em" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

Editprofile.propTypes = {
  userData: PropTypes.array.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  updateUserData: PropTypes.func.isRequired,
  showPageLoader: PropTypes.func.isRequired,
  hidePageLoader: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
    pageLoading: state.pageLoaderReducer,
  };
};

export default connect(stateToProps, {
  addFlashMessage,
  updateUserData,
  showPageLoader,
  hidePageLoader,
})(Editprofile);
