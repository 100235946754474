import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import NumberFormat from "react-number-format";
import ImageGallery from "react-image-gallery";
import {
  Modal,
  Button,
  Alert,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import UserProfileCard from "../templates/UserProfileCard";
import Productbox from "../templates/Productbox";
import { addFlashMessage } from "../../actions/flashMessages";
import StepZilla from "react-stepzilla";
import PurchaseStep1 from "../templates/PurchaseStep1";
import PurchaseStep2 from "../templates/PurchaseStep2";
import PurchaseStep3 from "../templates/PurchaseStep3";
import TestStep1 from "../templates/TestStep1";
import "react-image-gallery/styles/css/image-gallery.css";

class Article extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      deleteModalVisible: false,
      purchaseModalVisible: false,
      goToProfile: false,
      likeDisabled: false,
      deleteOption: "",
      userId: 0,
      userInfo: [],
      articleData: [],
      cities: [],
      articlecode: "",
      slides: null,
      count: 1,
      totalLikes: 0,
      liked: 0,
      orderData: {},
      purchaseCompleted: false,
      showReportAlert: false,
      reported: false,
      reportLoading: false,
    };

    this.onChangeValue = this.setDeleteOption.bind(this);
  }

  componentDidMount() {
    let udata = [];
    let userId = 0;

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata.user) {
      userId = udata.user.user_id;
      this.setState({ userInfo: udata, userId: userId });
    } else {
      this.setState({ isLoggedIn: false });
    }

    window.scrollTo(0, 0);

    let articlecode = "";
    if (this.props.match && this.props.match.params.articlecode) {
      let articlecode = this.props.match.params.articlecode;
      this.setState({ articlecode: articlecode });

      //console.log("articlecode > " + articlecode);

      let artUrl = window.BASE_API_URL + "public/article/get/" + articlecode;

      if (userId) {
        artUrl += "/" + userId;
      }

      axios
        .get(artUrl)
        .then((response) => {
          this.setState({
            articleData: response.data,
            liked: response.data.article.liked
              ? response.data.article.liked
              : 0,
            totalLikes: response.data.article.total_likes,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }

    this.fetchCities();
  }

  componentWillReceiveProps(nextProps) {
    let udata = [];
    let userId = 0;

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata.user) {
      userId = udata.user.user_id;
      this.setState({ userInfo: udata, userId: userId });
    } else {
      this.setState({ isLoggedIn: false });
    }

    window.scrollTo(0, 0);

    let articlecode = "";
    if (nextProps.match && nextProps.match.params.articlecode) {
      let articlecode = nextProps.match.params.articlecode;
      this.setState({ articlecode: articlecode });

      //console.log("articlecode > " + articlecode);

      let artUrl = window.BASE_API_URL + "public/article/get/" + articlecode;

      if (userId) {
        artUrl += "/" + userId;
      }

      axios
        .get(artUrl)
        .then((response) => {
          this.setState({
            articleData: response.data,
            liked: response.data.article.liked
              ? response.data.article.liked
              : 0,
            totalLikes: response.data.article.total_likes,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  }

  setDeleteOption = (e) => {
    this.setState({ deleteOption: e.currentTarget.value });
  };

  deleteModalClosed = () => {
    this.setState({
      deleteModalVisible: false,
    });
  };

  purchaseModalClosed = () => {
    this.setState({
      purchaseModalVisible: false,
    });
  };

  setOrderData = (data) => {
    this.setState({ orderData: data });
  };

  deleteArticle = () => {
    let artDelUrl =
      window.BASE_API_URL + "public/article/delete/" + this.state.articlecode;
    axios
      .delete(artDelUrl, {
        headers: {
          Authorization: this.state.userInfo.auth_key,
        },
      })
      .then((response) => {
        this.setState({ goToProfile: true });

        this.props.addFlashMessage({
          type: "success",
          message: "L'article est supprimé!",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  like = (e) => {
    e.preventDefault();

    if (this.state.likeDisabled === false) {
      this.setState({ likeDisabled: true });
      let udata = [];
      let followUrl = window.BASE_API_URL + "public/article/like";

      if (this.props.userData.length > 0) {
        udata = this.props.userData[0].userData;
      }

      if (udata.user) {
        let data = {
          user_id: udata.user.user_id,
          product_code: this.state.articlecode,
        };

        axios
          .post(followUrl, data, {
            headers: {
              Authorization: udata.auth_key,
            },
          })
          .then((response) => {
            if (response.data.status == "ok" && response.data.likes) {
              this.setState({
                totalLikes: response.data.likes.total,
                liked: 1,
                likeDisabled: false,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    }
  };

  unlike = (e) => {
    e.preventDefault();

    if (this.state.likeDisabled === false) {
      this.setState({ likeDisabled: true });
      let udata = [];
      let followUrl = window.BASE_API_URL + "public/article/unlike";

      if (this.props.userData.length > 0) {
        udata = this.props.userData[0].userData;
      }

      if (udata.user) {
        let data = {
          user_id: udata.user.user_id,
          product_code: this.state.articlecode,
        };

        axios
          .post(followUrl, data, {
            headers: {
              Authorization: udata.auth_key,
            },
          })
          .then((response) => {
            if (response.data.status == "ok" && response.data.likes) {
              this.setState({
                totalLikes: response.data.likes.total,
                liked: 0,
                likeDisabled: false,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    }
  };

  checkSteps = (step) => {
    console.log(step);

    if (step == 2) {
      this.setState({ purchaseCompleted: true });
    } else {
      this.setState({ purchaseCompleted: false });
    }

    return false;
  };

  showReportAlert = () => {
    this.setState({ showReportAlert: true });
  };

  hideReportAlert = () => {
    this.setState({ showReportAlert: false });
  };

  reportThisArticle = () => {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
      this.setState({ reportLoading: true });

      axios
        .post(
          window.BASE_API_URL + "public/article/report",
          {
            article_code: this.state.articlecode,
          },
          {
            headers: {
              Authorization: udata.auth_key,
            },
          }
        )
        .then((response) => {
          this.setState({
            reportLoading: false,
            reported: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  fetchCities = () => {
    if (!this.state.cities.length) {
      axios
        .get(window.BASE_API_URL + "public/users/cities")
        .then((response) => {
          this.setState({
            cities: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    if (this.state.goToProfile === true) {
      return <Redirect to="/profile" />;
    }

    if (this.state.articleData) {
      const { articleData } = this.state;
      const { article, related_products } = articleData;
      let profileIcon = (
        <i
          className="fa fa-user-circle"
          style={{ fontSize: "2em", position: "absolute" }}
        ></i>
      );
      let encodeUserID = "";

      const galImages = [];
      if (articleData.images) {
        articleData.images.map((image, index) => {
          galImages.push({ original: image, thumbnail: image });
        });
      }

      if (article) {
        if (article.profile_photo)
          profileIcon = <img src={article.profile_photo} alt="" />;
        encodeUserID = new Buffer(article.user_id).toString("base64");

        let user = [];

        if (this.state.userInfo.user) {
          user = this.state.userInfo.user;
        }

        let likeIcon = (
          <div className="wishlist-cfa">
            <a onClick={this.like}>
              <i className="fa fa-heart"></i>
              <span>
                {this.state.totalLikes
                  ? this.state.totalLikes
                  : article.total_likes}
              </span>
            </a>
          </div>
        );

        if (this.state.liked == 1) {
          likeIcon = (
            <div className="wishlist-cfa">
              <a className="active" onClick={this.unlike}>
                <i className="fa fa-heart"></i>
                <span>
                  {this.state.totalLikes
                    ? this.state.totalLikes
                    : article.total_likes}
                </span>
              </a>
            </div>
          );
        }

        const steps = [
          {
            name: "Informations personnelles",
            component: (
              <PurchaseStep1
                setOrderData={this.setOrderData}
                orderData={this.state.orderData}
                userData={this.state.userInfo}
                cities={this.state.cities}
              />
            ),
          },
          {
            name: "Résumé de la commande",
            component: (
              <PurchaseStep2
                orderData={this.state.orderData}
                userData={this.state.userInfo}
                articlecode={this.state.articlecode}
                article={article}
              />
            ),
          },
          {
            name: "Confirmation",
            component: (
              <PurchaseStep3
                orderData={this.state.orderData}
                userData={this.state.userInfo}
                purchaseModalClosed={this.purchaseModalClosed}
                article={article}
              />
            ),
          },
        ];

        return (
          <div>
            <div className="page-wrapper article-details">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 col-md-7 col-lg-8 col-xl-9">
                    <div className="menu-details-wrapper">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-7">
                          <div className="menu-img">
                            <div className="product-image-slider">
                              <ImageGallery
                                items={galImages}
                                useBrowserFullscreen={false}
                                showPlayButton={false}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                          <div className="menu-details">
                            <div className="category-block">
                              <div className="profile-avr-hm">
                                <h3>{article && article.title}</h3>
                              </div>
                              {likeIcon}
                            </div>
                            <h4>{article && article.brand_id}</h4>
                            <div className="rev-block">
                              <div className="price">
                                <NumberFormat
                                  value={article && article.sale_price}
                                  displayType={"text"}
                                  thousandSeparator=" "
                                  className="mainPrice"
                                  renderText={(value) => <>{value} DA</>}
                                />{" "}
                                {article && article.buying_price > 0 ? (
                                  <NumberFormat
                                    value={article.buying_price}
                                    displayType={"text"}
                                    thousandSeparator=" "
                                    className="markedPrice"
                                    renderText={(value) => (
                                      <span>{value} DA</span>
                                    )}
                                  />
                                ) : null}
                              </div>

                              <div className="dev-info dev-description">
                                Description du produit:
                                <span>{article && article.description}</span>
                              </div>
                              <div className="dev-info">
                                Ajouté:
                                <span>{article.readable_time}</span>
                              </div>
                              <div className="dev-info">
                                Type:
                                <span>
                                  {article.type == "new" ? "Neuf" : "Occasion"}
                                </span>
                              </div>
                              <div className="dev-info">
                                Taille:
                                <span>{article.size}</span>
                              </div>
                              <div className="dev-info">
                                Ville:
                                <span>{article.city_name}</span>
                              </div>
                              <div className="dev-info">
                                Quantité:
                                <span>{article.quantity}</span>
                              </div>
                              {article && article.type == "used" ? (
                                <div className="dev-info">
                                  Etat:
                                  <span>{article && article.condition}</span>
                                </div>
                              ) : null}
                              <div className="dev-info">
                                Couleur:
                                <span>{article && article.color}</span>
                              </div>
                              <div className="dev-info">
                                Échange:
                                <span>
                                  {article && article.accept_exchange == 1
                                    ? "J’accepte l’échange"
                                    : "N/A"}
                                </span>
                              </div>
                              <div className="dev-info">
                                Livraison:
                                <span>
                                  {article &&
                                  article.accept_sending_by_post == 1 ? (
                                    <>{`J'assure la livraison`}</>
                                  ) : (
                                    ""
                                  )}
                                  {article && article.recover_by_buyer == 1 ? (
                                    <>{`Produits à récupérer par l'achteur`}</>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>

                              <div className="dev-info">
                                Détail de la livraison:{" "}
                                <span>
                                  {article && article.recovery_conditions != ""
                                    ? article.recovery_conditions
                                    : "N/A"}
                                </span>
                              </div>

                              <div className="detail-but">
                                {article.status == "active" &&
                                article &&
                                article.user_id == this.state.userId ? (
                                  <>
                                    <a
                                      onClick={() => {
                                        this.setState({
                                          deleteModalVisible: true,
                                        });
                                      }}
                                      className="order-now-btn"
                                    >
                                      <i className="fa fa-trash"></i> Supprimer
                                    </a>
                                    <Link
                                      to={
                                        "/editarticle/" + this.state.articlecode
                                      }
                                      className="order-now-btn"
                                    >
                                      <i className="fa fa-edit"></i> Editer le
                                      produit
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <a
                                      onClick={() => {
                                        this.setState({
                                          purchaseModalVisible: true,
                                        });
                                      }}
                                      className="order-now-btn"
                                    >
                                      <i className="fa fa-shopping-bag"></i>{" "}
                                      Acheter
                                    </a>
                                    {this.state.userId ? (
                                      <a href="#!" className="order-now-btn">
                                        <i className="fa fa-envelope"></i>{" "}
                                        Contacter
                                      </a>
                                    ) : (
                                      <a href="#!" className="order-now-btn">
                                        <i className="fa fa-envelope"></i>{" "}
                                        Message
                                      </a>
                                    )}
                                  </>
                                )}
                                {article.status == "active" &&
                                  this.state.userInfo.user_id !=
                                    article.user_id && (
                                    <DropdownButton
                                      title=""
                                      id="bg-nested-dropdown"
                                      variant="light"
                                      className="profile-opts"
                                      size="sm"
                                    >
                                      <Dropdown.Item
                                        eventKey="1"
                                        onClick={this.showReportAlert}
                                      >
                                        <i className="far fa-question-circle"></i>{" "}
                                        Signaler cet article
                                      </Dropdown.Item>
                                    </DropdownButton>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="description-info">
                        <h6>Description du produit</h6>
                        <p>{article && renderHTML(article.description)}</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3">
                    <UserProfileCard userInfo={articleData.user} />
                  </div>
                </div>
                <div className="description-info mmlr">
                  <h6>Articles similaires</h6>
                  <div className="row">
                    {related_products ? (
                      related_products.map((product, index) => (
                        <div
                          className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 mplr"
                          key={index}
                        >
                          <Productbox productData={product} boxkey={index} />
                        </div>
                      ))
                    ) : (
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mplr">
                        No related article found
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Modal
              show={this.state.deleteModalVisible}
              onHide={this.deleteModalClosed}
              className="delete-modal"
              centered
            >
              <Modal.Body>
                <div>&nbsp;</div>
                <h5 style={{ textAlign: "center" }}>
                  Êtes-vous sûr de supprimer définitivement cet article?
                </h5>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={this.deleteArticle}>
                  Supprimer définitivement !
                </Button>
                <Button variant="secondary" onClick={this.deleteModalClosed}>
                  Annuler
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={this.state.purchaseModalVisible}
              onHide={this.purchaseModalClosed}
              className="purchase-modal"
              centered
            >
              <Modal.Header closeButton>Commande</Modal.Header>
              <Modal.Body
                className={this.state.purchaseCompleted && "order-completed"}
              >
                <h5 style={{ textAlign: "center" }}>
                  Payez en espèce à la réception de votre colis
                </h5>
                <div className="step-progress">
                  <StepZilla
                    steps={steps}
                    prevBtnOnLastStep={false}
                    onStepChange={(step) => {
                      return this.checkSteps(step);
                    }}
                    showNavigation={false}
                    hocValidationAppliedTo={[0]}
                  />
                </div>
              </Modal.Body>
            </Modal>

            <Modal
              show={this.state.showReportAlert}
              onHide={this.hideReportAlert}
              className="report-modal"
              id="report-modal"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title>Signalement</Modal.Title>
              </Modal.Header>
              <Modal.Body id="modal-body">
                {this.state.reported === false ? (
                  <>
                    <p>
                      Vous pouvez nous signaler un article dont le contenu est
                      inapproprié et non conforme avec les conditions
                      d'utilisation de DZDressing.
                    </p>
                    <p>
                      Etes-vous sûre de vouloir le signaler à l'équipe de
                      DZDressing?
                    </p>
                  </>
                ) : (
                  <Alert variant="success">
                    L'article est signalé. Notre équipe enquêtera pour cela.
                  </Alert>
                )}
              </Modal.Body>
              <Modal.Footer>
                {this.state.reported === false ? (
                  <>
                    <Button variant="primary" onClick={this.reportThisArticle}>
                      Oui, je veux le signaler
                    </Button>
                    <Button variant="light" onClick={this.hideReportAlert}>
                      Non
                    </Button>
                  </>
                ) : (
                  <Button variant="secondary" onClick={this.hideReportAlert}>
                    Annuler
                  </Button>
                )}
              </Modal.Footer>
            </Modal>
          </div>
        );
      } else {
        return (
          <div>
            <div className="page-wrapper article-details">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 p-4 text-center">
                    <i
                      className="fa fa-spinner fa-spin"
                      style={{ fontSize: "4em" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return <div>No article found</div>;
    }
  }
}

Article.propTypes = {
  userData: PropTypes.array.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, { addFlashMessage })(Article);
