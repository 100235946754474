import {
  ADD_USER_DATA,
  DELETE_USER_DATA,
  UPDATE_USER_DATA,
} from "../actions/types";
import shortid from "shortid";
import findIndex from "lodash/findIndex";

export default (state = [], action = {}) => {
  switch (action.type) {
    case ADD_USER_DATA:
      return [
        ...state,
        {
          id: shortid.generate(),
          userData: action.data.userData,
        },
      ];

    case UPDATE_USER_DATA:
      return [
        {
          id: shortid.generate(),
          userData: action.data.userData,
        },
      ];

    case DELETE_USER_DATA:
      const index = findIndex(state, { id: action.id });
      if (index >= 0) {
        return [...state.slice(0, index), ...state.slice(index + 1)];
      }
      return state;

    default:
      return state;
  }
  return state;
};
