import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import UserProfileCard from "../templates/UserProfileCard";
import UserDetailsCard from "../templates/UserDetailsCard";
import Profiletab from "../templates/Profiletab";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      noUser: false,
      userId: 0,
      userInfo: [],
      userData: [],
    };
  }

  componentDidMount() {
    this.setProfile(this.props);

    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(newProps) {
    this.setProfile(newProps);

    window.scrollTo(0, 0);
  }

  resetProfile = () => {
    this.setProfile(this.props);
  };

  setProfile = (spProps) => {
    let udata = [];

    if (spProps.userData.length > 0) {
      udata = spProps.userData[0].userData;
    }

    if (udata) {
      /* if (sessionStorage.getItem("userData")) {
      udata = JSON.parse(sessionStorage.getItem("userData")); */
    } else {
      this.setState({ isLoggedIn: false });
    }

    if (udata) {
      let userID;
      if (spProps.match && spProps.match.params.profile_id) {
        let profile_id = spProps.match.params.profile_id;
        //let encodeUserID = new Buffer(profile_id).toString("base64");
        userID = new Buffer.from(profile_id, "base64").toString("ascii");
      } else {
        if (udata.user) userID = udata.user.user_id;
      }

      if (!isNaN(userID)) {
        axios
          .get(
            window.BASE_API_URL +
              "public/users/profile/" +
              userID +
              (udata.user ? "/" + udata.user.user_id : "")
          )
          .then((response) => {
            this.setState({
              userData: response.data,
              userId: userID,
            });
          })
          .catch((err) => {
            console.log(err);
            this.setState({ noUser: true });
          });
      } else {
        console.log("userId");
        console.log(userID);
      }
    } else {
      console.log("udata.user");
      console.log(udata.user);
      //this.setState({ noUser: true });
    }
  };

  render() {
    if (this.state.noUser === true) {
      return <Redirect to="/home" />;
    }

    //console.log(sessionStorage.getItem("userData"));
    const userData = this.state.userData; //JSON.parse(sessionStorage.getItem("userData"));

    if (userData.user) {
      return (
        <div>
          <div className="page-wrapper shop-profile">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3">
                  <UserProfileCard userInfo={this.state.userData.user} />
                </div>
                <div className="col-sm-12 col-md-7 col-lg-6 col-xl-7">
                  <UserDetailsCard userInfo={this.state.userData.user} />
                </div>
                <div className="col-sm-12">
                  <Profiletab
                    userInfo={this.state.userData.user}
                    resetProfile={this.resetProfile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div className="page-wrapper shop-profile">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 p-4 text-center">
                  <i
                    className="fa fa-spinner fa-spin"
                    style={{ fontSize: "4em" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

Profile.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Profile);
