import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Link, Redirect, Route } from "react-router-dom";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import * as QueryString from "query-string";
import Notifications from "./Notifications";
import { deleteUserData } from "../../../actions/userActions";

class TopBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      showSearchBar: false,
      menuStructure: "",
      userData: [],
      visible: false,
      notificationVisible: false,
      sidebarActive: false,
      userMenuActive: true,
      redirectToSearch: false,
      searchQr: "",
      activeMobMenu: "user",
      searchType: "article",
      showSubMenu: "",
      notificationsCount: 0,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setSearchRef = this.setSearchRef.bind(this);
    this.setSubmenuNewRef = this.setSubmenuNewRef.bind(this);
    this.setSubmenuUsedRef = this.setSubmenuUsedRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleMenuOver = this.handleMenuOver.bind(this);
    this.setSearchQuery = this.setSearchQuery.bind(this);
    this.checkSearchSubmit = this.checkSearchSubmit.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.getItem("userData")) {
      this.setState({
        isLoggedIn: false,
        userData: JSON.parse(sessionStorage.getItem("userData")),
      });
    }

    //get colors
    axios
      .get(window.BASE_API_URL + "public/categories/menu_categories")
      .then((response) => {
        this.setState({
          menuStructure: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mouseover", this.handleMenuOver);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mouseover", this.handleMenuOver);
  }

  async getNotificCounts() {
    axios
      .get(window.BASE_API_URL + "public/users/notification_counts")
      .then((response) => {
        this.setState({
          notificationsCount: response.notificationsCount,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  setSearchRef(node) {
    this.searchRef = node;
  }

  setSubmenuNewRef(node) {
    this.subMenuNewRef = node;
  }

  setSubmenuUsedRef(node) {
    this.subMenuUsedRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ visible: false });
    }

    if (this.searchRef && !this.searchRef.contains(event.target)) {
      this.setState({ showSearchBar: false });
    }
  }

  handleMenuOver = (event) => {
    if (this.subMenuNewRef && this.subMenuNewRef.contains(event.target)) {
      this.setState({ showSubMenu: "jdid-menu" });
    } else if (
      this.subMenuUsedRef &&
      this.subMenuUsedRef.contains(event.target)
    ) {
      this.setState({ showSubMenu: "ocaz-menu" });
    } else {
      this.setState({ showSubMenu: "" });
    }
  };

  closeMegaMenu = () => {
    this.setState({ showSubMenu: "" });
  };

  toggleMenu = () => {
    this.setState({ visible: !this.state.visible });
  };

  toggleSearchBar = () => {
    this.setState({ showSearchBar: !this.state.showSearchBar });
  };

  openNotifications = () => {
    this.setState({ notificationVisible: true });
  };

  closeNotifications = () => {
    this.setState({ notificationVisible: false });
  };

  openSidebar = () => {
    this.setState({ sidebarActive: true });
    console.log("opensidebar");
  };

  closeSidebar = () => {
    this.setState({ sidebarActive: false });
  };

  openUserToggle = () => {
    this.setState({ userMenuActive: !this.state.userMenuActive });
  };

  redirectToSearch = () => {
    this.setState({ redirectToSearch: true }, () => {
      setTimeout(() => this.setState({ searchQr: "" }), 1000);
    });
  };

  setSearchQuery = (e) => {
    this.setState({ searchQr: e.currentTarget.value });
  };

  setActiveMobMenu = (menu) => {
    let activeMenu = menu;

    if (this.state.activeMobMenu == menu) activeMenu = "";

    this.setState({ activeMobMenu: activeMenu });
  };

  setSearchType = (e) => {
    this.setState({ searchType: e.currentTarget.value });
  };

  checkSearchSubmit = (e) => {
    if (e.keyCode == 13) {
      this.redirectToSearch();
    }
  };

  render() {
    let loggedinMenu = "";

    if (this.state.redirectToSearch === true) {
      this.setState({
        searchType: "articles",
        redirectToSearch: false,
        showSearchBar: false,
      });

      if (this.state.searchType == "users") {
        return <Redirect exact to={"/vendors?qr=" + this.state.searchQr} />;
      } else {
        return (
          <Redirect
            exact
            to={"/search?qr=" + this.state.searchQr + "#topbar"}
          />
        );
      }
    }

    //const { userData } = this.state;
    const { userData } = this.props;

    let udata = [];
    /* if (sessionStorage.getItem("userData")) {
      udata = JSON.parse(sessionStorage.getItem("userData"));
    } */
    if (userData.length > 0) {
      udata = userData[0].userData;
    }

    if (userData.length > 0) {
      let user = userData[0].userData.user;

      if (user) {
        loggedinMenu = (
          <>
            <div className="top-head-icon">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-favorite">Mes favoris</Tooltip>}
              >
                <Link to={"/favorite"} className="top-icon-mhide">
                  <i className="fal fa-heart"></i>
                </Link>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="tooltip-favorite">Notification</Tooltip>}
              >
                <a
                  onClick={this.openNotifications}
                  className="top-icon-mhide"
                  class="notification-icon"
                >
                  <i className="fal fa-bell"></i>
                  {this.state.notificationsCount > 0 && (
                    <span>{this.state.notificationsCount}</span>
                  )}
                </a>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id={`tooltip-favorite`}>Message</Tooltip>}
              >
                <a className="top-icon-mhide">
                  <i className="far fa-envelope"></i>
                </a>
              </OverlayTrigger>
              <a
                href="#!"
                onClick={this.openNotifications}
                className="pc-hide notification-icon"
              >
                <i className="fal fa-bell"></i>
                {this.state.notificationsCount > 0 && (
                  <span>{this.state.notificationsCount}</span>
                )}
              </a>
            </div>
            <div
              className="head-btn-je-vends top-icon-mhide"
              style={{ marginRight: "6px" }}
            >
              <Link to={"/addarticle"}>+ Je vends</Link>
            </div>
            <ul className="user-down-menu">
              <li ref={this.setWrapperRef}>
                <span className="profile-img-header user-manu-but">
                  <a onClick={this.toggleMenu}>
                    {user.profile_photo ? (
                      <img
                        src={user.profile_photo}
                        alt="inner-header-user-pic"
                      />
                    ) : (
                      <i
                        className="fas fa-user-circle"
                        style={{
                          display: "block",
                          fontSize: "1.8em",
                          padding: "5px 0 0 8px",
                          color: "#fff",
                        }}
                      ></i>
                    )}
                  </a>
                  <i className="fa fa-angle-down" onClick={this.toggleMenu}></i>
                </span>
                <ul
                  className="dropdown-menu user-manu-block"
                  style={{ display: this.state.visible ? "block" : "none" }}
                >
                  <li>
                    <Link to="/profile" onClick={this.toggleMenu}>
                      <i className="fas fa-store"></i>Mon dressing
                    </Link>
                  </li>
                  <li>
                    <Link to="/sales" onClick={this.toggleMenu}>
                      <i className="fas fa-shopping-bag"></i>Mes ventes
                    </Link>
                  </li>
                  <li>
                    <Link to="/orders" onClick={this.toggleMenu}>
                      <i className="fas fa-shopping-cart"></i>Mes achats
                    </Link>
                  </li>
                  <li>
                    <Link to="/editprofile" onClick={this.toggleMenu}>
                      <i className="fas fa-user-edit"></i>Editer le profil
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={this.toggleMenu}>
                      <i className="fas fa-phone-volume"></i>Contactez-nous
                    </Link>
                  </li>
                  <li>
                    <Link to="/logout" onClick={this.toggleMenu}>
                      <i className="fas fa-unlock-alt"></i>Déconnexion
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
            <Modal
              show={this.state.notificationVisible}
              onHide={this.closeNotifications}
              className="notificaton-modal"
            >
              <Modal.Header closeButton>
                <Modal.Title>Notifications</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Notifications />
              </Modal.Body>
            </Modal>
          </>
        );
      }
    }

    const { category_structur } = this.state.menuStructure;

    return (
      <div>
        <div className="header header-home">
          <span className="menu-icon" onClick={this.openSidebar}>
            <i className="far fa-bars"></i>
          </span>
          <div className="logo">
            <button className="mob-search-btn" onClick={this.toggleSearchBar}>
              <i className="fa fa-search"></i>
            </button>
            <Link to={"/home"}>
              <img
                src={require("../../../assets/images/dz-adm-logo.png")}
                width=""
                height=""
                alt="logo"
              />
            </Link>
          </div>

          <div className="header-right-side">
            <div
              className={
                "top-search-wrapper" +
                (this.state.showSearchBar === true ? " active" : "")
              }
              ref={this.setSearchRef}
            >
              <div className="icon-left-hm-head"></div>
              <input
                type="text"
                placeholder="Chercher par article, marque..."
                onChange={this.setSearchQuery}
                onKeyDown={this.checkSearchSubmit}
              />
              <select
                className="search-type"
                name="search_type"
                id="search_type"
                onChange={this.setSearchType}
              >
                <option value="articles">Articles</option>
                <option value="users">Vendeurs</option>
              </select>
              <a onClick={this.redirectToSearch}>
                <button className="search-btn">
                  <span>Chercher</span>
                  <i className="fas fa-search"></i>
                </button>
              </a>
            </div>
            <div className="sign-but">
              <Link to={"/login"}>S'inscrire/Se connecter</Link>
            </div>
            <div className="sign-but-mob">
              <Link to={"/login"}>
                <i className="fas fa-user"></i>
              </Link>
            </div>
            {loggedinMenu}
          </div>
        </div>
        <div
          className={
            "top-menu " + (+this.state.sidebarActive == true ? "act-menu" : "")
          }
        >
          <div className="container">
            <div className="sidenav" id="mySidenav">
              <a className="closebtn" onClick={this.closeSidebar}>
                <i className="fal fa-angle-left"></i>
              </a>
              <div className="main-menu mobile-menu">
                <ul>
                  <li
                    className={
                      "sub-menu user-name-mobile profile-img-mob " +
                      (this.state.activeMobMenu == "user" ? "menu-active" : "")
                    }
                  >
                    <a
                      className="user-pic-mobileA"
                      onClick={() => this.setActiveMobMenu("user")}
                    >
                      {udata.user && udata.user.profile_photo ? (
                        <img
                          src={udata.user.profile_photo}
                          alt="inner-header-user-pic"
                        />
                      ) : (
                        <i
                          className="fas fa-user-circle"
                          style={{
                            display: "block",
                            fontSize: "1.8em",
                            padding: "5px 10px 0px 0px",
                            color: "#fff",
                          }}
                        ></i>
                      )}
                      Hello{" "}
                      {udata.user && udata.user.username
                        ? udata.user.username
                        : null}
                      !
                      <i
                        className="fal fa-angle-right"
                        style={{
                          position: "absolute",
                          right: "12px",
                          color: "#fff",
                          fontSize: "1.3em",
                        }}
                      ></i>
                      <i
                        className="fal fa-angle-down"
                        style={{
                          position: "absolute",
                          right: "12px",
                          color: "#fff",
                          fontSize: "1.3em",
                        }}
                      ></i>
                    </a>
                    <ul
                      className={
                        "su-menu " +
                        (this.state.activeMobMenu == "user"
                          ? "menu-active"
                          : "")
                      }
                    >
                      <li className="al-menu" onClick={this.closeSidebar}>
                        <Link to="/profile">
                          <i className="fas fa-store"></i>Mon dressing
                        </Link>
                      </li>
                      <li>
                        <Link to="/favorite" onClick={this.closeSidebar}>
                          <i className="fas fa-heart"></i>&nbsp; Mes favoris
                        </Link>
                      </li>
                      <li>
                        <a href="#!">
                          <i className="fas fa-envelope"></i>&nbsp; Mes messages
                        </a>
                      </li>
                      <li className="al-menu" onClick={this.closeSidebar}>
                        <Link to="/sales">
                          <i className="fas fa-shopping-bag"></i>Mes ventes
                        </Link>
                      </li>
                      <li className="al-menu" onClick={this.closeSidebar}>
                        <Link to="/orders">
                          <i className="fas fa-shopping-cart"></i>Mes achats
                        </Link>
                      </li>
                      <li className="al-menu" onClick={this.closeSidebar}>
                        <Link to="/editprofile">
                          <i className="fas fa-user-edit"></i>Editer le profil
                        </Link>
                      </li>
                      <li className="al-menu" onClick={this.closeSidebar}>
                        <Link to="/contact">
                          <i className="fas fa-phone-volume"></i>Contactez-nous
                        </Link>
                      </li>
                      <li className="al-menu" onClick={this.closeSidebar}>
                        <Link to={"/logout"}>
                          <i className="fas fa-unlock-alt"></i>Déconnexion
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="m-login back-come" onClick={this.closeSidebar}>
                    <Link to={"/login"}>
                      S'inscrire / Se connecter<i className="fas fa-lock"></i>
                    </Link>
                  </li>
                  <li className="m-login" onClick={this.closeSidebar}>
                    <Link to={"/addarticle"}>
                      + Je vends un article
                      <i className="fas fa-plus-circle"></i>
                    </Link>
                  </li>

                  <li className="sub-menu">
                    <a onClick={() => this.setActiveMobMenu("homme")}>
                      Homme
                      <i
                        className={
                          "fal fa-angle-" +
                          (this.state.activeMobMenu == "homme"
                            ? "down"
                            : "right")
                        }
                      ></i>
                    </a>
                    <ul
                      className={
                        "su-menu" +
                        (this.state.activeMobMenu == "homme"
                          ? " menu-active"
                          : "")
                      }
                    >
                      {category_structur &&
                        category_structur.homme.map((cates, index) => {
                          let qrParams = {
                            gender: "homme",
                            cat_ids: [],
                          };

                          cates.sub_categories.map((scate, index) => {
                            qrParams.cat_ids.push(scate.cat_id);
                          });

                          let urlParams = QueryString.stringify(qrParams, {
                            arrayFormat: "bracket",
                          });

                          return (
                            <li key={index}>
                              <Link
                                to={{
                                  pathname: "/search",
                                  search: "?" + urlParams,
                                  hash: "topbar",
                                }}
                                onClick={this.closeSidebar}
                              >
                                {cates.details.title}
                                <img
                                  src={cates.details.icon}
                                  className="menu-acc"
                                  alt={cates.details.title}
                                />
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                  <li className="sub-menu">
                    <a onClick={() => this.setActiveMobMenu("femme")}>
                      Femme
                      <i
                        className={
                          "fal fa-angle-" +
                          (this.state.activeMobMenu === "femme"
                            ? "down"
                            : "right")
                        }
                      ></i>
                    </a>
                    <ul
                      className={
                        "su-menu" +
                        (this.state.activeMobMenu === "femme"
                          ? " menu-active"
                          : "")
                      }
                    >
                      {category_structur &&
                        category_structur.femme.map((cates, index) => {
                          let qrParams = {
                            gender: "femme",
                            cat_ids: [],
                          };

                          cates.sub_categories.map((scate, index) => {
                            qrParams.cat_ids.push(scate.cat_id);
                          });

                          let urlParams = QueryString.stringify(qrParams, {
                            arrayFormat: "bracket",
                          });

                          return (
                            <li key={index}>
                              <Link
                                to={{
                                  pathname: "/search",
                                  search: "?" + urlParams,
                                  hash: "topbar",
                                }}
                                onClick={this.closeSidebar}
                              >
                                {cates.details.title}
                                <img
                                  src={cates.details.icon}
                                  className="menu-acc"
                                  alt={cates.details.title}
                                />
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                  <li className="sub-menu">
                    <a onClick={() => this.setActiveMobMenu("enfant")}>
                      Enfant
                      <i
                        className={
                          "fal fa-angle-" +
                          (this.state.activeMobMenu === "enfant"
                            ? "down"
                            : "right")
                        }
                      ></i>
                    </a>
                    <ul
                      className={
                        "su-menu" +
                        (this.state.activeMobMenu === "enfant"
                          ? " menu-active"
                          : "")
                      }
                    >
                      {category_structur &&
                        category_structur.enfant.map((cates, index) => {
                          let qrParams = {
                            gender: "enfant",
                            cat_ids: [],
                          };

                          cates.sub_categories.map((scate, index) => {
                            qrParams.cat_ids.push(scate.cat_id);
                          });

                          let urlParams = QueryString.stringify(qrParams, {
                            arrayFormat: "bracket",
                          });

                          return (
                            <li key={index}>
                              <Link
                                to={{
                                  pathname: "/search",
                                  search: "?" + urlParams,
                                  hash: "topbar",
                                }}
                                onClick={this.closeSidebar}
                              >
                                {cates.details.title}
                                <img
                                  src={cates.details.icon}
                                  className="menu-acc"
                                  alt={cates.details.title}
                                />
                              </Link>
                            </li>
                          );
                        })}
                    </ul>
                  </li>
                  {/* <li className="sub-menu">
                    <Link
                      to={{
                        pathname: "/search",
                        search: "?type=new",
                        hash: "topbar",
                      }}
                      onClick={this.closeSidebar}
                    >
                      Nouveautes
                    </Link>
                  </li>
                  <li className="sub-menu">
                    <Link
                      to={{
                        pathname: "/search",
                        search: "?sort=most_loved",
                        hash: "topbar",
                      }}
                      onClick={this.closeSidebar}
                    >
                      Les coups de coeur<i className="fa fa-heart"></i>
                    </Link>
                  </li> */}
                </ul>
              </div>

              <div className="main-menu desktop-menu">
                <ul>
                  {/* <li className="home-menu">
                    <Link to={"/home"}>Home</Link>
                  </li> */}
                  <li
                    className="mega-menu sub-menu"
                    id="jdid-menu"
                    ref={this.setSubmenuNewRef}
                  >
                    <Link
                      to={{
                        pathname: "/search",
                        search: "?type=new",
                        hash: "topbar",
                      }}
                      onClick={this.closeMegaMenu}
                    >
                      <img
                        src={require("../../../assets/images/dress2-mm-b.png")}
                        alt="Jdid"
                        className="blue"
                      />{" "}
                      J'did
                    </Link>
                    <ul
                      className={
                        "su-menu mega-menu-image" +
                        (this.state.showSubMenu == "jdid-menu" ? " active" : "")
                      }
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 col-lg-4">
                            <div className="su-menu-title">
                              <Link
                                to={{
                                  pathname: "/search",
                                  search: "?type=new&gender=homme",
                                  hash: "topbar",
                                }}
                                onClick={this.closeMegaMenu}
                              >
                                Homme
                              </Link>
                            </div>
                            <ul>
                              {category_structur &&
                                category_structur.homme.map((cates, index) => {
                                  let qrParams = {
                                    type: "new",
                                    gender: "homme",
                                    cat_ids: [],
                                  };

                                  cates.sub_categories.map((scate, index) => {
                                    qrParams.cat_ids.push(scate.cat_id);
                                  });

                                  let urlParams = QueryString.stringify(
                                    qrParams,
                                    {
                                      arrayFormat: "bracket",
                                    }
                                  );

                                  return (
                                    <li key={index}>
                                      <Link
                                        to={{
                                          pathname: "/search",
                                          search: "?" + urlParams,
                                          hash: "topbar",
                                        }}
                                        onClick={this.closeMegaMenu}
                                      >
                                        {cates.details.title}
                                        {
                                          <img
                                            src={cates.details.icon}
                                            className="menu-acc"
                                            alt={cates.details.title}
                                          />
                                        }
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className="col-md-12 col-lg-4">
                            <div className="su-menu-title">
                              <Link
                                to={{
                                  pathname: "/search",
                                  search: "?type=new&gender=femme",
                                  hash: "topbar",
                                }}
                                onClick={this.closeMegaMenu}
                              >
                                Femme
                              </Link>
                            </div>
                            <ul>
                              {category_structur &&
                                category_structur.femme.map((cates, index) => {
                                  let qrParams = {
                                    type: "new",
                                    gender: "femme",
                                    cat_ids: [],
                                  };

                                  cates.sub_categories.map((scate, index) => {
                                    qrParams.cat_ids.push(scate.cat_id);
                                  });

                                  let urlParams = QueryString.stringify(
                                    qrParams,
                                    {
                                      arrayFormat: "bracket",
                                    }
                                  );

                                  return (
                                    <li key={index}>
                                      <Link
                                        to={{
                                          pathname: "/search",
                                          search: "?" + urlParams,
                                          hash: "topbar",
                                        }}
                                        onClick={this.closeMegaMenu}
                                      >
                                        {cates.details.title}
                                        <img
                                          src={cates.details.icon}
                                          className="menu-acc"
                                          alt={cates.details.title}
                                        />
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className="col-md-12 col-lg-4">
                            <div className="su-menu-title">
                              <Link
                                to={{
                                  pathname: "/search",
                                  search: "?type=new&gender=enfant",
                                  hash: "topbar",
                                }}
                                onClick={this.closeMegaMenu}
                              >
                                Enfant
                              </Link>
                            </div>
                            <ul>
                              {category_structur &&
                                category_structur.enfant.map((cates, index) => {
                                  let qrParams = {
                                    type: "new",
                                    gender: "enfant",
                                    cat_ids: [],
                                  };

                                  cates.sub_categories.map((scate, index) => {
                                    qrParams.cat_ids.push(scate.cat_id);
                                  });

                                  let urlParams = QueryString.stringify(
                                    qrParams,
                                    {
                                      arrayFormat: "bracket",
                                    }
                                  );

                                  return (
                                    <li key={index}>
                                      <Link
                                        to={{
                                          pathname: "/search",
                                          search: "?" + urlParams,
                                          hash: "topbar",
                                        }}
                                        onClick={this.closeMegaMenu}
                                      >
                                        {cates.details.title}
                                        <img
                                          src={cates.details.icon}
                                          className="menu-acc"
                                          alt={cates.details.title}
                                        />
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </li>
                  <li
                    className="mega-menu sub-menu"
                    id="ocaz-menu"
                    ref={this.setSubmenuUsedRef}
                  >
                    <Link
                      to={{
                        pathname: "/search",
                        search: "?type=used",
                        hash: "topbar",
                      }}
                      onClick={this.closeMegaMenu}
                    >
                      <img
                        src={require("../../../assets/images/dress1-mm-b.png")}
                        alt="Ocaz"
                        className="pink"
                      />
                      Ocaz
                    </Link>
                    <ul
                      className={
                        "su-menu mega-menu-image" +
                        (this.state.showSubMenu == "ocaz-menu" ? " active" : "")
                      }
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-md-12 col-lg-4">
                            <div className="su-menu-title">
                              <Link
                                to={{
                                  pathname: "/search",
                                  search: "?type=used&gender=homme",
                                  hash: "topbar",
                                }}
                                onClick={this.closeMegaMenu}
                              >
                                Homme
                              </Link>
                            </div>
                            <ul>
                              {category_structur &&
                                category_structur.homme.map((cates, index) => {
                                  let qrParams = {
                                    type: "used",
                                    gender: "homme",
                                    cat_ids: [],
                                  };

                                  cates.sub_categories.map((scate, index) => {
                                    qrParams.cat_ids.push(scate.cat_id);
                                  });

                                  let urlParams = QueryString.stringify(
                                    qrParams,
                                    {
                                      arrayFormat: "bracket",
                                    }
                                  );

                                  return (
                                    <li key={index}>
                                      <Link
                                        to={{
                                          pathname: "/search",
                                          search: "?" + urlParams,
                                          hash: "topbar",
                                        }}
                                        onClick={this.closeMegaMenu}
                                      >
                                        {cates.details.title}
                                        <img
                                          src={cates.details.icon}
                                          className="menu-acc"
                                          alt={cates.details.title}
                                        />
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className="col-md-12 col-lg-4">
                            <div className="su-menu-title">
                              <Link
                                to={{
                                  pathname: "/search",
                                  search: "?type=used&gender=femme",
                                  hash: "topbar",
                                }}
                                onClick={this.closeMegaMenu}
                              >
                                Femme
                              </Link>
                            </div>
                            <ul>
                              {category_structur &&
                                category_structur.femme.map((cates, index) => {
                                  let qrParams = {
                                    type: "used",
                                    gender: "femme",
                                    cat_ids: [],
                                  };

                                  cates.sub_categories.map((scate, index) => {
                                    qrParams.cat_ids.push(scate.cat_id);
                                  });

                                  let urlParams = QueryString.stringify(
                                    qrParams,
                                    {
                                      arrayFormat: "bracket",
                                    }
                                  );

                                  return (
                                    <li key={index}>
                                      <Link
                                        to={{
                                          pathname: "/search",
                                          search: "?" + urlParams,
                                          hash: "topbar",
                                        }}
                                        onClick={this.closeMegaMenu}
                                      >
                                        {cates.details.title}
                                        <img
                                          src={cates.details.icon}
                                          className="menu-acc"
                                          alt={cates.details.title}
                                        />
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                          <div className="col-md-12 col-lg-4">
                            <div className="su-menu-title">
                              <Link
                                to={{
                                  pathname: "/search",
                                  search: "?type=used&gender=enfant",
                                  hash: "topbar",
                                }}
                                onClick={this.closeMegaMenu}
                              >
                                Enfant
                              </Link>
                            </div>
                            <ul>
                              {category_structur &&
                                category_structur.enfant.map((cates, index) => {
                                  let qrParams = {
                                    type: "used",
                                    gender: "enfant",
                                    cat_ids: [],
                                  };

                                  cates.sub_categories.map((scate, index) => {
                                    qrParams.cat_ids.push(scate.cat_id);
                                  });

                                  let urlParams = QueryString.stringify(
                                    qrParams,
                                    {
                                      arrayFormat: "bracket",
                                    }
                                  );

                                  return (
                                    <li key={index}>
                                      <Link
                                        to={{
                                          pathname: "/search",
                                          search: "?" + urlParams,
                                          hash: "topbar",
                                        }}
                                        onClick={this.closeMegaMenu}
                                      >
                                        {cates.details.title}
                                        <img
                                          src={cates.details.icon}
                                          className="menu-acc"
                                          alt={cates.details.title}
                                        />
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/search",
                        search: "?sort=most_recent",
                        hash: "topbar",
                      }}
                    >
                      <img
                        src={require("../../../assets/images/dress4-mm-b.png")}
                        alt="Nouveautés"
                        className="green"
                      />{" "}
                      Nouveautes
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/mostlikedarticles",
                        search: "?sort=most_loved",
                        hash: "topbar",
                      }}
                    >
                      <img
                        src={require("../../../assets/images/heart-mm-b.png")}
                        alt="Coups de cœur"
                        className="orange"
                      />{" "}
                      Coups de cœur
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TopBar.propTypes = {
  userData: PropTypes.array.isRequired,
  deleteUserData: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, { deleteUserData })(TopBar);
