import React from "react";

const Error = ({ touched, message, styleText = "" }) => {
  if (touched === false) {
    return <div className="error">&nbsp;</div>;
  }

  if (message) {
    return <div className="error">{message}</div>;
  }

  return <div className="error">&nbsp;</div>;
};

export default Error;
