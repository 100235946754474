import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import Error from "../templates/Errors";
import Modal from "react-modal";
import { Alert } from "react-bootstrap";
import FacebookLoginButton from "../FacebookLoginButton";
import { addFlashMessage } from "../../actions/flashMessages";
import { addUserData } from "../../actions/userActions";
import { showPageLoader, hidePageLoader } from "../../actions/pageLoader";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    zIndex: 5,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Ce champ est obligatoire")
    .email("Entrer une adresse mail valide")
    .max(255, "Must be shorter than 255 characters"),
  username: Yup.string()
    .required("Ce champ est obligatoire")
    .max(160, "Must be shorter than 20 characters"),
  passkey: Yup.string()
    .required("Ce champ est obligatoire")
    .min(5, "Votre mot de passe doit contenir au moins 5 caractères")
    .max(30, "Must be shorter than 30 characters"),
  phone_number: Yup.string()
    .required("Ce champ est obligatoire")
    .matches(/^\d*(\.\d+)?$/, "Please entere numbers only")
    .length(10, "Doit contenir 10 chiffres"),
  gender: Yup.string().required("Ce champ est obligatoire"),
  city: Yup.number(),
  address_1: Yup.string()
    .min(0, "Votre mot de passe doit contenir au moins 5 caractères")
    .max(160, "Must be shorter than 80 characters"),
  introduction: Yup.string()
    .min(0, "Votre mot de passe doit contenir au moins 5 caractères")
    .max(600, "Must be shorter than 600 characters"),
  accept_tc_privacy: Yup.string().required("Ce champ est obligatoire"),
});

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRegistered: false,
      showInvalidError: false,
      cities: [],
    };

    this.onChange = this.onChange.bind(this);
    this.checkboxChange = this.checkboxChange.bind(this);
    /* this.onSubmit = this.onSubmit.bind(this); */

    this.props.hidePageLoader();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  checkboxChange(e) {
    this.setState({
      [e.target.name]: e.target.checked ? e.target.value : 0,
    });
  }

  checkValid = (isValid) => {
    this.setState({
      showInvalidError: !isValid,
    });
  };

  /* onSubmit(e) {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      username: this.state.username,
      passkey: this.state.passkey,
      phone_number: this.state.phone_number,
      gender: this.state.gender,
      city: this.state.city,
      address_1: this.state.address_1,
      introduction: this.state.introduction,
      accept_articl_exchange: this.state.accept_articl_exchange,
      accept_tc_privacy: this.state.accept_tc_privacy,
      subscribed: this.state.subscribed,
    };
    return axios
      .post(
        "http://dz.icomettech.com/api/public/users/validate_register",
        userData
      )
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  } */

  componentDidMount() {
    axios
      .get(window.BASE_API_URL + "public/users/cities")
      .then((response) => {
        this.setState({
          cities: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    const { cities } = this.state;

    if (sessionStorage.getItem("userData")) {
      return <Redirect to="/home" />;
    }

    if (this.state.isRegistered === true) {
      return <Redirect to="/home" />;
      //window.location.href = "https://dz.icomettech.com/";
    }

    return (
      <div>
        <div className="sin-up-wrapper sin-up">
          <div className="container">
            <div className="sin-up-content-block">
              <a href="#" className="sin-up-arrow-back">
                <i className="fa fa-long-arrow-left"></i> Back
              </a>
              <div className="sin-up-head">S'inscrire</div>
              <div className="sin-up-sub">Créer un compte</div>
              <ul className="social-login no-list">
                <li style={{ textAlign: "center", display: "block" }}>
                  Inscrivez-vous avec Facebook
                </li>
                <li>
                  <FacebookLoginButton />
                  {/* <a
                    className="facebook"
                    href="#"
                    style={{
                      width: "214px !important",
                      margin: "0 auto",
                    }}
                  >
                    <i className="fab fa-facebook-f"></i> &nbsp; Facebook
                  </a> */}
                </li>
              </ul>
              <div className="or">
                <span>Ou</span>
              </div>
              <Formik
                initialValues={{
                  email: "",
                  username: "",
                  passkey: "",
                  phone_number: "",
                  gender: "",
                  city: "",
                  address_1: "",
                  introduction: "",
                  //accept_articl_exchange: 0,
                  accept_tc_privacy: "",
                  subscribed: 0,
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  this.props.showPageLoader();

                  axios
                    .post(
                      window.BASE_API_URL + "public/users/validate_register",
                      values
                    )
                    .then((response) => {
                      resetForm();
                      if (response.data.status == "ok") {
                        this.setState({ isRegistered: true });
                        /* sessionStorage.setItem(
                          "userData",
                          JSON.stringify(response.data)
                        ); */

                        this.props.addFlashMessage({
                          type: "success",
                          message: "Bienvenu(e) sur DZdressing!",
                        });

                        this.props.addUserData({
                          userData: response.data,
                        });

                        this.props.hidePageLoader();

                        /* setTimeout(
                          () =>
                            (window.location.href =
                              "https://dz.icomettech.com/"),
                          2500
                        ); */
                      }
                    })
                    .catch((err) => {
                      setSubmitting(false);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                }) => (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <div className="form-group-label">
                            Adresse mail<span>*</span>
                          </div>
                          <input
                            type="email"
                            id="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            autoComplete="nope"
                          />
                          <Error
                            touched={touched.email}
                            message={errors.email}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <div className="form-group-label">
                            Nom d'utilisateur<span>*</span>
                          </div>
                          <input
                            type="text"
                            id="username"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            autoComplete="nope"
                          />
                          <Error
                            touched={touched.username}
                            message={errors.username}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <div className="form-group-label">
                            Mot de passe<span>*</span>
                          </div>
                          <input
                            type="password"
                            id="passkey"
                            name="passkey"
                            value={values.passkey}
                            onChange={handleChange}
                            autoComplete="nope"
                          />
                          <Error
                            touched={touched.passkey}
                            message={errors.passkey}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <div className="form-group-label">
                            Numéro de téléphone<span>*</span>
                          </div>
                          <input
                            type="text"
                            id="phone_number"
                            name="phone_number"
                            value={values.phone_number}
                            onChange={handleChange}
                            autoComplete="nope"
                          />
                          <Error
                            touched={touched.phone_number}
                            message={errors.phone_number}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group">
                          <div className="form-group-label">
                            Genre<span>*</span>
                          </div>
                          <select
                            id="gender"
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                          >
                            <option value="">Genre</option>
                            <option value="homme">Homme</option>
                            <option value="femme">Femme</option>
                          </select>
                          <Error
                            touched={touched.gender}
                            message={errors.gender}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group location">
                          <div className="location-icon"></div>
                          <div className="form-group-label">Ville</div>
                          <select
                            id="city"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                          >
                            <option value="">Ville</option>
                            {cities.length
                              ? cities.map((city) => (
                                  <option
                                    value={city.city_id}
                                    key={city.city_id}
                                  >
                                    {city.title}
                                  </option>
                                ))
                              : null}
                          </select>
                          <Error touched={touched.city} message={errors.city} />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="form-group location">
                          <div className="location-icon"></div>
                          <div className="form-group-label">Adresse</div>
                          <input
                            type="text"
                            id="address_1"
                            name="address_1"
                            value={values.address_1}
                            onChange={handleChange}
                            autoComplete="nope"
                          />
                          <Error
                            touched={touched.address_1}
                            message={errors.address_1}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group location">
                      <div className="location-icon"></div>
                      <div className="form-group-label">
                        Description de votre dressing
                      </div>
                      <textarea
                        rows="2"
                        className="text-area"
                        id="introduction"
                        name="introduction"
                        value={values.introduction}
                        onChange={handleChange}
                      ></textarea>
                      <Error
                        touched={touched.introduction}
                        message={errors.introduction}
                      />
                    </div>
                    <div className="check-box">
                      <p>
                        <input
                          className="filled-in"
                          id="subscribed"
                          name="subscribed"
                          type="checkbox"
                          value="1"
                          onChange={handleChange}
                        />
                        <label htmlFor="subscribed">
                          Je souhaite être informé(e) des offres spéciales et
                          recevoir la newsletter
                        </label>
                      </p>
                    </div>
                    <div className="check-box" style={{ position: "relative" }}>
                      <p>
                        <input
                          className="filled-in"
                          id="accept_tc_privacy"
                          name="accept_tc_privacy"
                          type="checkbox"
                          value="1"
                          onChange={handleChange}
                        />
                        <label htmlFor="accept_tc_privacy">
                          En m'inscrivant, j'accepte les conditions générales
                          d'utilisation et la politique de confidentialité du
                          site<span>*</span>
                        </label>
                        <Error
                          touched={touched.accept_tc_privacy}
                          message={errors.accept_tc_privacy}
                        />
                      </p>
                    </div>
                    <div className="clearfix">&nbsp;</div>
                    <div className="sign-up-button-main">
                      {this.state.showInvalidError == true && (
                        <Alert className="alert alert-danger">
                          Veuillez compléter les champs en rouge
                        </Alert>
                      )}
                      <button
                        className="sign-up-butt"
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => this.checkValid(isValid)}
                      >
                        Rejoindre la communauté
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
              <div className="dont-have-account">
                Déjà inscrit ? <Link to={"/login"}>Connexion</Link>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.isRegistered}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="container" style={{ textAlign: "center" }}>
            <h2>Bienvenu(e) sur DZdressing</h2>
            <div>&nbsp;</div>
            <div>Nous sommes très heureux de vous avoir parmi nous</div>
            {/* <div>&nbsp;</div>
            <div>
              <Link
                to={"/login"}
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  display: "inline-block",
                  lineHeight: "42px",
                  borderRadius: "3px",
                  textTransform: "uppercase",
                  padding: "0 20px",
                  background: "#1b1464",
                  color: "#fff",
                }}
              >
                Login here
              </Link>
            </div> */}
          </div>
        </Modal>
        <div
          className={
            "page-loader " +
            (this.props.pageLoading.active === true ? "active" : "")
          }
        >
          <div className=" text-center">
            <i
              className="fa fa-spinner fa-spin"
              style={{ fontSize: "4em" }}
            ></i>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  userData: PropTypes.array.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  addUserData: PropTypes.func.isRequired,
  showPageLoader: PropTypes.func.isRequired,
  hidePageLoader: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
    pageLoading: state.pageLoaderReducer,
  };
};

export default connect(stateToProps, {
  addFlashMessage,
  addUserData,
  showPageLoader,
  hidePageLoader,
})(Register);
