import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Productbox from "../templates/Productbox";

export class HomeFeaturedArticles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      items: 12,
      loading: false,
      isLoggedIn: false,
      userInfo: [],
      articles: [],
    };
  }

  componentDidMount() {
    let udata = [];
    let paramData = {};

    if (this.props.userData.length) {
      udata = this.props.userData[0].userData;
      this.setState({ isLoggedIn: true, userInfo: udata }, () =>
        this.fetchArticles()
      );
    } else {
      this.setState({ isLoggedIn: false }, () => this.fetchArticles());
    }

    document.addEventListener("scroll", this.trackScrolling);
  }

  fetchArticles = () => {
    let params = {};

    this.setState({ loading: true });

    if (this.state.userInfo.user) {
      params.user_id = this.state.userInfo.user.user_id;
    }

    params.page = this.state.page;
    params.items = this.state.items;

    params.sort = "most_recent";

    axios
      .get(window.BASE_API_URL + "public/article/search", {
        params: params,
      })
      .then((response) => {
        if (this.state.page > 1) {
          if (response.data.articles != undefined) {
            this.setState((prevState) => ({
              articles: prevState.articles.concat(response.data.articles),
              loading: false,
            }));
          } else {
            this.setState({
              loading: false,
              endOfResult: true,
            });
          }
        } else {
          this.setState({
            articles: response.data.articles,
            totalArticles: response.data.total_articles,
            loading: false,
            endOfResult: false,
            queryParams: [],
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return true;
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("listing-wrapper");
    if (this.isBottom(wrappedElement) && this.state.endOfResult === false) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.fetchArticles();
      });
    }
  };

  render() {
    return (
      <div id="listing-wrapper">
        <div className="row">
          {this.state.articles &&
            this.state.articles.map((article, index) => (
              <div
                key={"art-" + index}
                className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 mplr"
                key={"col" + index}
              >
                <Productbox productData={article} boxkey={index} />
              </div>
            ))}
        </div>
        <div className="text-center load-more-btn menu-list">
          {this.state.loading && (
            <div>
              Chargement <i className="fa fa-spinner fa-spin"></i>
            </div>
          )}
        </div>
      </div>
    );
  }
}

HomeFeaturedArticles.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(HomeFeaturedArticles);
