import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Button, Alert } from "react-bootstrap";
import Error from "./Errors";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Ce champ est obligatoire")
    .email("Entrer une adresse mail valide")
    .max(255, "Must be shorter than 255 characters"),
  username: Yup.string()
    .required("Ce champ est obligatoire")
    .max(160, "Must be shorter than 20 characters"),
  phone_number: Yup.string()
    .required("Ce champ est obligatoire")
    .matches(/^\d*(\.\d+)?$/, "Please entere numbers only")
    .length(10, "Doit contenir 10 chiffres"),
  city: Yup.number(),
  address_1: Yup.string()
    .min(0, "Votre mot de passe doit contenir au moins 5 caractères")
    .max(160, "Must be shorter than 80 characters"),
  /* postcode: Yup.string()
    .required("Ce champ est obligatoire")
    .matches(/^\d*(\.\d+)?$/, "Please entere numbers only")
    .length(5, "Doit contenir 5 chiffres"), */
});

class PurchaseStep1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: [],
      cities: [],
      showInvalidError: false,
      formValid: false,
      cityName: "",
    };

    this.isValidated = this.isValidated.bind(this);
  }

  fetchCities = () => {
    if (!this.state.cities.length) {
      axios
        .get(window.BASE_API_URL + "public/users/cities")
        .then((response) => {
          this.setState({
            cities: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  componentDidMount() {
    let udata = [];

    if (this.props.userData) {
      udata = this.props.userData;
      this.setState({ userData: udata });
    }

    //this.fetchCities();
  }

  componentWillMount() {
    this.isValidated = this.isValidated.bind(this);
  }

  checkValid = (isValid) => {
    this.setState({
      showInvalidError: !isValid,
      formValid: !isValid,
    });
  };

  isValidated() {
    return false;
  }

  render() {
    let user = this.state.userData.user;
    let orderData = this.props.orderData;
    let cities = this.props.cities;

    if (user) {
      return (
        <>
          <Formik
            initialValues={{
              user_id: user.user_id,
              email: orderData.email ? orderData.email : user.email,
              username: orderData.username ? orderData.username : user.username,
              phone_number: orderData.phone_number
                ? orderData.phone_number
                : user.phone_number,
              city: orderData.city ? orderData.city : user.city,
              address_1: orderData.address_1
                ? orderData.address_1
                : user.address_1,
              //postcode: orderData.postcode ? orderData.postcode : user.postcode,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);

              if (this.state.cityName != "") {
                values.cityName = this.state.cityName;
              } else {
                let cityTitle = "";
                for (var i = 0; i < cities.length; i++) {
                  console.log(values.city);
                  if (cities[i].city_id === values.city) {
                    cityTitle = cities[i].title;
                  }
                }

                values.cityName = cityTitle;
              }

              this.props.setOrderData(values);

              this.props.jumpToStep(1);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => (
              <form onSubmit={handleSubmit} encType="multipart/form-data">
                <input
                  type="hidden"
                  name="user_id"
                  id="user_id"
                  value={values.user_id}
                />
                <div className="row">
                  <div className="col-sm-12 offset-md-2 offset-lg-2 col-md-8 col-lg-8">
                    <div className="form-group">
                      <div className="form-group-label">
                        Adresse mail<span>*</span>
                      </div>
                      <input
                        type="email"
                        placeholder="Adresse mail"
                        id="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <Error touched={touched.email} message={errors.email} />
                    </div>

                    <div className="form-group">
                      <div className="form-group-label">
                        Nom d'utilisateur<span>*</span>
                      </div>
                      <input
                        type="text"
                        placeholder="Nom d'utilisateur"
                        id="username"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                      />
                      <Error
                        touched={touched.username}
                        message={errors.username}
                      />
                    </div>

                    <div className="form-group">
                      <div className="form-group-label">
                        Numéro de téléphone<span>*</span>
                      </div>
                      <input
                        type="text"
                        id="phone_number"
                        name="phone_number"
                        value={values.phone_number}
                        onChange={handleChange}
                      />
                      <Error
                        touched={touched.phone_number}
                        message={errors.phone_number}
                      />
                    </div>

                    <div className="form-group location">
                      <div className="location-icon"></div>
                      <div className="form-group-label">Adresse</div>
                      <input
                        type="text"
                        id="address_1"
                        name="address_1"
                        value={values.address_1}
                        onChange={handleChange}
                      />
                      <Error
                        touched={touched.address_1}
                        message={errors.address_1}
                      />
                    </div>

                    <div className="form-group location">
                      <div className="location-icon"></div>
                      <div className="form-group-label">Ville</div>
                      <select
                        id="city"
                        name="city"
                        value={values.city}
                        onChange={(e) => {
                          let city = cities[e.currentTarget.selectedIndex - 1];
                          //console.log(e.currentTarget);
                          //console.log(city.title);
                          this.setState({ cityName: city.title });
                          handleChange(e);
                        }}
                      >
                        <option value="">Ville</option>
                        {cities.length
                          ? cities.map((city) => (
                              <option value={city.city_id} key={city.city_id}>
                                {city.title}
                              </option>
                            ))
                          : null}
                      </select>
                      <Error touched={touched.city} message={errors.city} />
                    </div>

                    {/* <div className="form-group location">
                      <div className="location-icon"></div>
                      <div className="form-group-label">Code postal</div>
                      <input
                        type="text"
                        id="postcode"
                        name="postcode"
                        value={values.postcode}
                        onChange={handleChange}
                      />
                      <Error
                        touched={touched.postcode}
                        message={errors.postcode}
                      />
                    </div> */}
                    {this.state.showInvalidError == true && (
                      <Alert className="alert alert-danger">
                        Veuillez compléter les champs en rouge
                      </Alert>
                    )}
                    <div className="">
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => this.checkValid(isValid)}
                      >
                        Suivant &gt;
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}
export default PurchaseStep1;
