import React, { Component } from "react";
import StarRatingComponent from "react-star-rating-component";
import { Link } from "react-router-dom";
import Moment from "moment";
import "moment/locale/fr";

export class RatingBlock extends Component {
  render() {
    const rdata = this.props.rating;

    if (rdata) {
      let encodeUserID = new Buffer(rdata.from_id).toString("base64");
      return (
        <div className="reviews-box-w">
          <div className="user-img">
            <img src={rdata.profile_photo} className="img-responsive" alt="" />
          </div>
          <div className="reviews-box">
            <h4>
              <Link to={"/profile/" + encodeUserID} target="_blank">
                {rdata.username}
              </Link>
            </h4>
            <div className="rating-added">
              <StarRatingComponent
                name="rate2"
                renderStarIcon={() => <i className="fa fa-star"></i>}
                renderStarIconHalf={() => <i className="fa fa-star"></i>}
                starCount={5}
                value={rdata.rating}
                emptyStarColor="#cfcfcf"
                editing={false}
              />

              <span className="time-block">
                {rdata.rating_date &&
                  Moment(rdata.rating_date, "YYYY-MM-DD HH:II:SS").format(
                    "D MMM YYYY"
                  )}
              </span>
            </div>
            <p>{rdata.comment}</p>
          </div>
          <div className="clearfix"></div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default RatingBlock;
