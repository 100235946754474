import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Productbox from "./Productbox";
import StarRatingComponent from "react-star-rating-component";
import RatingBlock from "./RatingBlock";

class Profiletab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      userInfo: this.props.userInfo ? this.props.userInfo : [],
      activeTab: "one",
      products: [],
      ratingsList: [],
      ratings: 0,
      commentAllowed: false,
      commentValid: false,
      commentText: "",
    };
  }

  componentDidMount() {
    this.setTabs(this.props);
  }

  componentWillReceiveProps(newProps) {
    this.setTabs(newProps);
  }

  setTabs = (stProps) => {
    let udata = [];

    if (stProps.userData.length > 0) {
      udata = stProps.userData[0].userData;
    }

    if (stProps.userInfo) {
      let userInfo = stProps.userInfo;
      if (userInfo) {
        axios
          .get(
            window.BASE_API_URL +
              "public/article/get_user_products/" +
              userInfo.user_id +
              (udata.user ? "/" + udata.user.user_id : "")
          )
          .then((response) => {
            this.setState({
              products: response.data,
              userInfo: userInfo,
            });
          })
          .catch((err) => {
            console.log(err);
          });

        this.getRatings(stProps);
      }
    }
  };

  setTab = (e) => {
    e.preventDefault();
    this.setState({ activeTab: e.target.getAttribute("tabname") });
  };

  onStarClick = (nextValue) => {
    let isValid = false;
    if (nextValue > 0) {
      isValid = true;
    }

    this.setState({ ratings: nextValue, commentValid: isValid });
  };

  setCommentText = (e) => {
    this.setState({ commentText: e.currentTarget.value });
  };

  addComment = () => {
    if (this.state.ratings > 0) {
      this.setState({ commentPosting: true });

      let udata = [];

      if (this.props.userData.length > 0) {
        udata = this.props.userData[0].userData;
        console.log(udata);
      }

      axios
        .post(
          window.BASE_API_URL + "public/users/add_rating",
          {
            rating: this.state.ratings,
            comment: this.state.commentText,
            user_id: this.state.userInfo.user_id,
          },
          {
            headers: {
              Authorization: udata.auth_key,
            },
          }
        )
        .then((response) => {
          this.setState(
            {
              commentPosting: false,
              commentAllowed: false,
            },
            () => {
              this.getRatings(this.props);
              this.props.resetProfile();
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  getRatings = (stProps) => {
    let udata = [];

    if (stProps.userData.length > 0) {
      udata = stProps.userData[0].userData;
    }

    if (stProps.userInfo) {
      let userInfo = stProps.userInfo;
      if (userInfo) {
        axios
          .get(window.BASE_API_URL + "public/users/ratings", {
            params: {
              profile_id: userInfo.user_id,
              user_id: udata.user ? udata.user.user_id : 0,
            },
          })
          .then((response) => {
            this.setState({
              ratingsList: response.data.ratings,
              commentAllowed: response.data.comment_allowed,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  render() {
    const { userInfo } = this.state;

    if (userInfo) {
      return (
        <div>
          <div
            data-responsive-tabs
            className="shop-profile-tabs tabs responsive-tabs responsive-tabs-initialized"
          >
            <nav>
              <ul>
                <li className={this.state.activeTab == "one" ? "active" : ""}>
                  <a href="#!" tabname="one" onClick={this.setTab}>
                    Disponibles (
                    {userInfo.articles_count ? userInfo.articles_count : 0})
                  </a>
                </li>
                <li className={this.state.activeTab == "two" ? "active" : ""}>
                  <a href="#!" tabname="two" onClick={this.setTab}>
                    Les avis des acheteurs (
                    {userInfo.total_ratings ? userInfo.total_ratings : 0})
                  </a>
                </li>
              </ul>
            </nav>
            <div className="content">
              <section
                id="one"
                className="mmlr"
                style={{
                  display: this.state.activeTab == "one" ? "block" : "none",
                }}
              >
                <div className="row">
                  {this.state.products &&
                    this.state.products.map((product, index) => (
                      <div
                        className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 mplr"
                        key={index}
                      >
                        <Productbox productData={product} boxkey={index} />
                      </div>
                    ))}
                </div>
              </section>

              <section
                id="two"
                style={{
                  display: this.state.activeTab == "two" ? "block" : "none",
                }}
              >
                {this.state.commentAllowed && (
                  <div
                    className="feedback-section padd"
                    style={{ display: "none" }}
                  >
                    <div className="rating-feedback">
                      <h4>Leave Feedback</h4>
                      <div className="rating-divwrap-main">
                        <div className="rating-div">
                          <label>Rating:</label>
                          <div className="rating-block">
                            <StarRatingComponent
                              name="rate2"
                              renderStarIcon={() => (
                                <i className="fa fa-star"></i>
                              )}
                              renderStarIconHalf={() => (
                                <i className="fa fa-star"></i>
                              )}
                              starCount={5}
                              value={this.state.ratings}
                              emptyStarColor="#cfcfcf"
                              onStarClick={this.onStarClick}
                            />
                          </div>
                        </div>
                        <div className="rating-div">
                          <label>Comment:</label>
                          <div className="rating-block">
                            <textarea
                              className="form-control col-md-6 comment-box"
                              placeholder="(Optionnel) Exemple: Vendeuse sérieuse et très sympathique, je recommande fortement"
                              onChange={this.setCommentText}
                              maxLength="260"
                            >
                              {this.state.commentText}
                            </textarea>
                          </div>
                        </div>
                        <div className="rating-div">
                          <label></label>
                          <div className="rating-block">
                            <button
                              className="btn btn-primary"
                              onClick={this.addComment}
                              disabled={!this.state.commentValid}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.ratingsList &&
                  this.state.ratingsList.map((rating, index) => (
                    <RatingBlock rating={rating} key={index} />
                  ))}

                {(this.state.ratingsList == null ||
                  this.state.ratingsList.length == 0) && (
                  <div className="reviews-box-w">
                    <p>No reviews added yet</p>
                  </div>
                )}
              </section>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

Profiletab.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Profiletab);
