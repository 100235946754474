import React, { Component } from "react";

class Aboutus extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container">
        <div className="clearfix">&nbsp;</div>
        <div className="clearfix">&nbsp;</div>
        <h3 className="text-center">A propos de DZDressing</h3>
        <div className="devider"></div>
        <div className="row">
          <div className="col-md-8 offset-md-2">
            <div className="clearfix">&nbsp;</div>
            <p>
              DZdressing est le premier réseau social de la mode et de
              Videdressing en Algérie. DZdressing propose une expérience
              shopping inégalée à ses utilisateurs. Notre site se veut comme le
              #3en1 de la mode car il permet au particulier de vendre leurs
              articles d’occasion et aux boutiques de produits neufs de
              commercialiser leurs produits et avoir une visibilité sur le net.
              Ils proposent aussi sur ces réseaux sociaux plein de conseils et
              bons plans mode.
            </p>
          </div>
        </div>
        <div className="devider"></div>
        <div className="clearfix">&nbsp;</div>
      </div>
    );
  }
}

export default Aboutus;
