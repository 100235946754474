import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export class UserListBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buttonLoading: false,
      followers_count: 0,
      following: false,
    };
  }

  componentDidMount() {
    if (this.props.userProfile) {
      this.setState({
        followers_count: this.props.userProfile.followers_count,
        following: this.props.userProfile.following == 1,
      });
    }
  }

  follow = (followTo) => {
    if (this.state.buttonLoading == true) return;

    let udata = [];
    let followUrl = window.BASE_API_URL + "public/users/follow";

    if (this.props.userData) {
      udata = this.props.userData;
    } else {
      return;
    }

    if (udata.user) {
      this.setState({ buttonLoading: true });

      let data = {
        user_id: followTo,
        follower_id: udata.user.user_id,
      };

      axios
        .post(followUrl, data, {
          headers: {
            Authorization: udata.auth_key,
          },
        })
        .then((response) => {
          this.setState({ goToProfile: true, buttonLoading: false });

          if (response.data.status == "ok" && response.data.followers) {
            this.setState({
              followers_count: response.data.followers.total,
              following: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  unfollow = (followedId) => {
    if (this.state.buttonLoading == true) return;

    let udata = [];
    let followUrl = window.BASE_API_URL + "public/users/unfollow";

    if (this.props.userData) {
      udata = this.props.userData;
    }

    if (udata.user) {
      this.setState({ buttonLoading: true });

      let data = {
        user_id: followedId,
        follower_id: udata.user.user_id,
      };

      axios
        .post(followUrl, data, {
          headers: {
            Authorization: udata.auth_key,
          },
        })
        .then((response) => {
          this.setState({ goToProfile: true, buttonLoading: false });

          if (response.data.status == "ok" && response.data.followers) {
            this.setState({
              followers_count: response.data.followers.total,
              following: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    const userProfile = this.props.userProfile;
    let udata = [];
    let twoSectTemplate = false;

    if (this.props.userData) {
      udata = this.props.userData.user;
    }

    if (this.props.twoSect) {
      twoSectTemplate = this.props.twoSect;
    }

    return (
      <div
        className={
          "col-xs-12 col-sm-12  profile-block " +
          (twoSectTemplate ? "col-md-6 box-profile" : "col-md-12")
        }
      >
        <div>
          <div className="profile-pic">
            <div className="user-img">
              {userProfile.profile_photo ? (
                <img
                  src={userProfile.profile_photo}
                  className="img-responsive"
                  alt=""
                />
              ) : (
                <i
                  className="fa fa-user-circle"
                  style={{
                    fontSize: "3.3em",
                    position: "absolute",
                  }}
                ></i>
              )}
            </div>
          </div>
          <div className="profile-follow">
            {this.state.following == 1 ? (
              <button
                type="button"
                className={
                  "footer-hm-chef-bx" +
                  (this.state.buttonLoading ? " loading" : "")
                }
                onClick={() => this.unfollow(userProfile.user_id)}
                style={{
                  opacity:
                    udata && udata.user_id == userProfile.user_id ? 0 : 1,
                  visibility:
                    udata && udata.user_id == userProfile.user_id
                      ? "hidden"
                      : "visible",
                }}
              >
                <i className="fas fa-check"></i> Abonné
              </button>
            ) : (
              <button
                type="button"
                className={
                  "footer-hm-chef-bx" +
                  (this.state.buttonLoading ? " loading" : "")
                }
                onClick={() => this.follow(userProfile.user_id)}
                style={{
                  opacity:
                    udata && udata.user_id == userProfile.user_id ? 0 : 1,
                  visibility:
                    udata && udata.user_id == userProfile.user_id
                      ? "hidden"
                      : "visible",
                }}
              >
                S'abonner
              </button>
            )}
          </div>
          <div className="profile-details">
            <div className="profile_username">
              <Link
                to={
                  "/profile/" +
                  new Buffer(userProfile.user_id).toString("base64")
                }
              >
                {userProfile.username ? userProfile.username : "visit"}
              </Link>
            </div>
            <div className="profile-stats">
              {userProfile.articles_count} articles ·{" "}
              {this.state.followers_count} abonnées ·{" "}
              {userProfile.followings_count} suivies
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserListBox;
