import React, { Component } from "react";

class Contact extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="sin-up-wrapper login">
          <div className="container">
            <div className="sin-up-content-block">
              <div className="sin-up-head">Contactez-nous</div>
              <div className="sin-up-sub">Coming Soon...</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
