import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { render } from "react-dom";
import Multiselect from "react-widgets/lib/Multiselect";
import DropdownList from "react-widgets/lib/DropdownList";
import "react-widgets/dist/css/react-widgets.css";
import Productbox from "../templates/Productbox";
import * as QueryString from "query-string";

export class Newarticles extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      endOfResult: false,
      loggedInUser: [],
      page: 1,
      items: 12,
      totalArticles: 0,
      articles: [],
      sorting: "most_recent",
    };
  }

  componentDidMount() {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
      //console.log(this.props.productData);
      if (udata) {
        this.setState(
          {
            loggedInUser: udata.user,
          },
          () => this.fetchArticles()
        );
      } else {
        this.fetchArticles();
      }
    } else {
      this.fetchArticles();
    }

    document.addEventListener("scroll", this.trackScrolling);
  }

  fetchArticles = () => {
    let params = {};
    this.setState({ loading: true });

    if (this.state.loggedInUser) {
      params.user_id = this.state.loggedInUser.user_id;
    }

    params.page = this.state.page;
    params.items = this.state.items;
    params.sort = this.state.sorting;

    axios
      .get(window.BASE_API_URL + "public/article/search", {
        params: params,
      })
      .then((response) => {
        if (this.state.page > 1) {
          if (response.data.articles != undefined) {
            this.setState((prevState) => ({
              articles: prevState.articles.concat(response.data.articles),
              loading: false,
            }));
          } else {
            this.setState({
              loading: false,
              endOfResult: true,
            });
          }
        } else {
          this.setState({
            articles: response.data.articles,
            totalArticles: response.data.total_articles,
            loading: false,
            endOfResult: false,
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return true;
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("listing-wrapper");
    if (this.isBottom(wrappedElement) && this.state.endOfResult === false) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.fetchArticles();
      });
    }
  };

  render() {
    return (
      <div className="container">
        <div className="listing-wrapper" id="listing-wrapper">
          <div className="search-listing">
            <h2 style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)" }}>
              Nouveautes article
            </h2>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mmlr">
                <div className="result-block listin respon pc-hide">
                  <p>{this.state.totalArticles} articles trouvés</p>
                </div>
                <div className="row">
                  {this.state.articles &&
                    this.state.articles.map((product, index) => (
                      <div
                        className="col-6 col-sm-6 col-md-4 col-lg-3 col-xl-3 mplr"
                        key={"col" + index}
                      >
                        <Productbox productData={product} boxkey={index} />
                      </div>
                    ))}
                </div>
                <div className="text-center load-more-btn menu-list">
                  {this.state.loading && (
                    <div>
                      Chargement <i className="fa fa-spinner fa-spin"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Newarticles.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Newarticles);
