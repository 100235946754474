import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Link, Redirect } from "react-router-dom";
import NumberFormat from "react-number-format";

class Productbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectProductCode: "",
      totalLikes: 0,
      loggedInUser: [],
      liked: this.props.productData ? this.props.productData.liked : 0,
      likeDisabled: false,
    };
  }

  componentDidMount() {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
      //console.log(this.props.productData);
      if (udata) {
        this.setState({
          loggedInUser: udata.user,
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      liked: nextProps.productData ? nextProps.productData.liked : 0,
    });
  }

  goToProduct = (product_code) => {
    return this.setState({ redirectProductCode: product_code });
  };

  like = (e) => {
    e.preventDefault();
    if (this.state.likeDisabled === false) {
      this.setState({ likeDisabled: true });
      let udata = [];
      let followUrl = window.BASE_API_URL + "public/article/like";

      if (this.props.userData.length > 0) {
        udata = this.props.userData[0].userData;
      }

      if (udata.user) {
        let data = {
          user_id: udata.user.user_id,
          product_code: this.props.productData.product_code,
        };

        axios
          .post(followUrl, data, {
            headers: {
              Authorization: udata.auth_key,
            },
          })
          .then((response) => {
            if (response.data.status == "ok" && response.data.likes) {
              this.setState({
                totalLikes: response.data.likes.total,
                liked: 1,
                likeDisabled: false,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    }
  };

  unlike = (e) => {
    e.preventDefault();

    if (this.state.likeDisabled === false) {
      this.setState({ likeDisabled: true });
      let udata = [];
      let followUrl = window.BASE_API_URL + "public/article/unlike";

      if (this.props.userData.length > 0) {
        udata = this.props.userData[0].userData;
      }

      if (udata.user) {
        let data = {
          user_id: udata.user.user_id,
          product_code: this.props.productData.product_code,
        };

        axios
          .post(followUrl, data, {
            headers: {
              Authorization: udata.auth_key,
            },
          })
          .then((response) => {
            if (response.data.status == "ok" && response.data.likes) {
              this.setState({
                totalLikes: response.data.likes.total,
                liked: 0,
                likeDisabled: false,
              });
            }

            if (this.props.loadFavorites) {
              this.props.loadFavorites();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      return false;
    }
  };

  render() {
    if (this.state.redirectProductCode != "") {
      //console.log("redirectProductCode");
      //console.log(this.state.redirectProductCode);
      return <Redirect to={`/article/${this.state.redirectProductCode}`} />;
    }

    const productData = this.props.productData;
    let bgStyle = {};
    let profileIcon = (
      <i
        className="fa fa-user-circle"
        style={{ fontSize: "2em", position: "absolute" }}
      ></i>
    );

    if (productData.photo_1) {
      bgStyle = { backgroundImage: "url(" + productData.photo_1 + ")" };
    }

    if (productData.profile_photo) {
      profileIcon = <img src={productData.profile_photo} alt="" />;
    }

    if (productData) {
      let likeIcon = (
        <div className="wishlist-cfa">
          <a onClick={this.like}>
            <span>
              {this.state.totalLikes
                ? this.state.totalLikes
                : productData.total_likes}
            </span>
            <i className="fa fa-heart"></i>
          </a>
        </div>
      );

      if (this.state.liked == 1) {
        likeIcon = (
          <div className="wishlist-cfa">
            <a className="active" onClick={this.unlike}>
              <span>
                {this.state.totalLikes
                  ? this.state.totalLikes
                  : productData.total_likes}
              </span>
              <i className="fa fa-heart"></i>
            </a>
          </div>
        );
      }

      let encodeUserID = new Buffer(productData.user_id).toString("base64");
      return (
        <div>
          <div className="main-block">
            <Link to={"/article/" + productData.product_code} target="_blank">
              <div
                className="box-image"
                style={bgStyle}
                //onClick={() => this.goToProduct(productData.product_code)}
              >
                {/* <img src={productData.photo_1} alt={productData.title} /> */}
              </div>
            </Link>
            <div className="cate-address">
              <div className="category-block">
                <div className="profile-avr-hm">
                  <div className="profile-avr-hm-img">
                    <Link to={`/profile/${encodeUserID}`} target="_blank">
                      {profileIcon}
                    </Link>
                  </div>
                  <div className="profile-avr-hm-text">
                    {productData.total_views} Vus
                  </div>
                </div>
                <div className="sold-product">
                  {productData.type == "new" ? "Neuf" : "Occasion"}
                </div>
              </div>
              <div
                className="title-box-img-hm"
                //onClick={() => this.goToProduct(productData.product_code)}
              >
                <Link
                  to={"/article/" + productData.product_code}
                  target="_blank"
                >
                  {productData.brand_id}
                </Link>
              </div>
              <div className="price-count-per">
                <NumberFormat
                  value={productData.sale_price}
                  displayType={"text"}
                  thousandSeparator=" "
                  className="mainPrice"
                  renderText={(value) => <>{value} DA</>}
                />{" "}
                {productData.buying_price > 0 ? (
                  <NumberFormat
                    value={productData.buying_price}
                    displayType={"text"}
                    thousandSeparator=" "
                    className="markedPrice"
                    renderText={(value) => <span>{value} DA</span>}
                  />
                ) : null}
              </div>
              <div className="user-connect">
                {productData.size}
                <br />
                {productData.city_name}
                {/* {productData.accept_exchange > 0 ? (
                  <a href="#!">
                    <img
                      src={require("../../assets/images/user-connect.png")}
                      alt="user-connect"
                    />
                  </a>
                ) : null} */}
                {likeIcon}
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      );
    } else return <></>;
  }
}

Productbox.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Productbox);
