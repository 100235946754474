import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Productbox from "../templates/Productbox";

class Favorite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: true,
      userData: [],
      favorites: [],
    };
  }

  componentDidMount = () => {
    this.loadFavorites();
  };

  loadFavorites = () => {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata) {
      this.setState({
        userData: udata,
      });

      if (udata) {
        axios
          .get(
            window.BASE_API_URL +
              "public/article/favorites/" +
              udata.user.user_id
          )
          .then((response) => {
            this.setState({
              favorites: response.data,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      this.setState({ isLoggedIn: false });
    }
  };

  render() {
    if (this.state.isLoggedIn === false) {
      return <Redirect to="/login" />;
    }

    return (
      <div>
        <div className="page-wrapper shop-profile">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <section id="favorites" className="mmlr">
                  <h2 style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>
                    Mes favoris
                  </h2>
                  <div className="row">
                    {this.state.favorites.products &&
                      this.state.favorites.products.map((product, index) => (
                        <div
                          className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 mplr"
                          key={index}
                        >
                          <Productbox
                            productData={product}
                            boxkey={index}
                            loadFavorites={this.loadFavorites}
                          />
                        </div>
                      ))}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Favorite.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Favorite);
