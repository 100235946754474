import { SHOW_PAGE_LOADER, HIDE_PAGE_LOADER } from "../actions/types";

export default (state = [], action = {}) => {
  switch (action.type) {
    case SHOW_PAGE_LOADER:
      return {
        active: true,
      };

    case HIDE_PAGE_LOADER:
      return {
        active: false,
      };

    default:
      return state;
  }
  return state;
};
