import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Modal,
  Button,
  Alert,
  DropdownButton,
  Dropdown,
} from "react-bootstrap";
import axios from "axios";
import Moment from "moment";
import "moment/locale/fr";

class UserDetailsCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      loggedInUser: [],
      userData: [],
      showReportAlert: false,
      reported: false,
      reportLoading: false,
    };
  }

  componentDidMount = () => {
    //console.log(this.props.userInfo);
    //this.setState({ userData: JSON.parse(this.props.userInfo) });
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata.user) {
      /* if (sessionStorage.getItem("userData")) {
      udata = JSON.parse(sessionStorage.getItem("userData")); */
      this.setState({
        loggedInUser: udata.user,
      });
    }
  };

  showReportAlert = () => {
    this.setState({ showReportAlert: true });
  };

  hideReportAlert = () => {
    this.setState({ showReportAlert: false });
  };

  reportThisUser = () => {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
      this.setState({ reportLoading: true });

      axios
        .post(
          window.BASE_API_URL + "public/users/report",
          {
            user_id: this.props.userInfo.user_id,
          },
          {
            headers: {
              Authorization: udata.auth_key,
            },
          }
        )
        .then((response) => {
          this.setState({
            reportLoading: false,
            reported: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    const userData = this.props.userInfo;

    if (userData) {
      return (
        <div>
          {this.state.loggedInUser.user_id != userData.user_id && (
            <DropdownButton
              title=""
              id="bg-nested-dropdown"
              variant="light"
              className="profile-opts"
              size="sm"
            >
              <Dropdown.Item eventKey="1" onClick={this.showReportAlert}>
                <i className="far fa-question-circle"></i> Signaler ce vendeur
              </Dropdown.Item>
            </DropdownButton>
          )}
          <div className="menu-details">
            {userData.username && (
              <h3 className="profile-name mob-hide">{userData.username} </h3>
            )}
            <div className="dev-info">
              {userData.address_1 && (
                <>
                  Adresse: <span>{userData.address_1} </span>
                </>
              )}
            </div>
            <div className="dev-info">
              {userData.city_name && (
                <>
                  Ville: <span>{userData.city_name}</span>
                </>
              )}
            </div>
            <div className="dev-info">
              {userData.introduction && (
                <>
                  Description:
                  <span>{userData.introduction}</span>
                </>
              )}
            </div>
            <div>&nbsp;</div>
            <div className="phone-no">
              <i className="fas fa-phone-volume"></i>
              {userData.phone_number && (
                <a href={"tel:" + userData.phone_number}>
                  {userData.phone_number}
                </a>
              )}
            </div>
            <div className="dev-info">
              <i className="fal fa-clock"></i>Dernière connexion:
              <span>il y a 1 heures</span>
            </div>
            <div className="dev-info">
              <i className="fal fa-calendar-alt"></i>Inscrit le:
              <span>
                {userData.registered_date &&
                  Moment(
                    userData.registered_date,
                    "YYYY-MM-DD HH:II:SS"
                  ).format("D MMM YYYY")}
              </span>
            </div>
            <div className="clearfix">&nbsp;</div>
            <div className="detail-but">
              {this.state.loggedInUser.user_id == userData.user_id ? (
                <Link to="/editprofile" className="order-now-btn">
                  <i className="fa fa-edit"></i> Editer le profil
                </Link>
              ) : null}
              {this.state.loggedInUser.user_id != userData.user_id ? (
                <a href="#!" className="order-now-btn">
                  <i className="fa fa-envelope"></i> Message
                </a>
              ) : null}
            </div>
          </div>
          <Modal
            show={this.state.showReportAlert}
            onHide={this.hideReportAlert}
            className="report-modal"
            id="report-modal"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Signalement</Modal.Title>
            </Modal.Header>
            <Modal.Body id="modal-body">
              {this.state.reported === false ? (
                <>
                  <p>
                    Vous pouvez signaler un utilisateur dont le comportement est
                    inapproprié et non conforme aux conditions d'utilisation du
                    site.
                  </p>
                  <p>Voulez-vous vraiment signaler ce problème à DZDressing?</p>
                </>
              ) : (
                <Alert variant="success">
                  L'utilisateur est signalé. Notre équipe enquêtera pour cela.
                </Alert>
              )}
            </Modal.Body>
            <Modal.Footer>
              {this.state.reported === false ? (
                <>
                  <Button variant="primary" onClick={this.reportThisUser}>
                    Oui, je veux le signaler
                  </Button>
                  <Button variant="light" onClick={this.hideReportAlert}>
                    Non
                  </Button>
                </>
              ) : (
                <Button variant="secondary" onClick={this.hideReportAlert}>
                  Annuler
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

UserDetailsCard.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(UserDetailsCard);
