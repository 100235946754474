import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div className="newsletter_bx homme">
          <div className="container">
            <div className="newsletter-mai-wrapp">
              <div className="newsletter_txt">
                NE MANQUEZ RIEN, inscrivez-vous à notre newsletter
              </div>
              <div className="inpt_newsletter">
                <input
                  type="text"
                  placeholder="Saisissez votre adresse mail"
                  className="frm_newsletter"
                  name="newsletter_email"
                />
                <button
                  type="button"
                  className="subscribe-btn"
                  value="Inscription"
                  name=""
                >
                  Inscription{" "}
                </button>
                <p className="newsletter-note">
                  En vous inscrivant, vous acceptez nos{" "}
                  <Link to="/termsandconditions">Termes et Conditions</Link>, et
                  notre{" "}
                  <Link to="/termsandconditions">
                    Politique de Confidentialité
                  </Link>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-main-block">
          <div className="container">
            <div className="footer-col-block">
              <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-3 offset-md-2">
                  <div className="footer-col-head img-footer-hm">
                    <img
                      src={require("../../assets/images/dz-adm-logo.png")}
                      alt="Logo"
                    />
                  </div>
                  <div className="social-widget">
                    <div className="footer_heading footer-col-head">
                      <span>Suivez nous</span>
                    </div>
                    <div className="footer-content-block">
                      <ul className="social-footer">
                        <li className="facebook">
                          <a
                            href="https://web.facebook.com/Dzdressingcom-109974140750706/?_rdc=1&_rdr"
                            target="_blank"
                          >
                            <i className="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li className="linkedin">
                          <a
                            href="https://www.linkedin.com/company/dzdressing/"
                            target="_blank"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </a>
                        </li>
                        {/* <li className="twitter">
                        <Link
                          to="https://www.twitter.com/dzdressing/"
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </Link>
                      </li> */}
                        <li className="instagram">
                          <a
                            href="https://z-p3.www.instagram.com/dzdressing_com/"
                            target="_blank"
                          >
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3 abc">
                  <div className="footer_heading footer-col-head">
                    <span>Liens</span>
                  </div>
                  <div className="menu_name points-footer">
                    <ul>
                      <li>
                        <Link to="/aboutus">A propos de DZDressing</Link>
                      </li>
                      <li>
                        <Link to="/howitworks">Comment ça marche?</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contactez-nous</Link>
                      </li>
                      <li>
                        <Link to="/termsandconditions">
                          Termes &amp; conditions
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-12 col-md-3 col-lg-3 abc">
                  <div className="footer_heading footer-col-head">
                    <span>Contact</span>
                  </div>
                  <div className="menu_name points-footer btn-footer">
                    <ul className="contact-homelink">
                      <li>
                        <span className="footericon-defualt email-icns-ftr"></span>
                        <a href="mailto:dzdressing@gmail.com">
                          contact@dzdressing.com
                        </a>
                      </li>
                      <li>
                        <span className="footericon-defualt phone-icns-ftr"></span>{" "}
                        <a href="tel:0213553695795">+ (213) 553 69 57 95</a> /
                        <br />
                        <a href="tel:0213664505916">+ (213) 664 50 59 16</a>
                      </li>
                      <li>
                        <span className="footericon-defualt map-icns-ftr"></span>{" "}
                        214 ilots pins maritimes, Mohammadia, Algeria.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="copyright-block">
            <i className="fa fa-copyright"></i> 2020 <a>DZDressing</a>, Tous
            droits réservés
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
