import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import Multiselect from "react-widgets/lib/Multiselect";
import DropdownList from "react-widgets/lib/DropdownList";
import Productbox from "../templates/Productbox";
import * as QueryString from "query-string";
import { isMobile } from "react-device-detect";
import "react-widgets/dist/css/react-widgets.css";
import "react-input-range/lib/bundle/react-input-range.css";

export class Search extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    const params = QueryString.parse(props.location.search, {
      arrayFormat: "bracket",
    });

    this.state = {
      queryParams: params,
      loading: true,
      endOfResult: false,
      mobileMenueActive: false,
      sorting: params.sort ? params.sort : "",
      loggedInUser: [],
      page: 1,
      items: 12,
      totalArticles: 0,
      type: params.type ? params.type : "",
      queryString: params.qr ? params.qr : "",
      gender: params.gender ? params.gender : "",
      qrCategories: [],
      qrSubCategories: [],
      qrBrands: [],
      qrSizes: [],
      qrConditions: [],
      qrColors: [],
      categoryValues: [],
      subCategoryValues: [],
      brandValues: params.brand_ids ? params.brand_ids : [],
      sizeValues: [],
      conditionValues: [],
      colorValues: [],
      cityValues: [],
      typeValue:
        params.type == "new" ? "Neuf" : params.type == "used" ? "Occasion" : "",
      sort: "",
      categoryOpts: [],
      subCategoryOpts: [],
      conditionOpts: [
        { value: "very_good", title: "Trés bon état" },
        { value: "good", title: "Bon état" },
        { value: "average", title: "Etat moyen" },
        { value: "new_without_label", title: "Neuf sans étiquette" },
        { value: "new_with_label", title: "Neuf avec étiquette" },
      ],
      typeOpts: [
        { value: "new", title: "Neuf" },
        { value: "used", title: "Occasion" },
      ],
      brandOpts: [],
      sizeOpts: [],
      colorOpts: [],
      citiesOpts: [],
      articles: [],
      mostLikedTab: this.props.mostLikedTab != undefined,
      priceMin: "",
      priceMax: "",
    };

    this.setGender = this.setGender.bind(this);
  }

  componentDidMount() {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
      //console.log(this.props.productData);
      if (udata) {
        this.setState({
          loggedInUser: udata.user,
        });
      }
    }

    //set default conditions
    if (this.state.queryParams.conditions) {
      let defaults = [];
      this.state.conditionOpts.map((cond) => {
        if (this.state.queryParams.conditions.indexOf(cond.value)) {
          defaults.push(cond);
        }
        this.setState({ conditionValues: defaults });
      });
    }

    this.fetchCategories();
    this.fetchBrand();

    //get colors
    axios
      .get(window.BASE_API_URL + "public/users/colors")
      .then((response) => {
        let defaults = [];
        if (this.state.queryParams.colors !== undefined) {
          response.data.map((clr) => {
            if (this.state.queryParams.colors.indexOf(clr.value) >= 0) {
              defaults.push(clr);
            }
          });
        }

        this.setState({
          colorValues: defaults,
          colorOpts: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(window.BASE_API_URL + "public/users/cities")
      .then((response) => {
        let defaults = [];
        if (this.state.queryParams.cities !== undefined) {
          response.data.map((clr) => {
            if (this.state.queryParams.cities.indexOf(clr.city_id) >= 0) {
              defaults.push(clr);
            }
          });
        }

        this.setState(
          {
            cityValues: defaults,
            citiesOpts: response.data,
          },
          () => this.fetchArticles()
        );
      })
      .catch((err) => {
        console.log(err);
      });

    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillReceiveProps(newProps) {
    console.log(newProps);

    if (newProps.location && newProps.location.hash == "#topbar") {
      const params = QueryString.parse(newProps.location.search, {
        arrayFormat: "bracket",
      });

      console.log(params);

      this.setState(
        {
          queryParams: params,
          type: params.type ? params.type : "",
          typeValue:
            params.type == "new"
              ? "Neuf"
              : params.type == "used"
              ? "Occasion"
              : "",
          gender: params.gender ? params.gender : "",
          brandValues: params.brand_ids ? params.brand_ids : [],
          queryString: params.qr ? params.qr : "",
          sizeValues: [],
          conditionValues: [],
          colorValues: [],
          cityValues: [],
          priceMin: "",
          priceMax: "",
          sorting: params.sort ? params.sort : "",
          page: 1,
          articles: [],
          mostLikedTab: newProps.mostLikedTab != undefined,
        },
        () => {
          if (this.state.queryParams.conditions) {
            let defaults = [];
            this.state.conditionOpts.map((cond) => {
              if (this.state.queryParams.conditions.indexOf(cond.value)) {
                defaults.push(cond);
              }
              this.setState({ conditionValues: defaults });
            });
          }

          this.fetchCategories(true);
          this.fetchBrand();
          //this.fetchArticles();
        }
      );
    }
  }

  fetchCategories = (fetchArticle = false) => {
    let self = this;
    axios
      .get(
        window.BASE_API_URL +
          "public/categories/search_categories" +
          (this.state.gender ? "/" + this.state.gender : "")
      )
      .then((response) => {
        let defaults = [];
        if (this.state.queryParams.cat_ids !== undefined) {
          response.data.map((cat) => {
            if (this.state.queryParams.cat_ids.indexOf(cat.cat_id) >= 0) {
              defaults.push(cat);
            }
          });
        }

        this.setState(
          {
            subCategoryValues: defaults,
            subCategoryOpts: response.data,
          },
          () => {
            if (fetchArticle) self.fetchArticles();
          }
        );

        this.fetchSizes();
      })
      .catch((err) => {
        console.log(err);
      });
    return true;
  };

  fetchSizes = () => {
    let catIds = [];

    if (this.state.subCategoryValues) {
      this.state.subCategoryValues.map((cat) => catIds.push(cat.cat_id));
    }

    axios
      .get(window.BASE_API_URL + "public/article/search_sizes", {
        params: {
          cids: catIds,
          gender: this.state.gender,
        },
      })
      .then((response) => {
        let defaults = [];
        if (this.state.queryParams.sizes !== undefined) {
          response.data.map((siz) => {
            if (this.state.queryParams.sizes.indexOf(siz.value) >= 0) {
              defaults.push(siz);
            }
          });
        }

        this.setState({
          sizeValues: defaults,
          sizeOpts: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    return true;
  };

  fetchBrand = () => {
    axios
      .get(window.BASE_API_URL + "public/article/search_brands", {
        params: {
          gender: this.state.gender,
        },
      })
      .then((response) => {
        this.setState({
          brandOpts: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    return true;
  };

  fetchArticles = () => {
    let catIds = [];
    let colors = [];
    let sizes = [];
    let conditions = [];
    let brands = [];
    let cities = [];
    let params = {};

    this.setState({ loading: true });

    if (this.state.subCategoryValues) {
      this.state.subCategoryValues.map((cat) => catIds.push(cat.cat_id));
      params.cat_ids = catIds;
    }

    if (this.state.brandValues) {
      params.brand_ids = this.state.brandValues;
    }

    if (this.state.colorValues) {
      this.state.colorValues.map((color) => colors.push(color.value));
      params.colors = colors;
    }

    if (this.state.cityValues) {
      this.state.cityValues.map((city) => cities.push(city.city_id));
      params.cities = cities;
    }

    if (this.state.sizeValues) {
      this.state.sizeValues.map((size) => sizes.push(size.value));
      params.sizes = sizes;
    }

    if (this.state.conditionValues) {
      this.state.conditionValues.map((cond) => conditions.push(cond.value));
      params.conditions = conditions;
    }

    if (this.state.gender) {
      params.gender = this.state.gender;
    }

    if (this.state.type) {
      params.type = this.state.type;
    }

    if (this.state.loggedInUser) {
      params.user_id = this.state.loggedInUser.user_id;
    }

    if (this.state.queryString) {
      params.qr = this.state.queryString;
    }

    params.page = this.state.page;
    params.items = this.state.items;
    params.sort = this.state.sorting;
    params.min_price = this.state.priceMin;
    params.max_price = this.state.priceMax;

    axios
      .get(window.BASE_API_URL + "public/article/search", {
        params: params,
      })
      .then((response) => {
        if (this.state.page > 1) {
          if (response.data.articles != undefined) {
            this.setState((prevState) => ({
              articles: prevState.articles.concat(response.data.articles),
              loading: false,
            }));
          } else {
            this.setState({
              loading: false,
              endOfResult: true,
            });
          }
        } else {
          this.setState({
            articles: response.data.articles,
            totalArticles: response.data.total_articles,
            loading: false,
            endOfResult: false,
            queryParams: [],
          });
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    return true;
  };

  setUrlQuery = () => {
    let catIds = [];
    let colors = [];
    let sizes = [];
    let conditions = [];
    let brands = [];
    let cities = [];
    let params = {};

    if (this.state.subCategoryValues) {
      this.state.subCategoryValues.map((cat) => catIds.push(cat.cat_id));
      params.cat_ids = catIds;
    }

    if (this.state.brandValues) {
      params.brand_ids = this.state.brandValues;
    }

    if (this.state.colorValues) {
      this.state.colorValues.map((color) => colors.push(color.value));
      params.colors = colors;
    }

    if (this.state.sizeValues) {
      this.state.sizeValues.map((size) => sizes.push(size.value));
      params.sizes = sizes;
    }

    if (this.state.conditionValues) {
      this.state.conditionValues.map((cond) => conditions.push(cond.value));
      params.conditions = conditions;
    }

    if (this.state.cityValues) {
      this.state.cityValues.map((city) => cities.push(city.city_id));
      params.cities = cities;
    }

    if (this.state.gender) {
      params.gender = this.state.gender;
    }

    if (this.state.type) {
      params.type = this.state.type;
    }

    if (this.state.queryString) {
      params.qr = this.state.queryString;
    }

    params.sort = this.state.sorting;
    params.min_price = this.state.priceMin;
    params.max_price = this.state.priceMax;

    let urlParams = QueryString.stringify(params, {
      arrayFormat: "bracket",
    });

    this.props.history.push({
      pathname: "/search",
      search: "?" + urlParams,
    });
  };

  setCategory = (value) => {
    this.setState(
      {
        subCategoryValues: value,
        page: 1,
        articles: !isMobile ? [] : this.state.articles,
      },
      () => {
        this.fetchSizes();
        if (!isMobile) {
          this.fetchArticles();
          //this.toggleMenu();
          this.setUrlQuery();
        }
      }
    );
  };

  setBrand = (value) => {
    this.setState(
      {
        brandValues: value,
        page: 1,
        articles: !isMobile ? [] : this.state.articles,
      },
      () => {
        if (!isMobile) {
          this.fetchArticles();
          //this.toggleMenu();
          this.setUrlQuery();
        }
      }
    );
  };

  setSize = (value) => {
    this.setState(
      {
        sizeValues: value,
        page: 1,
        articles: !isMobile ? [] : this.state.articles,
      },
      () => {
        if (!isMobile) {
          this.fetchArticles();
          //this.toggleMenu();
          this.setUrlQuery();
        }
      }
    );
  };

  setCondition = (value) => {
    this.setState(
      {
        conditionValues: value,
        page: 1,
        articles: !isMobile ? [] : this.state.articles,
      },
      () => {
        if (!isMobile) {
          this.fetchArticles();
          //this.toggleMenu();
          this.setUrlQuery();
        }
      }
    );
  };

  setType = (e) => {
    let newValue = e.currentTarget.value;

    if (newValue == this.state.type) {
      newValue = "";
    }
    this.setState(
      //{ typeValue: value.option, type: value.value, page: 1, articles: [] },
      {
        type: newValue,
        page: 1,
        articles: !isMobile ? [] : this.state.articles,
      },
      () => {
        if (!isMobile) {
          this.fetchArticles();
          //this.toggleMenu();
          this.setUrlQuery();
        }
      }
    );
  };

  setColors = (value) => {
    this.setState(
      {
        colorValues: value,
        page: 1,
        articles: !isMobile ? [] : this.state.articles,
      },
      () => {
        if (!isMobile) {
          this.fetchArticles();
          //this.toggleMenu();
          this.setUrlQuery();
        }
      }
    );
  };

  setCity = (value) => {
    this.setState(
      {
        cityValues: value,
        page: 1,
        articles: !isMobile ? [] : this.state.articles,
      },
      () => {
        if (!isMobile) {
          this.fetchArticles();
          //this.toggleMenu();
          this.setUrlQuery();
        }
      }
    );
  };

  setSorting = (value) => {
    this.setState(
      {
        sorting: value.option,
        page: 1,
        articles: !isMobile ? [] : this.state.articles,
      },
      () => {
        if (!isMobile) {
          this.fetchArticles();
          //this.toggleMenu();
          this.setUrlQuery();
        }
      }
    );
  };

  setGender = (e) => {
    let newValue = e.currentTarget.value;

    if (newValue == this.state.gender) {
      newValue = "";
    }
    this.setState(
      {
        gender: newValue,
        subCategoryValues: [],
        sizeValues: [],
        brandValues: [],
        page: 1,
        articles: !isMobile ? [] : this.state.articles,
      },
      () => {
        this.fetchCategories();
        this.fetchBrand();
        if (!isMobile) {
          this.fetchArticles();
          //this.toggleMenu();
          this.setUrlQuery();
        }
      }
    );
  };

  resetSearch = () => {
    this.setState(
      {
        queryString: "",
        gender: "",
        type: "",
        subCategoryValues: [],
        sizeValues: [],
        brandValues: [],
        colorValues: [],
        cityValues: [],
        conditionValues: [],
        page: !isMobile ? 1 : this.state.page,
        articles: !isMobile ? [] : this.state.articles,
      },
      () => {
        this.fetchCategories();
        this.fetchBrand();
        //this.toggleMenu();
        this.setUrlQuery();

        if (!isMobile) {
          this.fetchArticles();
        }
      }
    );
  };

  searchByQuery = (e) => {
    if (e.keyCode === 13) {
      this.setState(
        { page: 1, articles: !isMobile ? [] : this.state.articles },
        () => {
          if (!isMobile) {
            this.fetchArticles();
            //this.toggleMenu();
            this.setUrlQuery();
          }
        }
      );
    }
  };

  setQueryString = (e) => {
    this.setState({ queryString: e.currentTarget.value });
  };

  setMinPrice = (e) => {
    this.setState({
      priceMin: e.currentTarget.value,
    });
  };

  setMaxPrice = (e) => {
    this.setState({
      priceMax: e.currentTarget.value,
    });
  };

  checkPriceMinEnter = (e) => {
    if (e.keyCode === 13) {
      this.priceSearch();
    }
  };

  checkPriceMaxEnter = (e) => {
    if (e.keyCode === 13) {
      this.priceSearch();
    }
  };

  priceSearch = (e) => {
    this.setState(
      { page: 1, articles: !isMobile ? [] : this.state.articles },
      () => {
        if (!isMobile) {
          this.fetchArticles();
          this.setUrlQuery();
        }
      }
    );
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = document.getElementById("listing-wrapper");
    if (this.isBottom(wrappedElement) && this.state.endOfResult === false) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.fetchArticles();
      });
    }
  };

  toggleMenu = () => {
    this.setState({ mobileMenueActive: !this.state.mobileMenueActive });
  };

  render() {
    let sortOptions = [
      {
        option: "",
        title: "Trier par",
      },
      {
        option: "most_recent",
        title: "Le plus récent",
      },
      {
        option: "most_viewed",
        title: "Les plus vus",
      },
      {
        option: "most_loved",
        title: "Les plus aimés",
      },
      {
        option: "most_cheap",
        title: "Les moins chers",
      },
    ];

    return (
      <div className="container-fluid">
        <div className="listing-wrapper" id="listing-wrapper">
          <div className="search-listing">
            <div className="row">
              <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3">
                <div
                  className={
                    "left-filter" +
                    (this.state.mobileMenueActive === true ? " active" : "")
                  }
                >
                  <div className="filter-close-but" onClick={this.toggleMenu}>
                    <i className="fal fa-times"></i>
                  </div>
                  <div className="clearfix sort-dropdown">
                    <DropdownList
                      filter={false}
                      data={sortOptions}
                      textField="title"
                      valueField="option"
                      defaultValue={{
                        option: "",
                        title: "Trier par",
                      }}
                      className="float-left"
                      onChange={this.setSorting}
                    />

                    <button
                      className="btn btn-light float-right"
                      onClick={this.resetSearch}
                    >
                      Réinitialiser
                    </button>
                  </div>
                  <div>&nbsp;</div>
                  {/* <div className="result-block listin respon mob-hide">
                    <p>{this.state.totalArticles} articles trouvés</p>
                  </div> */}
                  <div className="search-input-block form-group input-group">
                    <input
                      type="text"
                      name="search_query"
                      className="form-control"
                      value={this.state.queryString}
                      onChange={this.setQueryString}
                      onKeyUp={this.searchByQuery}
                      placeholder="Chercher par article, marque.."
                    />
                    {/* <div className="input-group-append">
                      <button
                        className="btn btn-outline-secondary"
                        type="button"
                        onClick={() => {
                          this.setState({ page: 1, articles: [] }, () => {
                            this.fetchArticles();
                          });
                        }}
                      >
                        <i className="fa fa-search"></i>
                      </button>
                    </div> */}
                  </div>

                  <div className="size-boxes-wrapper">
                    <div className="label-section-block">Pubilc:</div>
                    <div className="size-boxes radio-btns">
                      <div className="radio-btn">
                        <input
                          id="gender_f"
                          name="gender"
                          type="checkbox"
                          value="femme"
                          checked={this.state.gender == "femme"}
                          onChange={this.setGender}
                        />
                        <label htmlFor="gender_f">Femme</label>
                      </div>
                      <div className="radio-btn">
                        <input
                          id="gender_h"
                          name="gender"
                          type="checkbox"
                          value="homme"
                          checked={this.state.gender == "homme"}
                          onChange={this.setGender}
                        />
                        <label htmlFor="gender_h">Homme</label>
                      </div>
                      <div className="radio-btn">
                        <input
                          id="gender_e"
                          name="gender"
                          type="checkbox"
                          value="enfant"
                          checked={this.state.gender == "enfant"}
                          onChange={this.setGender}
                        />
                        <label htmlFor="gender_e">Enfant</label>
                      </div>

                      <div className="clearfix"></div>
                    </div>
                  </div>

                  <div className="size-boxes-wrapper">
                    <div className="label-section-block">Type:</div>
                    <div className="size-boxes radio-btns">
                      <div className="radio-btn">
                        <input
                          id="type_n"
                          name="type"
                          type="checkbox"
                          value="new"
                          checked={this.state.type == "new"}
                          onChange={this.setType}
                        />
                        <label htmlFor="type_n">Neuf</label>
                      </div>
                      <div className="radio-btn">
                        <input
                          id="type_o"
                          name="type"
                          type="checkbox"
                          value="used"
                          checked={this.state.type == "used"}
                          onChange={this.setType}
                        />
                        <label htmlFor="type_o">Occasion</label>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>

                  {/* <div className="form-group-label">Type:</div>
                  <div className="form-group select-block groupd-options">
                    {this.state.subCategoryOpts && (
                      <DropdownList
                        filter={false}
                        name="categoryOpts"
                        id="categoryOpts"
                        placeholder="Type"
                        textField="title"
                        valueField="option"
                        data={this.state.typeOpts}
                        value={this.state.typeValue}
                        onChange={(value) => this.setType(value)}
                      />
                    )}
                  </div> */}

                  <div className="form-group-label">Prix:</div>
                  <div className="form-group input-group">
                    <div className="row no-gutters">
                      <div className="col">
                        <input
                          type="number"
                          min="1"
                          step="1"
                          placeholder="Min"
                          id="min_price"
                          name="min_price"
                          value={this.state.priceMin}
                          onChange={this.setMinPrice}
                          onKeyDown={this.checkPriceMinEnter}
                          style={{ width: "78%" }}
                        />{" "}
                        DA
                      </div>
                      <div
                        className="col-1"
                        style={{ alignSelf: "center", textAlign: "center" }}
                      >
                        à
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          min="1"
                          step="1"
                          placeholder="Max"
                          id="max_price"
                          name="max_price"
                          value={this.state.priceMax}
                          onChange={this.setMaxPrice}
                          onKeyDown={this.checkPriceMaxEnter}
                          style={{ width: "78%" }}
                        />{" "}
                        DA
                      </div>
                    </div>
                  </div>

                  <div className="form-group-label">Catégorie:</div>
                  <div className="form-group select-block groupd-options">
                    {this.state.subCategoryOpts && (
                      <Multiselect
                        inputProps={{ maxLength: 5 }}
                        name="categoryOpts"
                        id="categoryOpts"
                        placeholder="Catégorie"
                        textField="title"
                        valueField="cat_id"
                        groupBy="parent_title"
                        data={this.state.subCategoryOpts}
                        value={this.state.subCategoryValues}
                        onChange={(value) => this.setCategory(value)}
                      />
                    )}
                  </div>

                  <div className="form-group-label">Marque</div>
                  <div className="form-group select-block">
                    <Multiselect
                      name="brandOpts"
                      id="brandOpts"
                      placeholder="Marque"
                      data={this.state.brandOpts}
                      value={this.state.brandValues}
                      onChange={(value) => this.setBrand(value)}
                    />
                  </div>

                  <div className="form-group-label">Tailles</div>
                  <div className="form-group select-block">
                    <Multiselect
                      name="sizeOpts"
                      id="sizeOpts"
                      placeholder="Tailles"
                      textField="value"
                      valueField="size_id"
                      data={this.state.sizeOpts}
                      value={this.state.sizeValues}
                      onChange={(value) => this.setSize(value)}
                    />
                  </div>

                  <div className="form-group-label">Etats</div>
                  <div className="form-group select-block">
                    <Multiselect
                      name="conditionOpts"
                      id="conditionOpts"
                      placeholder="Etats"
                      textField="title"
                      valueField="value"
                      data={this.state.conditionOpts}
                      value={this.state.conditionValues}
                      onChange={(value) => this.setCondition(value)}
                    />
                  </div>

                  <div className="size-boxes-wrapper">
                    <div className="label-section-block">Couleur:</div>
                    <div className="form-group select-block">
                      <Multiselect
                        name="colorOpts"
                        id="colorOpts"
                        placeholder="Couleur"
                        textField="value"
                        valueField="color_id"
                        data={this.state.colorOpts}
                        value={this.state.colorValues}
                        onChange={(value) => this.setColors(value)}
                      />
                    </div>
                  </div>

                  <div className="size-boxes-wrapper">
                    <div className="label-section-block">Wilaya:</div>
                    <div className="form-group select-block">
                      <Multiselect
                        name="citiesOpts"
                        id="citiesOpts"
                        placeholder="Wilaya"
                        textField="title"
                        valueField="city_id"
                        data={this.state.citiesOpts}
                        value={this.state.cityValues}
                        onChange={(value) => this.setCity(value)}
                      />
                    </div>
                  </div>
                  <div className="clearfix filter-action">
                    <button
                      className="btn btn-primary float-left pc-hide"
                      onClick={() => {
                        this.setState({ page: 1, articles: [] }, () => {
                          this.fetchArticles();
                          this.toggleMenu();
                        });
                      }}
                    >
                      Appliquer filtre
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-8 col-lg-9 col-xl-9 mmlr">
                {this.state.mostLikedTab && (
                  <div
                    data-responsive-tabs
                    className="shop-profile-tabs tabs responsive-tabs responsive-tabs-initialized"
                  >
                    <nav>
                      <ul>
                        <li className="active">
                          <Link
                            to={{
                              pathname: "/mostlikedarticles",
                              search: "?sort=most_loved",
                              hash: "topbar",
                            }}
                            className="disabled-link"
                          >
                            <i className="fas fa-store"></i> Les articles les
                            plus aimés
                          </Link>
                        </li>
                        <li>
                          <Link to={"/mostfollowedusers"}>
                            <i className="fas fa-users"></i> Les vendeurs les
                            plus suivis
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                )}
                <div className="result-block listin respon">
                  <p>{this.state.totalArticles} articles trouvés</p>
                </div>
                <div className="row">
                  {this.state.articles &&
                    this.state.articles.map((product, index) => (
                      <div
                        className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 mplr"
                        key={"col" + index}
                      >
                        <Productbox productData={product} boxkey={index} />
                      </div>
                    ))}
                </div>
                <div className="text-center load-more-btn menu-list">
                  {this.state.loading && (
                    <div>
                      Chargement <i className="fa fa-spinner fa-spin"></i>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            "filter-open-but" +
            (this.state.mobileMenueActive === true ? " active" : "")
          }
          onClick={this.toggleMenu}
        >
          <i className="fa fa-sliders-h"></i> FILTRER
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Search);
