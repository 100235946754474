import { SHOW_PAGE_LOADER, HIDE_PAGE_LOADER } from "./types";

export function showPageLoader() {
  return {
    type: SHOW_PAGE_LOADER,
  };
}

export function hidePageLoader() {
  return {
    type: HIDE_PAGE_LOADER,
  };
}
