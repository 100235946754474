import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import Error from "../templates/Errors";
import { Alert } from "react-bootstrap";
import { addFlashMessage } from "../../actions/flashMessages";
import { showPageLoader, hidePageLoader } from "../../actions/pageLoader";

const fileSize = 160 * 1024;
const allowedFormats = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

const validationSchema = Yup.object().shape({
  image1: Yup.mixed().required("Ce champ est obligatoire"),
  /* image2: Yup.mixed(),
  image3: Yup.mixed(),
  image4: Yup.mixed(),
  image5: Yup.mixed(), */
  type: Yup.string().required("Ce champ est obligatoire"),
  gender: Yup.string().required("Ce champ est obligatoire"),
  brand: Yup.string().required("Ce champ est obligatoire"),
  title: Yup.string()
    .required("Ce champ est obligatoire")
    .min(0, "Must be 5 characters long")
    .max(80, "Must be shorter than 80 characters"),
  description: Yup.string()
    .required("Ce champ est obligatoire")
    .min(0, "Must be 5 characters long")
    .max(1600, "Must be shorter than 1600 characters"),
  quantity: Yup.number().required("Ce champ est obligatoire"),
  category_id: Yup.string().required("Ce champ est obligatoire"),
  sub_category: Yup.string().required("Ce champ est obligatoire"),
  size: Yup.string().required("Ce champ est obligatoire"),
  color: Yup.string().required("Ce champ est obligatoire"),
  city_id: Yup.number().required("Ce champ est obligatoire"),
  sale_price: Yup.number().required("Ce champ est obligatoire"),
  buying_price: Yup.number(),
  state: Yup.string().when("type", {
    is: "used",
    then: Yup.string().required("Field is required"),
    otherwise: Yup.string(),
  }),
});

class Editarticle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      hideBrand: false,
      showSuccess: false,
      isUsedSelected: false,
      showInvalidError: false,
      goToProduct: false,
      articlecode: "",
      checkedOption: "",
      image1Url: require("../../assets/images/defoul-photo.jpg"),
      image2Url: require("../../assets/images/defoul-photo.jpg"),
      image3Url: require("../../assets/images/defoul-photo.jpg"),
      image4Url: require("../../assets/images/defoul-photo.jpg"),
      image5Url: require("../../assets/images/defoul-photo.jpg"),
      image1value: "",
      image2value: "",
      image3value: "",
      image4value: "",
      image5value: "",
      gender: "",
      cat_id: "",
      sub_category: "",
      articleData: [],
      userData: [],
      categories: [],
      subCategories: [],
      cities: [],
      sizes: [],
      colors: [],
    };

    this.onChange = this.onChange.bind(this);
    this.checkboxChange = this.checkboxChange.bind(this);

    this.props.hidePageLoader();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  checkboxChange(e) {
    this.setState({
      [e.target.name]: e.target.checked ? e.target.value : 0,
    });
  }

  checkUsedSelected = (e) => {
    let condition = false;
    if (e.target.value == "used") {
      condition = true;
    }
    this.setState({
      isUsedSelected: condition,
    });
  };

  chekOptions = (e, setFieldValue) => {
    this.setState({
      checkedOption: e.target.checked ? e.target.name : "",
    });

    if (e.target.name == "accept_sending_by_post" && e.target.checked) {
      setFieldValue("accept_sending_by_post", "1");
      setFieldValue("recover_by_buyer", "0");
    } else {
      setFieldValue("recover_by_buyer", "1");
      setFieldValue("accept_sending_by_post", "0");
    }
  };

  fetchSubCategories = (cat_id = 0) => {
    let gender = this.state.gender;
    if (cat_id) {
      axios
        .get(
          window.BASE_API_URL +
            "public/categories/sub_categories/" +
            cat_id +
            (gender ? "/" + gender : "")
        )
        .then((response) => {
          this.setState({
            subCategories: response.data,
          });

          this.fetchSizes(this.state.sub_category);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return true;
  };

  fetchCategories = (gender) => {
    axios
      .get(
        window.BASE_API_URL +
          "public/categories/main_categories" +
          (gender ? "/" + gender : "")
      )
      .then((response) => {
        this.setState({
          categories: response.data,
        });

        this.fetchSubCategories(this.state.cat_id);
      })
      .catch((err) => {
        console.log(err);
      });
    return true;
  };

  fetchSizes = (category_id) => {
    axios
      .get(
        window.BASE_API_URL +
          "public/article/sizes" +
          (category_id ? "/" + category_id : "")
      )
      .then((response) => {
        this.setState({
          sizes: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    return true;
  };

  handleBrandCheck = (e) => {
    if (e.target.checked) {
      this.setState({ hideBrand: true });
    } else {
      this.setState({ hideBrand: false });
    }
  };

  checkValid = (isValid) => {
    this.setState({
      showInvalidError: !isValid,
    });
  };

  changeImagePreview = (e, setFieldValue) => {
    //console.log(e.target.files[0]);
    if (e.target.files.length) {
      let base64File = this.toBase64(e.target.files[0], e.target);
      this.setState({
        [e.target.name + "Url"]: URL.createObjectURL(e.target.files[0]),
        //[e.target.name + "value"]: base64File,
      });
    } else {
      this.setState({
        [e.target.name +
        "Url"]: require("../../assets/images/defoul-photo.jpg"),
      });
    }
    setFieldValue(e.target.name, e.currentTarget.files[0]);

    //console.log(base64File);
  };

  removeImage = (img, setFieldValue) => {
    this.setState({
      [img + "Url"]: require("../../assets/images/defoul-photo.jpg"),
      [img + "value"]: "",
    });
    setFieldValue(img, "");
  };

  clearPhotos = () => {
    this.setState({
      image1Url: require("../../assets/images/defoul-photo.jpg"),
      image2Url: require("../../assets/images/defoul-photo.jpg"),
      image3Url: require("../../assets/images/defoul-photo.jpg"),
      image4Url: require("../../assets/images/defoul-photo.jpg"),
      image5Url: require("../../assets/images/defoul-photo.jpg"),
    });
  };

  toBase64 = (file, ele) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, "");
        if (encoded.length % 4 > 0) {
          encoded += "=".repeat(4 - (encoded.length % 4));
        }
        resolve(encoded);
        this.setState({ [ele.name + "value"]: encoded });
      };
      reader.onerror = (error) => reject(error);
    });

  componentDidMount() {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata) {
      this.setState({
        userData: udata,
      });
    } else {
      this.setState({ isLoggedIn: false });
    }

    let articlecode = "";
    if (this.props.match && this.props.match.params.articlecode) {
      let articlecode = this.props.match.params.articlecode;
      this.setState({ articlecode: articlecode });

      //console.log("articlecode > " + articlecode);

      let artUrl =
        window.BASE_API_URL + "public/article/getforedit/" + articlecode;

      axios
        .get(artUrl)
        .then((response) => {
          let article = response.data.article;
          let stateData = {
            articleData: response.data,
            checkedOption:
              article.accept_sending_by_post == 1
                ? "accept_sending_by_post"
                : "recover_by_buyer",
            gender: article.gender,
            cat_id: article.category_id,
            sub_category: article.sub_category,
            isUsedSelected: article.type == "used" ? true : false,
            image1Url:
              article.photo_1 != ""
                ? article.photo_1
                : require("../../assets/images/defoul-photo.jpg"),
            image2Url:
              article.photo_2 != ""
                ? article.photo_2
                : require("../../assets/images/defoul-photo.jpg"),
            image3Url:
              article.photo_3 != ""
                ? article.photo_3
                : require("../../assets/images/defoul-photo.jpg"),
            image4Url:
              article.photo_4 != ""
                ? article.photo_4
                : require("../../assets/images/defoul-photo.jpg"),
            image5Url:
              article.photo_5 != ""
                ? article.photo_5
                : require("../../assets/images/defoul-photo.jpg"),
            image1value:
              article.base64_photo_1 != "" ? article.base64_photo_1 : "",
            image2value:
              article.base64_photo_2 != "" ? article.base64_photo_2 : "",
            image3value:
              article.base64_photo_3 != "" ? article.base64_photo_3 : "",
            image4value:
              article.base64_photo_4 != "" ? article.base64_photo_4 : "",
            image5value:
              article.base64_photo_5 != "" ? article.base64_photo_5 : "",
          };

          this.fetchCategories(article.gender);

          this.setState(stateData);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    axios
      .get(window.BASE_API_URL + "public/users/cities")
      .then((response) => {
        this.setState({
          cities: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(window.BASE_API_URL + "public/categories/main_categories")
      .then((response) => {
        this.setState({
          categories: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(window.BASE_API_URL + "public/article/sizes")
      .then((response) => {
        this.setState({
          sizes: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(window.BASE_API_URL + "public/users/colors")
      .then((response) => {
        this.setState({
          colors: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    if (this.state.isLoggedIn === false) {
      return <Redirect to="/login" />;
    }

    if (this.state.goToProduct === true) {
      return <Redirect to={"/article/" + this.state.articlecode} />;
    }

    const { cities, userData, categories, sizes, colors } = this.state;
    let { user } = userData;

    const subCategories = this.state.subCategories;

    if (user) {
      let { user_id } = user;

      if (this.state.articleData) {
        const { articleData } = this.state;
        const { article } = articleData;

        const galImages = [];
        if (articleData.images) {
          articleData.images.map((image, index) => {
            galImages.push({ original: image, thumbnail: image });
          });
        }

        if (article) {
          return (
            <div>
              <div className="page-wrapper product-form">
                <div className="container">
                  <Formik
                    initialValues={{
                      user_id: user_id,
                      articlecode: article.product_code,
                      image1: article.base64_photo_1,
                      image2: article.base64_photo_2,
                      image3: article.base64_photo_3,
                      image4: article.base64_photo_4,
                      image5: article.base64_photo_5,
                      type: article.type,
                      gender: article.gender,
                      brand: article.brand_id,
                      state: article.state,
                      title: article.title,
                      description: article.description,
                      quantity: article.quantity,
                      category_id: article.category_id,
                      sub_category: article.sub_category,
                      size: article.size,
                      color: article.color,
                      city_id: article.city_id,
                      sale_price: article.sale_price,
                      buying_price:
                        article.buying_price > 0 ? article.buying_price : "",
                      accept_exchange: article.accept_exchange,
                      accept_sending_by_post: article.accept_sending_by_post,
                      recover_by_buyer: article.recover_by_buyer,
                      recovery_conditions: article.recovery_conditions,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      setSubmitting(true);

                      this.props.showPageLoader();

                      values.image1 = this.state.image1value;
                      values.image2 = this.state.image2value;
                      values.image3 = this.state.image3value;
                      values.image4 = this.state.image4value;
                      values.image5 = this.state.image5value;

                      axios
                        .post(
                          window.BASE_API_URL + "public/article/update",
                          values
                        )
                        .then((response) => {
                          setSubmitting(false);
                          this.setState({
                            showSuccess: true,
                            goToProduct: true,
                          });

                          this.props.addFlashMessage({
                            type: "success",
                            message:
                              "Votre article a été mis à jour avec succès!",
                          });

                          this.props.hidePageLoader();
                        })
                        .catch((err) => {
                          setSubmitting(false);
                        });
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      isValid,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        encType="multipart/form-data"
                      >
                        <input
                          type="hidden"
                          name="user_id"
                          id="user_id"
                          value={values.user_id}
                        />
                        <input
                          type="hidden"
                          name="articlecode"
                          id="articlecode"
                          value={values.articlecode}
                        />
                        <div className="content-block sin-up-content-block">
                          <div className="heading-2">
                            <i className="fas fa-camera-retro"></i> Mets en
                            évidence ton Article!
                          </div>
                          <div className="row">
                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2 image-required">
                              <div
                                className={
                                  "update-pic-btns " +
                                  (values.image1 && "previewed")
                                }
                              >
                                <img
                                  src={this.state.image1Url}
                                  className="img-responsive img-preview"
                                  alt="user-my-profile-defoul"
                                />
                                <input
                                  id="image1"
                                  name="image1"
                                  type="file"
                                  accept="image/*"
                                  className="attachment_upload"
                                  onChange={(e) => {
                                    this.changeImagePreview(e, setFieldValue);
                                  }}
                                  //value={values.image1}
                                />
                                <a
                                  className="remove-picture"
                                  onClick={() =>
                                    this.removeImage("image1", setFieldValue)
                                  }
                                >
                                  <i className="fas fa-times"></i>
                                </a>
                              </div>
                              <div className="upload-cont">
                                <span>Photo 1</span>Ton article de face, en
                                entier et sur un fond neutre.
                              </div>
                              <div className="upload-red">Obligatoire</div>
                              <Error
                                touched={touched.image1}
                                message={errors.image1}
                                style={{ position: "relative", bottom: 0 }}
                              />
                            </div>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                              <div
                                className={
                                  "update-pic-btns " +
                                  (values.image2 && "previewed")
                                }
                              >
                                <img
                                  src={this.state.image2Url}
                                  className="img-responsive img-preview"
                                  alt="user-my-profile-defoul"
                                />
                                <input
                                  id="image2"
                                  name="image2"
                                  type="file"
                                  accept="image/*"
                                  className="attachment_upload"
                                  onChange={(e) => {
                                    this.changeImagePreview(e, setFieldValue);
                                  }}
                                />
                                <a
                                  className="remove-picture"
                                  onClick={() =>
                                    this.removeImage("image2", setFieldValue)
                                  }
                                >
                                  <i className="fas fa-times"></i>
                                </a>
                              </div>
                              <div className="upload-cont">
                                <span>Photo 2</span>L'arrière de ton article.
                              </div>
                              <Error
                                touched={touched.image2}
                                message={errors.image2}
                                style={{ position: "relative", bottom: 0 }}
                              />
                            </div>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                              <div
                                className={
                                  "update-pic-btns " +
                                  (values.image3 && "previewed")
                                }
                              >
                                <img
                                  src={this.state.image3Url}
                                  className="img-responsive img-preview"
                                  alt="user-my-profile-defoul"
                                />
                                <input
                                  id="image3"
                                  name="image3"
                                  type="file"
                                  accept="image/*"
                                  className="attachment_upload"
                                  onChange={(e) => {
                                    this.changeImagePreview(e, setFieldValue);
                                  }}
                                />
                                <a
                                  className="remove-picture"
                                  onClick={() =>
                                    this.removeImage("image3", setFieldValue)
                                  }
                                >
                                  <i className="fas fa-times"></i>
                                </a>
                              </div>
                              <div className="upload-cont">
                                <span>Photo 3</span>Ton article porté ou sur
                                cintre.
                              </div>
                              <Error
                                touched={touched.image3}
                                message={errors.image3}
                                style={{ position: "relative", bottom: 0 }}
                              />
                            </div>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                              <div
                                className={
                                  "update-pic-btns " +
                                  (values.image4 && "previewed")
                                }
                              >
                                <img
                                  src={this.state.image4Url}
                                  className="img-responsive img-preview"
                                  alt="user-my-profile-defoul"
                                />
                                <input
                                  id="image4"
                                  name="image4"
                                  type="file"
                                  accept="image/*"
                                  className="attachment_upload"
                                  onChange={(e) => {
                                    this.changeImagePreview(e, setFieldValue);
                                  }}
                                />
                                <a
                                  className="remove-picture"
                                  onClick={() =>
                                    this.removeImage("image4", setFieldValue)
                                  }
                                >
                                  <i className="fas fa-times"></i>
                                </a>
                              </div>
                              <div className="upload-cont">
                                <span>Photo 4</span>L'étiquette.
                              </div>
                              <Error
                                touched={touched.image4}
                                message={errors.image4}
                                style={{ position: "relative", bottom: 0 }}
                              />
                            </div>
                            <div className="col-6 col-sm-4 col-md-4 col-lg-3 col-xl-2">
                              <div
                                className={
                                  "update-pic-btns " +
                                  (values.image5 && "previewed")
                                }
                              >
                                <img
                                  src={this.state.image5Url}
                                  className="img-responsive img-preview"
                                  alt="user-my-profile-defoul"
                                />
                                <input
                                  id="image5"
                                  name="image5"
                                  type="file"
                                  accept="image/*"
                                  className="attachment_upload"
                                  onChange={(e) => {
                                    this.changeImagePreview(e, setFieldValue);
                                  }}
                                />
                                <a
                                  className="remove-picture"
                                  onClick={() =>
                                    this.removeImage("image5", setFieldValue)
                                  }
                                >
                                  <i className="fas fa-times"></i>
                                </a>
                              </div>
                              <div className="upload-cont">
                                <span>Photo 5</span>Les éventuels défauts de ton
                                article
                              </div>
                              <Error
                                touched={touched.image5}
                                message={errors.image5}
                                style={{ position: "relative", bottom: 0 }}
                              />
                            </div>
                          </div>
                          <div className="heading-2">
                            <i className="fas fa-edit"></i> Décris ton Article
                          </div>

                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Votre article est<span>*</span>
                            </div>
                            <select
                              id="type"
                              name="type"
                              value={values.type}
                              onChange={(e) => {
                                handleChange(e);
                                this.checkUsedSelected(e);
                              }}
                            >
                              <option value="">Selectionnez</option>
                              <option value="new">Neuf</option>
                              <option value="used">Occasion</option>
                            </select>
                            <Error
                              touched={touched.type}
                              message={errors.type}
                            />
                          </div>

                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Pubilc<span>*</span>
                            </div>
                            <select
                              id="gender"
                              name="gender"
                              value={values.gender}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({
                                  gender: e.currentTarget.value,
                                });
                                this.fetchCategories(e.currentTarget.value);
                              }}
                            >
                              <option value="">Public</option>
                              <option value="homme">Homme</option>
                              <option value="femme">Femme</option>
                              <option value="enfant">Enfant</option>
                            </select>
                            <Error
                              touched={touched.gender}
                              message={errors.gender}
                            />
                          </div>

                          <div
                            className="form-group prod-from"
                            style={{
                              display: this.state.hideBrand ? "none" : "block",
                            }}
                          >
                            <div className="form-group-label">
                              Marque<span>*</span>
                            </div>
                            <input
                              type="text"
                              placeholder="Marque"
                              id="brand"
                              name="brand"
                              value={values.brand}
                              onChange={handleChange}
                            />
                            <Error
                              touched={touched.brand}
                              message={errors.brand}
                            />
                          </div>

                          {/* <div className="check-box">
                        <input
                          className="filled-in"
                          type="checkbox"
                          id="no_brand"
                          name="no_brand"
                          value="1"
                          onChange={(handleChange, this.handleBrandCheck)}
                        />
                        <label htmlFor="no_brand">
                          Je ne connais pas la marque
                        </label>
                        <Error
                          touched={touched.no_brand}
                          message={errors.no_brand}
                        />
                      </div> */}
                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Objet à vendre<span>*</span>
                            </div>
                            <input
                              type="text"
                              placeholder="Object a vendre"
                              id="title"
                              name="title"
                              value={values.title}
                              onChange={handleChange}
                            />
                            <Error
                              touched={touched.title}
                              message={errors.title}
                            />
                          </div>

                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Description<span>*</span>
                            </div>
                            <textarea
                              rows="2"
                              className="text-area"
                              id="description"
                              name="description"
                              value={values.description}
                              onChange={handleChange}
                            ></textarea>
                            <Error
                              touched={touched.description}
                              message={errors.description}
                            />
                          </div>

                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Quantité<span>*</span>
                            </div>
                            <input
                              type="number"
                              min="1"
                              max="10000"
                              placeholder="1"
                              id="quantity"
                              name="quantity"
                              value={values.quantity}
                              onChange={handleChange}
                            />
                            <Error
                              touched={touched.quantity}
                              message={errors.quantity}
                            />
                          </div>

                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Catégorie<span>*</span>
                            </div>
                            <select
                              id="category_id"
                              name="category_id"
                              value={values.category_id}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({
                                  cat_id: e.currentTarget.value,
                                });
                                this.fetchSubCategories(e.currentTarget.value);
                              }}
                            >
                              <option value="">
                                Selectionnez une Categorie
                              </option>
                              {categories.length
                                ? categories.map((category) => (
                                    <option
                                      value={category.cat_id}
                                      key={category.cat_id}
                                    >
                                      {category.title}
                                    </option>
                                  ))
                                : null}
                            </select>
                            <Error
                              touched={touched.category_id}
                              message={errors.category_id}
                            />
                          </div>

                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Sous-catégorie<span>*</span>
                            </div>
                            <select
                              id="sub_category"
                              name="sub_category"
                              value={values.sub_category}
                              onChange={(e) => {
                                handleChange(e);
                                this.setState({
                                  sub_category: e.currentTarget.value,
                                });
                                this.fetchSizes(e.currentTarget.value);
                              }}
                            >
                              <option value="">
                                Selectionnez une Categorie
                              </option>
                              {subCategories.length
                                ? subCategories.map((scat) => (
                                    <option
                                      value={scat.cat_id}
                                      key={scat.cat_id}
                                    >
                                      {scat.title}
                                    </option>
                                  ))
                                : null}
                            </select>
                            <Error
                              touched={touched.sub_category}
                              message={errors.sub_category}
                            />
                          </div>

                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Taille<span>*</span>
                            </div>
                            <select
                              id="size"
                              name="size"
                              value={values.size}
                              onChange={handleChange}
                            >
                              <option value="">Taille</option>
                              {sizes.length
                                ? sizes.map((size) => (
                                    <option
                                      value={size.value}
                                      key={size.size_id}
                                    >
                                      {size.value}
                                    </option>
                                  ))
                                : null}
                            </select>
                            <Error
                              touched={touched.size}
                              message={errors.size}
                            />
                          </div>

                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Couleur<span>*</span>
                            </div>
                            <select
                              id="color"
                              name="color"
                              value={values.color}
                              onChange={handleChange}
                            >
                              <option value="">Couleur</option>
                              {colors.length
                                ? colors.map((color) => (
                                    <option
                                      value={color.value}
                                      key={color.color_id}
                                    >
                                      {color.value}
                                    </option>
                                  ))
                                : null}
                            </select>
                            <Error
                              touched={touched.color}
                              message={errors.color}
                            />
                          </div>

                          <div
                            className="form-group prod-from"
                            style={{
                              display:
                                this.state.isUsedSelected == true
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <div className="form-group-label">
                              Etat<span>*</span>
                            </div>
                            <select
                              id="state"
                              name="state"
                              value={values.state}
                              onChange={handleChange}
                            >
                              <option value="">Etat</option>
                              <option value="very_good">Trés bon état</option>
                              <option value="good">Bon état</option>
                              <option value="average">Etat moyen</option>
                              <option value="new_without_label">
                                Neuf sans étiquette
                              </option>
                              <option value="new_with_label">
                                Neuf avec étiquette
                              </option>
                            </select>
                            <Error
                              touched={touched.state}
                              message={errors.state}
                            />
                          </div>

                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Wilaya<span>*</span>
                            </div>
                            <select
                              id="city_id"
                              name="city_id"
                              value={values.city_id}
                              onChange={handleChange}
                            >
                              <option value="">Wilaya</option>
                              {cities.length
                                ? cities.map((city) => (
                                    <option
                                      value={city.city_id}
                                      key={city.city_id}
                                    >
                                      {city.title}
                                    </option>
                                  ))
                                : null}
                            </select>
                            <Error
                              touched={touched.city_id}
                              message={errors.city_id}
                            />
                          </div>

                          <div className="heading-2">
                            <i className="far fa-money-bill-alt"></i> Fixes tes
                            conditions
                          </div>

                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Prix de vente<span>*</span>
                            </div>
                            <input
                              type="number"
                              min="1"
                              step="1"
                              placeholder="Prix de vente"
                              id="sale_price"
                              name="sale_price"
                              value={values.sale_price}
                              onChange={handleChange}
                            />
                            <Error
                              touched={touched.sale_price}
                              message={errors.sale_price}
                            />
                          </div>

                          <div className="form-group prod-from">
                            <div className="form-group-label">
                              Prix d'origine
                            </div>
                            <input
                              type="number"
                              min="1"
                              step="1"
                              placeholder="Prix d'origine"
                              id="buying_price"
                              name="buying_price"
                              value={values.buying_price}
                              onChange={handleChange}
                            />
                            <Error
                              touched={touched.buying_price}
                              message={errors.buying_price}
                            />
                          </div>

                          <div className="check-box">
                            <input
                              className="filled-in"
                              id="accept_exchange"
                              name="accept_exchange"
                              type="checkbox"
                              value="1"
                              onChange={(e) => {
                                if (e.target.checked)
                                  setFieldValue("accept_exchange", "1");
                                else setFieldValue("accept_exchange", "0");
                              }}
                              checked={
                                values.accept_exchange == 1 ? true : false
                              }
                            />
                            <label htmlFor="accept_exchange">
                              j’accepte d’échanger mon produit
                            </label>
                            <Error
                              touched={touched.accept_exchange}
                              message={errors.accept_exchange}
                            />
                          </div>

                          <div className="check-box">
                            <input
                              className="filled-in"
                              type="checkbox"
                              id="recover_by_buyer"
                              name="recover_by_buyer"
                              value="1"
                              checked={
                                this.state.checkedOption == "recover_by_buyer"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                //handleChange(e);
                                this.chekOptions(e, setFieldValue);
                              }}
                            />
                            <label htmlFor="recover_by_buyer">
                              Produits à récupérer par l'achteur<span>*</span>
                            </label>

                            <Error
                              touched={touched.recover_by_buyer}
                              message={errors.recover_by_buyer}
                            />
                          </div>

                          <div className="check-box">
                            <input
                              className="filled-in"
                              type="checkbox"
                              id="accept_sending_by_post"
                              name="accept_sending_by_post"
                              value="1"
                              checked={
                                this.state.checkedOption ==
                                "accept_sending_by_post"
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                //handleChange(e);
                                this.chekOptions(e, setFieldValue);
                              }}
                            />
                            <label htmlFor="accept_sending_by_post">
                              J'assure la livraison
                            </label>
                            <Error
                              touched={touched.accept_sending_by_post}
                              message={errors.accept_sending_by_post}
                            />
                          </div>

                          <div className="form-group-label form-group">
                            Conditions de récupération/livraison
                          </div>
                          <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  placeholder=""
                                  id="recovery_conditions"
                                  name="recovery_conditions"
                                  value={values.recovery_conditions}
                                  onChange={handleChange}
                                />
                                <Error
                                  touched={touched.recovery_conditions}
                                  message={errors.recovery_conditions}
                                />
                              </div>
                            </div>
                          </div>
                          {this.state.showInvalidError == true && (
                            <Alert className="alert alert-danger">
                              Veuillez compléter les champs en rouge
                            </Alert>
                          )}
                          {this.state.showSuccess && (
                            <Alert
                              className="alert alert-success"
                              onClose={() =>
                                this.setState({ showSuccess: false })
                              }
                              dismissible
                            >
                              Détails enregistrés
                            </Alert>
                          )}
                          <div className="but text-center">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              onClick={() => this.checkValid(isValid)}
                            >
                              Mise à jour
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                  {/* <div>
                {this.state.showSuccess && (
                  <Alert
                    className="alert alert-success"
                    onClose={() => this.setState({ showSuccess: false })}
                    dismissible
                  >
                    Details saved
                  </Alert>
                )}
              </div> */}
                </div>
              </div>
              <div
                className={
                  "page-loader " +
                  (this.props.pageLoading.active === true ? "active" : "")
                }
              >
                <div className=" text-center">
                  <i
                    className="fa fa-spinner fa-spin"
                    style={{ fontSize: "4em" }}
                  ></i>
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <div className="page-wrapper article-details">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 p-4 text-center">
                      <i
                        className="fa fa-spinner fa-spin"
                        style={{ fontSize: "4em" }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      } else {
        return (
          <div>
            <div className="page-wrapper article-details">
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 p-4 text-center">
                    <i
                      className="fa fa-spinner fa-spin"
                      style={{ fontSize: "4em" }}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else
      return (
        <div>
          <div className="page-wrapper article-details">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 p-4 text-center">
                  <i
                    className="fa fa-spinner fa-spin"
                    style={{ fontSize: "4em" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

Editarticle.propTypes = {
  userData: PropTypes.array.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  showPageLoader: PropTypes.func.isRequired,
  hidePageLoader: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
    pageLoading: state.pageLoaderReducer,
  };
};

export default connect(stateToProps, {
  addFlashMessage,
  showPageLoader,
  hidePageLoader,
})(Editarticle);
