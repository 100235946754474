import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { addFlashMessage } from "../actions/flashMessages";
import { addUserData } from "../actions/userActions";

class FacebookLoginButton extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
      fbData: [],
    };
  }

  componentClicked = () => {
    console.log("compnent clicked");
  };

  responseFacebook = (response) => {
    if (response.status !== "not_authorized" && response.status !== "unknown") {
      /* console.log(JSON.stringify(response));
      sessionStorage.setItem(
        "userData",
        '{"status":"ok","user":{"user_id":"19","username":"0","passkey":"","first_name":"Linda","last_name":"Vijayve","email":"linda_sbocgxg_vijayve@tfbnw.net","phone_number":"","introduction":"","size":"","size2":"","shoe_size":"","address_1":"","address_2":"","postcode":"","city":"0","dob":null,"gender":"","accept_articl_exchange":"0","accept_recive_parcel":"0","user_level":"0","profile_photo":"https://platform-lookaside.fbsbx.com/platform/profilepic/?asid=112491870448050&height=50&width=50&ext=1590927928&hash=AeSnC9_xCJSd1HJT","profile_banner":"","fb_user_id":"112491870448050","fb_key":"EAAD8nd2FRwwBADUXa5AcKEsFCZAqq64PvJLW4fMHGILRXE22tIP2ZBt8jLFKQeg5rsvd8d8x4ZClQicE5qRzNVL9wRwnzCaZAxf9AeMW0HVbYWYq7OQuJwT4FvIlFup4PClS7MpGUmJCeXZBB7qHltfS9SOzh3IQkwgXDCvVIl4s1CEXRjNc9ErzczkaAD7C8x3xJNr9Y9GuZBTPsptCEg","fb_secure":"","fb_username":"","fb_email":"","subscribed":"0","status":"","registered_date":"2020-05-01 07:25:35","updated_at":null,"deleted_at":null,"follower_count":"0","following_count":"0","articles_count":"0","favorite_count":"0","city_name":""},"auth_key":"kct068nKbocuw0UImd+v0DHZ1KriI6K8AtTMIrQmo7mJS8JispfDxWVG7NGndgHnXF1KYsn1CAjom6MdjT4jwHcOVVjtv41tqQ/LO4oCgGOjtF4xNOSUrAH6tZ5phHAme3g/IpUHJ71LXvt6zhEsFHKkYA=="}'
      );
      this.setState({
        isLoggedIn: true,
        fbData: response,
      }); */

      axios
        .post(window.BASE_API_URL + "public/users/fblogin_auth", response)
        .then((response) => {
          if (response.data.status == "ok") {
            //sessionStorage.setItem("userData", JSON.stringify(response.data));
            this.props.addFlashMessage({
              type: "success",
              message: "Welcome back!",
            });

            this.props.addUserData({
              userData: response.data,
            });

            this.setState({
              isLoggedIn: true,
              fbData: response,
            });
          } else if (response.data.status == "err") {
            console.log("error");
            console.log(response.data);
          }
          console.log("response");
          console.log(response.data.status);
        })
        .catch((err) => {
          console.log("error2");
          console.log(err);
        });
    } else {
      this.setState({
        isLoggedIn: false,
      });
    }
  };

  render() {
    let fbContent;

    if (this.state.isLoggedIn === true) {
      return <Redirect to="/home" />;
      //window.location.href = "https://dz.icomettech.com/";
    }

    if (sessionStorage.getItem("userData")) {
      //window.location.href = "https://dz.icomettech.com/";
      //return <Redirect to="/home" />;
    }

    if (this.state.isLoggedIn) {
      fbContent = null;
    } else {
      fbContent = (
        <FacebookLogin
          appId="277754956564236"
          autoLoad={false}
          fields="name,email,picture"
          onClick={this.componentClicked}
          callback={this.responseFacebook}
          isMobile={false}
          disableMobileRedirect={true}
          render={(renderProps) => (
            <a
              className="facebook"
              href="#!"
              style={{ margin: "0px auto" }}
              onClick={renderProps.onClick}
            >
              <i className="fab fa-facebook-f"></i> &nbsp; Facebook
            </a>
          )}
        />
      );
    }
    return <>{fbContent}</>;
  }
}

FacebookLoginButton.propTypes = {
  userData: PropTypes.array.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  addUserData: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, { addFlashMessage, addUserData })(
  FacebookLoginButton
);
