import { ADD_USER_DATA, DELETE_USER_DATA, UPDATE_USER_DATA } from "./types";

export function addUserData(data) {
  return {
    type: ADD_USER_DATA,
    data,
  };
}

export function updateUserData(data) {
  return {
    type: UPDATE_USER_DATA,
    data,
  };
}

export function deleteUserData(id) {
  return {
    type: DELETE_USER_DATA,
    id,
  };
}
