import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import "react-widgets/dist/css/react-widgets.css";
import { Link } from "react-router-dom";
import { UserListBox } from "../templates/UserListBox";
import * as QueryString from "query-string";

export class Mostfollowedusers extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    const params = QueryString.parse(this.props.location.search, {
      arrayFormat: "bracket",
    });

    this.state = {
      loading: true,
      endOfResult: false,
      loggedInUser: [],
      items: 12,
      followedUsers: [],
      followedPage: 1,
      followedLoading: false,
      followedEnd: true,
      queryString: params.qr ? params.qr : "",
    };
  }

  componentDidMount() {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
      //console.log(this.props.productData);
      if (udata) {
        this.setState(
          {
            loggedInUser: udata.user,
            userData: udata,
          },
          () => {
            this.fetchMostFollowed();
          }
        );
      } else {
        this.fetchMostFollowed();
      }
    } else {
      this.fetchMostFollowed();
    }

    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillReceiveProps(newProps) {
    console.log(newProps);
    if (newProps.location) {
      const params = QueryString.parse(newProps.location.search, {
        arrayFormat: "bracket",
      });

      this.setState({ queryString: params.qr ? params.qr : "" }, () =>
        this.fetchMostFollowed()
      );
    }
  }

  isBottom(el) {
    if (el) return el.getBoundingClientRect().bottom <= window.innerHeight;
    return false;
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedUsers = document.getElementById("users-wrapper");
    if (this.isBottom(wrappedUsers) && this.state.followedEnd === false) {
      this.setState({ followedPage: this.state.followedPage + 1 }, () => {
        this.fetchMostFollowed();
      });
    }
  };

  fetchMostFollowed = () => {
    let params = {};

    if (this.state.followersEnd) return;

    this.setState({ followedLoading: true });

    params.page = this.state.followedPage;
    params.items = 10;
    params.user_id = this.state.loggedInUser.user_id
      ? this.state.loggedInUser.user_id
      : 0;
    params.qr = this.state.queryString;

    axios
      .get(window.BASE_API_URL + "public/users/most_followed", {
        params: params,
      })
      .then((response) => {
        if (this.state.followedPage > 1) {
          if (
            response.data.profiles != undefined &&
            response.data.profiles.length > 0
          ) {
            this.setState((prevState) => ({
              followedUsers: prevState.followedUsers.concat(
                response.data.profiles
              ),
              followedLoading: false,
            }));
          } else {
            this.setState({
              followedLoading: false,
              followedEnd: true,
            });
          }
        } else {
          this.setState({
            followedUsers: response.data.profiles,
            followedLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="container">
        <div
          data-responsive-tabs
          className="shop-profile-tabs tabs responsive-tabs responsive-tabs-initialized"
        >
          <nav>
            <ul>
              <li>
                <Link
                  to={{
                    pathname: "/mostlikedarticles",
                    search: "?sort=most_loved",
                    hash: "topbar",
                  }}
                >
                  <i className="fas fa-store"></i> Les articles les plus aimés
                </Link>
              </li>
              <li className="active">
                <Link to={"/mostfollowedusers"} className="disabled-link">
                  <i className="fas fa-users"></i> Les vendeurs les plus suivis
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <section id="users">
          <div className="listing-wrapper" id="users-wrapper">
            <div className="search-listing">
              <div className="row">
                {this.state.followedUsers &&
                  this.state.followedUsers.map((profile, index) => (
                    <UserListBox
                      userProfile={profile}
                      userData={this.state.userData}
                      twoSect={true}
                      key={"fu-" + index}
                    />
                  ))}
              </div>
              <div className="text-center load-more-btn menu-list">
                {this.state.followedLoading && (
                  <div>
                    Chargement <i className="fa fa-spinner fa-spin"></i>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

Mostfollowedusers.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Mostfollowedusers);
