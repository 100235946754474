import React, { Component } from "react";
import axios from "axios";
import { Button, Alert } from "react-bootstrap";
import NumberFormat from "react-number-format";

class PurchaseStep2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: [],
      submiting: false,
    };
  }

  componentDidMount() {
    let udata = [];

    if (this.props.userData) {
      udata = this.props.userData;
      this.setState({ userData: udata });
    }

    this.isValidated = this.isValidated.bind(this);
  }

  isValidated() {
    return false;
  }

  putOrder = () => {
    let artDelUrl = window.BASE_API_URL + "public/orders/put_order";
    let data = {
      user_id: this.state.userData.user.user_id,
      articlecode: this.props.articlecode,
      orderData: this.props.orderData,
    };
    this.setState({ submiting: true });
    axios
      .post(artDelUrl, data, {
        headers: {
          Authorization: this.state.userData.auth_key,
        },
      })
      .then((response) => {
        if (response.data.status == "ok") {
          this.props.jumpToStep(2);
        }
        this.setState({ submiting: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 offset-md-1 offset-lg-1 col-md-10 col-lg-10">
          <Alert className="alert alert-info text-center">
            <p>
              Le montant à payer est de :{" "}
              <strong>
                <NumberFormat
                  value={this.props.article && this.props.article.sale_price}
                  displayType={"text"}
                  thousandSeparator=" "
                  className="mainPrice"
                  renderText={(value) => <>{value} DA</>}
                />
              </strong>
            </p>
            <p>L'adresse enregistrée pour la livraison est la suivante:</p>
            <p>
              {this.props.orderData.username}
              <br />
              {this.props.orderData.address_1}
              {/* <br />
              {this.props.orderData.postcode} */}
              <br />
              {this.props.orderData.cityName}
              <br />
              Numéro de téléphone: {this.props.orderData.phone_number}
            </p>
          </Alert>
          <Button
            className="btn btn-primary"
            onClick={() => this.putOrder()}
            disabled={this.state.submiting}
          >
            Confirmer la commande
          </Button>
        </div>
      </div>
    );
  }
}

export default PurchaseStep2;
