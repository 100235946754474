import React, { useState } from "react";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import Error from "../templates/Errors";
import { Alert } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("l’adresse mail n’est pas valide")
    .required("l’adresse mail n’est pas valide")
    .max(255, "Must be shorter than 255 characters"),
});

function ForgotPassword() {
  const [isLoggedin, setisLoggedin] = useState(false);
  const [isKeysent, setisKeysent] = useState(false);
  const [showInvalidError, setshowInvalidError] = useState(false);

  if (sessionStorage.getItem("userData")) {
    return <Redirect to="/home" />;
  }

  if (isKeysent) {
    return <Redirect to="/login" />;
  }

  const checkValid = (isValid) => {
    setshowInvalidError(!isValid);
  };

  return (
    <div>
      <div className="sin-up-wrapper login">
        <div className="container">
          <div className="sin-up-content-block">
            <div className="sin-up-head">Mot de passe oublié</div>
            <div className="sin-up-sub">
              Merci d'indiquer votre adresse e-mail puis de cliquer sur
              "Envoyer". Nous vous enverrons un e-mail contenant un lien afin de
              réinitialiser votre mot de passe
            </div>
            <Formik
              initialValues={{
                email: "",
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                axios
                  .post(
                    window.BASE_API_URL + "public/users/forgot_password",
                    values
                  )
                  .then((response) => {
                    resetForm();
                    if (response.data.status == "ok") {
                      setisKeysent(true);
                    }
                  })
                  .catch((err) => {
                    setSubmitting(false);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
                isValid,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="form-group">
                    <div className="form-group-label">
                      Adresse mail<span className="labe-red-top">*</span>
                    </div>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      autoComplete="nope"
                    />
                    <Error touched={touched.email} message={errors.email} />
                  </div>
                  {showInvalidError == true && (
                    <Alert className="alert alert-danger">
                      Veuillez compléter les champs en rouge
                    </Alert>
                  )}
                  <div className="sign-up-button-main">
                    <button
                      type="submit"
                      className="sign-up-butt"
                      disabled={isSubmitting}
                      onClick={() => checkValid(isValid)}
                    >
                      Envoyer
                    </button>
                  </div>
                </form>
              )}
            </Formik>
            <div className="dont-have-account">
              Revenir à <Link to="/login">Connexion</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
