import React from "react";
import { useRouteMatch } from "react-router-dom";

const generatePage = (page) => {
  const compPageName = page.charAt(0).toUpperCase() + page.slice(1);
  const component = () => require(`./pages/${compPageName}`).default;
  //console.log(component);
  try {
    return React.createElement(component());
  } catch (err) {
    console.log(err);
    return React.createElement(() => 404);
  }
};

function PageRenderer() {
  const {
    params: { page },
  } = useRouteMatch();

  return generatePage(page);
}

export default PageRenderer;
