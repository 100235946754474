import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";

export class FeaturedProfile extends Component {
  constructor(props) {
    super(props);
    //console.log(props);
    this.state = {
      isLoggedIn: true,
      buttonLoading: false,
      following: this.props.profileData
        ? this.props.profileData.following
        : false,
      totalFollowers: 0,
      loggedInUser: this.props.userData ? this.props.userData : [],
      userInfo: this.props.profileData ? this.props.profileData : [],
      homeData: [],
    };
  }

  componentDidMount() {
    //console.log(this.props.userInfo);
    //this.setState({ userData: JSON.parse(this.props.userInfo) });
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata) {
      this.setState({
        loggedInUser: udata.user,
      });
    }
  }

  follow = () => {
    let udata = [];
    let followUrl = window.BASE_API_URL + "public/users/follow";

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata.user) {
      this.setState({ buttonLoading: true });

      let data = {
        user_id: this.state.userInfo.user_id,
        follower_id: this.state.loggedInUser.user_id,
      };

      axios
        .post(followUrl, data, {
          headers: {
            Authorization: udata.auth_key,
          },
        })
        .then((response) => {
          this.setState({ goToProfile: true, buttonLoading: false });

          if (response.data.status == "ok" && response.data.followers) {
            this.setState({
              totalFollowers: response.data.followers.total,
              following: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  unfollow = () => {
    let udata = [];
    let followUrl = window.BASE_API_URL + "public/users/unfollow";

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata.user) {
      this.setState({ buttonLoading: true });

      let data = {
        user_id: this.state.userInfo.user_id,
        follower_id: this.state.loggedInUser.user_id,
      };

      axios
        .post(followUrl, data, {
          headers: {
            Authorization: udata.auth_key,
          },
        })
        .then((response) => {
          this.setState({ goToProfile: true, buttonLoading: false });

          if (response.data.status == "ok" && response.data.followers) {
            this.setState({
              totalFollowers: response.data.followers.total,
              following: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    if (this.state.userInfo) {
      let userInfo = this.state.userInfo;
      //console.log(userInfo);

      let encodeUserID = new Buffer(userInfo.user_id).toString("base64");
      let followBtn = null;

      if (
        !this.state.loggedInUser ||
        (this.state.loggedInUser &&
          this.state.loggedInUser.user_id != userInfo.user_id)
      ) {
        if (this.state.following === true) {
          followBtn = (
            <button
              type="button"
              className={
                "footer-hm-chef-bx" +
                (this.state.buttonLoading ? " loading" : "")
              }
              onClick={this.unfollow}
            >
              <i className="fas fa-check"></i> Abonné
            </button>
          );
        } else {
          followBtn = (
            <button
              type="button"
              className={
                "footer-hm-chef-bx" +
                (this.state.buttonLoading ? " loading" : "")
              }
              onClick={this.follow}
            >
              S'abonner
            </button>
          );
        }
      }

      return (
        <>
          <div className="main-block">
            <div className="box-image">
              <Link to={"/profile/" + encodeUserID} target="_blank">
                {userInfo.profile_photo && (
                  <img
                    src={userInfo.profile_photo ? userInfo.profile_photo : ""}
                    alt="nearby-chef-img1"
                  />
                )}
              </Link>
            </div>
            <div className="cate-address">
              <div className="name-tx-pear">
                <Link to={"/profile/" + encodeUserID}>
                  {userInfo.username && (
                    <span>{userInfo.username.toUpperCase()} </span>
                  )}
                </Link>
              </div>
              <div className="location-mp">
                {userInfo.articles_count && userInfo.articles_count} Articles en
                vente
              </div>
              <div className="reviewratingsr new-review-hm">
                <div className="reviewrating-min">
                  <div className="tabl-cel">
                    <StarRatingComponent
                      name="rate2"
                      renderStarIcon={() => <i className="fa fa-star"></i>}
                      renderStarIconHalf={() => <i className="fa fa-star"></i>}
                      starCount={5}
                      value={parseInt(userInfo.average_ratings)}
                      emptyStarColor="#cfcfcf"
                      editing={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="main-block">
            <div className="box-image">
              <a href="shop-profile.html">
                <img
                  src={require("../../assets/images/boutique-1.jpg")}
                  alt="nearby-chef-img1"
                />
              </a>
            </div>
            <div className="cate-address">
              <div className="profile-avtr-pear">
                <img
                  src={require("../../assets/images/home-chef-profile.jpg")}
                  alt=""
                />
              </div>
              <div className="name-tx-pear">
                <a href="shop-profile.html">Howard K. Stern</a>
              </div>
              <div className="location-mp">685 Articles en vente</div>
              <div className="reviewratingsr new-review-hm">
                <div className="reviewrating-min">
                  <div className="five-str tabl-cel">
                    <i className="fa fa-star fill-str"></i>
                    <i className="fa fa-star fill-str"></i>
                    <i className="fa fa-star fill-str"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                  </div>
                  <div className="text-str tabl-cel">(198 reviews)</div>
                </div>
                <a
                  href="seller-profie-details.html"
                  className="footer-hm-chef-bx"
                >
                  S'abonner
                </a>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}

FeaturedProfile.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(FeaturedProfile);
