import React from "react";

function NotificationModel() {
  return (
    <div>
      <div
        className="modal fade"
        id="notificationmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="notificationmodalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Notifications
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="notification-box">
                <div className="user-img">
                  <img
                    src="images/user1.png"
                    className="img-responsive"
                    alt=""
                  />
                </div>
                <div className="notification-info">
                  <h6>10:34 -Jan 25, 2017</h6>
                  <p>
                    Pellentesque habitant<span> morbi tristique senectus</span>{" "}
                    et netus et malesuada fames ac egestas.
                  </p>
                </div>
              </div>
              <div className="notification-box">
                <div className="user-img">
                  <img
                    src="images/user1.png"
                    className="img-responsive"
                    alt=""
                  />
                </div>
                <div className="notification-info">
                  <h6>10:34 -Jan 25, 2017</h6>
                  <p>
                    Vestibulum tortor quam,
                    <span> feugiat vitae, ultricies eget,</span> tempor sit
                    amet, ante.
                  </p>
                </div>
              </div>
              <div className="notification-box">
                <div className="user-img">
                  <img
                    src="images/user1.png"
                    className="img-responsive"
                    alt=""
                  />
                </div>
                <div className="notification-info">
                  <h6>10:34 -Jan 25, 2017</h6>
                  <p>
                    Vestibulum tortor quam,
                    <span> feugiat vitae, ultricies eget,</span> tempor sit
                    amet, ante.
                  </p>
                </div>
              </div>
              <div className="notification-box">
                <div className="user-img">
                  <img
                    src="images/user1.png"
                    className="img-responsive"
                    alt=""
                  />
                </div>
                <div className="notification-info">
                  <h6>10:34 -Jan 25, 2017</h6>
                  <p>
                    Vestibulum tortor quam,
                    <span> feugiat vitae, ultricies eget,</span> tempor sit
                    amet, ante.
                  </p>
                </div>
              </div>
              <div className="notification-box">
                <div className="user-img">
                  <img
                    src="images/user1.png"
                    className="img-responsive"
                    alt=""
                  />
                </div>
                <div className="notification-info">
                  <h6>10:34 -Jan 25, 2017</h6>
                  <p>
                    Vestibulum tortor quam,
                    <span> feugiat vitae, ultricies eget,</span> tempor sit
                    amet, ante.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationModel;
