import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

class Flashmessage extends Component {
  closeFlashModal = () => {
    this.props.deleteFlashMessage(this.props.message.id);
  };

  render() {
    const { id, type, text } = this.props.message;
    return (
      <div>
        <Modal
          show={true}
          onHide={this.closeFlashModal}
          centered
          className="flash-msg-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div closeButton></div>
            <h4>
              <i className="fas fa-check"></i> {text}
            </h4>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

Flashmessage.propTypes = {
  message: PropTypes.object.isRequired,
  deleteFlashMessage: PropTypes.func.isRequired,
};

export default connect()(Flashmessage);
