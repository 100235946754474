import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Productbox from "./Productbox";
import { Table } from "react-bootstrap";

class StatsTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: true,
      userData: [],
      activeTab: "favorites",
      orders: [],
      favorites: [],
    };
  }

  setTab = (e) => {
    e.preventDefault();
    this.setState({ activeTab: e.target.getAttribute("tabname") });
    this.props.history.push("/stats/" + e.target.getAttribute("tabname"));
  };

  componentDidMount = () => {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata) {
      /* if (sessionStorage.getItem("userData")) {
      let udata = JSON.parse(sessionStorage.getItem("userData")); */
      this.setState({
        userData: udata.user,
      });

      if (udata) {
        axios
          .get(
            window.BASE_API_URL + "public/users/orders/" + udata.user.user_id
          )
          .then((response) => {
            this.setState({
              orders: response.data,
            });
          })
          .catch((err) => {
            console.log(err);
          });

        axios
          .get(
            window.BASE_API_URL +
              "public/article/favorites/" +
              udata.user.user_id
          )
          .then((response) => {
            this.setState({
              favorites: response.data,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      this.setState({ isLoggedIn: false });
    }

    if (this.props.activeSection) {
      this.setState({ activeTab: this.props.activeSection });
    }
  };

  render() {
    const userData = this.props.userInfo;

    if (userData) {
      return (
        <div>
          <div
            data-responsive-tabs
            className="shop-profile-tabs tabs responsive-tabs responsive-tabs-initialized"
          >
            <nav>
              <ul>
                <li
                  className={
                    this.state.activeTab == "favorites" ? "active" : ""
                  }
                >
                  <a href="#!" tabname="favorites" onClick={this.setTab}>
                    Favorites (
                    {this.state.favorites.count
                      ? this.state.favorites.count
                      : 0}
                    )
                  </a>
                </li>
                <li
                  className={this.state.activeTab == "orders" ? "active" : ""}
                >
                  <a href="#!" tabname="orders" onClick={this.setTab}>
                    Orders
                  </a>
                </li>
                <li className={this.state.activeTab == "sale" ? "active" : ""}>
                  <a href="#!" tabname="sale" onClick={this.setTab}>
                    Sale
                  </a>
                </li>
              </ul>
            </nav>
            <div className="content">
              <section
                id="favorites"
                className="mmlr"
                style={{
                  display:
                    this.state.activeTab == "favorites" ? "block" : "none",
                }}
              >
                <div className="row">
                  {this.state.favorites.products &&
                    this.state.favorites.products.map((product, index) => (
                      <div
                        className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 mplr"
                        key={index}
                      >
                        <Productbox productData={product} boxkey={index} />
                      </div>
                    ))}
                </div>
              </section>

              <section
                id="orders"
                style={{
                  display: this.state.activeTab == "orders" ? "block" : "none",
                }}
              >
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Article</th>
                      <th>Cost</th>
                      <th>Quantity</th>
                      <th>Total Cost</th>
                      <th>Order Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="6">No orders has been placed yet</td>
                    </tr>
                  </tbody>
                </Table>
              </section>

              <section
                id="sale"
                style={{
                  display: this.state.activeTab == "sale" ? "block" : "none",
                }}
              >
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>User</th>
                      <th>Article</th>
                      <th>Cost</th>
                      <th>Quantity</th>
                      <th>Total Cost</th>
                      <th>Order Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="8">No product has been sold yet</td>
                    </tr>
                  </tbody>
                </Table>
              </section>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

StatsTabs.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(StatsTabs);
