import { combineReducers } from "redux";
import flashMessages from "./reducers/flashMessages";
import userDataReducer from "./reducers/userDataReducer";
import pageLoaderReducer from "./reducers/pageLoaderReducer";

export default combineReducers({
  flashMessages,
  userDataReducer,
  pageLoaderReducer,
});
