import React, { Component } from "react";
import TopBar from "./header/TopBar";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Navigation from "./header/Navigation";
import NotificationModel from "./header/NotificationModel";
import { Helmet } from "react-helmet";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      userData: [],
    };
  }

  componentDidMount() {
    const description = document.querySelector('meta[name="description"]');
    if (description) {
      description.remove();
    }
  }

  render() {
    const { userData } = this.props;

    return (
      <div>
        <Helmet>
          <title data-react-helmet="true">
            DZDressing | Le premier réseau social de la mode et de videdressing
            en Algérie
          </title>
          <meta
            name="description"
            data-react-helmet="true"
            content="Découvrez la première marketplace algérienne dédiée qu'à la mode! Surtout occasion mais aussi du neuf, plein de conseils et de bons plans mode. Le #3en1 de la mode pour une expérience shopping inégalée"
          />
          <meta
            property="og:title"
            data-react-helmet="true"
            content="DZDressing | Le premier réseau social de la mode et de videdressing
            en Algérie"
          />
          <meta
            property="og:description"
            data-react-helmet="true"
            content="Découvrez la première marketplace algérienne dédiée qu'à la mode! Surtout occasion mais aussi du neuf, plein de conseils et de bons plans mode. Le #3en1 de la mode pour une expérience shopping inégalée"
          />
          <meta property="og:type" data-react-helmet="true" content="website" />
          <meta
            property="og:url"
            data-react-helmet="true"
            content="https://www.dzdressing.com/home"
          />
          <meta
            property="og:image"
            data-react-helmet="true"
            content="https://www.dzdressing.com/dzdressing-social.jpg"
          />

          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=UA-171592324-1`}
          ></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-171592324-1');`}
          </script>
        </Helmet>
        <header className={userData.length > 0 ? "after-menu" : ""}>
          <div id="header-home">
            <TopBar />
            {/* <Navigation /> */}
            <NotificationModel />
          </div>
          <div className="overlay"></div>
        </header>
      </div>
    );
  }
}

Header.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Header);
