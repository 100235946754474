import React, { Component } from "react";

export class Howitworks extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container">
        <div className="clearfix">&nbsp;</div>
        <div className="clearfix">&nbsp;</div>
        <h3 className="text-center">Comment ça marche?</h3>
        <div className="devider"></div>

        <div className="order-steps-section">
          <div className="works-section">
            <h4>3 etapes pour acheter</h4>
            <div className="row">
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="work-icon">
                  <div className="steps-icon steps-icon1">&nbsp;</div>
                  <img
                    src={require("../../assets/images/diversion-arrow-right.png")}
                    alt=""
                    className="diversion-arrow-img"
                  />
                  <span className="step-number-block">1</span>
                </div>
                <div className="work-content">
                  <span>1. Trouvez votre coup de coeur</span> Lorem ipsum dolor
                  sit amet, consectea dipiscing elit, sed do eiusmod tempor
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="work-icon">
                  <div className="steps-icon steps-icon2">&nbsp;</div>
                  <img
                    src={require("../../assets/images/diversion-arrow-right.png")}
                    alt=""
                    className="diversion-arrow-img"
                  />
                  <span className="step-number-block">2</span>
                </div>
                <div className="work-content">
                  <span>2. Mettez vous d'accord avec le vendeur</span> Lorem
                  ipsum dolor sit amet, consectea dipiscing elit, sed do eiusmod
                  tempor
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="work-icon">
                  <div className="steps-icon steps-icon3">&nbsp;</div>
                  <span className="step-number-block">3</span>
                </div>
                <div className="work-content">
                  <span>3. appreciez votre produit</span> Lorem ipsum dolor sit
                  amet, consectea dipiscing elit, sed do eiusmod tempor
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="devider"></div>

        <div className="order-steps-section">
          <div className="works-section">
            <h4>3 etapes pour vendre</h4>
            <div className="row">
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="work-icon">
                  <div className="steps-icon steps-icon4">&nbsp;</div>
                  <img
                    src={require("../../assets/images/diversion-arrow-right.png")}
                    alt=""
                    className="diversion-arrow-img"
                  />
                  <span className="step-number-block">1</span>
                </div>
                <div className="work-content">
                  <span>1. Mettez votre article en vente</span> Lorem ipsum
                  dolor sit amet, consectea dipiscing elit, sed do eiusmod
                  tempor
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="work-icon">
                  <div className="steps-icon steps-icon5">&nbsp;</div>
                  <img
                    src={require("../../assets/images/diversion-arrow-right.png")}
                    alt=""
                    className="diversion-arrow-img"
                  />
                  <span className="step-number-block">2</span>
                </div>
                <div className="work-content">
                  <span>2. Mettez vous d'accord avec l'achteur</span> Lorem
                  ipsum dolor sit amet, consectea dipiscing elit, sed do eiusmod
                  tempor
                </div>
              </div>
              <div className="col-sm-4 col-md-4 col-lg-4">
                <div className="work-icon">
                  <div className="steps-icon steps-icon6">&nbsp;</div>
                  <span className="step-number-block">3</span>
                </div>
                <div className="work-content">
                  <span>3. Encaisser votre argent</span> Lorem ipsum dolor sit
                  amet, consectea dipiscing elit, sed do eiusmod tempor
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Howitworks;
