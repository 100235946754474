import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import UserProfileCard from "../templates/UserProfileCard";
import StatsTabs from "../templates/StatsTabs";

class Stats extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      userInfo: [],
      userData: [],
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("userData")) {
      this.setState({
        userData: JSON.parse(sessionStorage.getItem("userData")),
      });
    } else {
      this.setState({ isLoggedIn: false });
    }
  }

  render() {
    if (this.state.isLoggedIn === false) {
      return <Redirect to="/login" />;
    }

    const userData = JSON.parse(sessionStorage.getItem("userData"));

    let activeSection = "favorites";

    if (this.props.match.params.section) {
      activeSection = this.props.match.params.section;
    }

    return (
      <div>
        <div className="page-wrapper shop-profile">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3">
                <UserProfileCard
                  userInfo={this.state.userData.user}
                  ajunkahi="thisisi"
                />
              </div>
              <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9">
                <StatsTabs
                  userInfo={this.state.userData.user}
                  activeSection={activeSection}
                  history={this.props.history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Stats;
