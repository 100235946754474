import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import "moment/locale/fr";
import { Table, Modal, Button, Alert } from "react-bootstrap";
import NumberFormat from "react-number-format";
import OrderStatus from "../templates/OrderStatus";

class Sales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      userInfo: [],
      userData: [],
      orders: [],
      addressData: [],
      selectedOrder: [],
      page: 1,
      loading: true,
      showAddress: false,
      showStatusAction: false,
      showInactiveModal: false,
      laodingInactiveArticle: false,
      newStatus: "",
    };
  }

  componentDidMount() {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata) {
      this.setState(
        {
          userData: udata,
        },
        () => this.getOrders()
      );
    } else {
      this.setState({ isLoggedIn: false });
    }
  }

  getOrders = () => {
    let params = {
      page: this.state.page,
    };

    this.setState({ loading: true, orders: [] });

    axios
      .get(window.BASE_API_URL + "public/orders/get_sales", {
        params: {
          user_id: this.state.userData.user.user_id,
        },
        headers: {
          Authorization: this.state.userData.auth_key,
        },
      })
      .then((response) => {
        this.setState({
          orders: response.data,
          updateLoading: false,
          showStatusAction: false,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateStatus = () => {
    if (this.state.newStatus != this.state.selectedOrder.satus) {
      let orderId = this.state.selectedOrder.order_id;

      this.setState({ updateLoading: true });

      axios
        .post(
          window.BASE_API_URL + "public/orders/update_status",
          {
            order_id: orderId,
            new_status: this.state.newStatus,
          },
          {
            headers: {
              Authorization: this.state.userData.auth_key,
            },
          }
        )
        .then((response) => {
          this.getOrders();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  showAddress = (data) => {
    let addrData = JSON.parse(data);

    if (addrData) {
      this.setState({ addressData: addrData, showAddress: true });
    }
  };

  addressClose = () => {
    this.setState({ showAddress: false });
  };

  showStatusAction = (order) => {
    if (order) {
      this.setState({
        selectedOrder: order,
        newStatus: order.status,
        showStatusAction: true,
      });
    }
  };

  hideStatusAction = () => {
    this.setState({ showStatusAction: false });
  };

  setStatus = (e) => {
    this.setState({ newStatus: e.currentTarget.value });
  };

  showInactiveModal = (order) => {
    this.setState({ selectedOrder: order, showInactiveModal: true });
  };

  hideInactiveModal = () => {
    this.setState({ showInactiveModal: false });
  };

  inactiveArticle = () => {
    this.setState({ laodingInactiveArticle: true });

    let artDelUrl = window.BASE_API_URL + "public/article/inactivate";
    axios
      .post(
        artDelUrl,
        { article_code: this.state.selectedOrder.product_code },
        { headers: { Authorization: this.state.userData.auth_key } }
      )
      .then((response) => {
        this.setState(
          { showInactiveModal: false, laodingInactiveArticle: false },
          () => this.getOrders()
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (this.state.isLoggedIn === false) {
      return <Redirect to="/login" />;
    }

    let statusTrans = {
      pending_review: "En cours de validation",
      waiting_dispatch: "En attente d'expédition",
      dispatched: "Expédié",
      delivered: "Livré",
      confirmed: "Livraison confirmée",
      canceled: "Annulé",
    };

    const userData = JSON.parse(sessionStorage.getItem("userData"));
    const self = this;

    return (
      <div>
        <div className="page-wrapper shop-profile">
          <div className="container">
            <div className="row">
              {/* <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3 mob-hide">
                <UserProfileCard
                  userInfo={this.state.userData.user}
                  ajunkahi="thisisi"
                />
              </div> */}
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <section id="orders">
                  <h2 style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>
                    Mes ventes
                  </h2>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Utilisateur</th>
                        <th>Article</th>
                        <th>Prix</th>
                        <th>Quantité</th>
                        <th>Prix total</th>
                        <th>Date de commande</th>
                        <th>Adresse</th>
                        <th>Statut</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orders &&
                        this.state.orders.map((order, index) => (
                          <tr key={"ord-" + index}>
                            <td>{index + 1}</td>
                            <td>#{order.order_id}</td>
                            <td>
                              <Link
                                to={() => {
                                  let encodeUserID = new Buffer(
                                    order.buyer_id
                                  ).toString("base64");
                                  return "/profile/" + encodeUserID;
                                }}
                              >
                                {order.buyer_username}
                              </Link>
                            </td>
                            <td>
                              <Link to={"/article/" + order.product_code}>
                                {order.title}
                              </Link>
                            </td>
                            <td>
                              <NumberFormat
                                value={order.amount}
                                displayType={"text"}
                                thousandSeparator=" "
                                className="mainPrice"
                                renderText={(value) => (
                                  <span className="price-nowrap">
                                    {value} DA
                                  </span>
                                )}
                                decimalScale={0}
                              />
                            </td>
                            <td>{order.quantity}</td>
                            <td>
                              <NumberFormat
                                value={order.total_amount}
                                displayType={"text"}
                                thousandSeparator=" "
                                className="mainPrice"
                                renderText={(value) => (
                                  <span className="price-nowrap">
                                    {value} DA
                                  </span>
                                )}
                                decimalScale={0}
                              />
                            </td>
                            <td>
                              {Moment(
                                order.order_date,
                                "YYYY-MM-DD HH:II:SS"
                              ).format("D MMM YYYY")}
                            </td>
                            <td>
                              <a
                                onClick={() =>
                                  this.showAddress(order.order_details)
                                }
                                style={{ borderBottom: "1px dotted" }}
                              >
                                Afficher l'adresse
                              </a>
                            </td>
                            <td>
                              <OrderStatus
                                order={order}
                                showStatusAction={this.showStatusAction}
                              />
                              {order.status == "confirmed" &&
                                order.article_status == "active" && (
                                  <div>
                                    <Button
                                      variant="primary"
                                      size="sm"
                                      style={{ whiteSpace: "nowrap" }}
                                      onClick={() =>
                                        this.showInactiveModal(order)
                                      }
                                    >
                                      Retirer l’article de la vente
                                    </Button>
                                  </div>
                                )}
                            </td>
                          </tr>
                        ))}
                      {!this.state.orders && (
                        <tr>
                          <td colSpan="8">
                            Aucune commande n'a encore été passée
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showAddress}
          onHide={this.addressClose}
          className="delete-modal"
          centered
        >
          <Modal.Header closeButton>Adresse</Modal.Header>
          <Modal.Body>
            <Alert className="alert alert-info text-center">
              <p>
                {this.state.addressData.username}
                <br />
                {this.state.addressData.address_1}
                {/*  <br />
                {this.props.orderData.postcode} */}
                <br />
                {this.state.addressData.cityName}
              </p>
            </Alert>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showStatusAction}
          onHide={this.hideStatusAction}
          className="status-modal"
          centered
        >
          <Modal.Body>
            <div>&nbsp;</div>
            <h5 style={{ textAlign: "center" }}>
              Sélectionner un statut pour la commande
            </h5>
            <select className="form-control" onChange={this.setStatus}>
              {Object.keys(statusTrans).map(function (key) {
                if (key == "confirmed") return null;
                else
                  return (
                    <option
                      value={key}
                      key={"opt-" + key}
                      selected={self.state.newStatus == key}
                    >
                      {statusTrans[key]}
                    </option>
                  );
              })}
            </select>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={this.updateStatus}
              disabled={this.state.updateLoading}
            >
              Mettre à jour{" "}
              {this.state.updateLoading && (
                <i className="fa fa-spinner fa-spin"></i>
              )}
            </Button>
            <Button variant="secondary" onClick={this.hideStatusAction}>
              Annuler
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showInactiveModal}
          onHide={this.hideInactiveModal}
          className="confirm-modal"
          centered
        >
          <Modal.Body>
            <div>&nbsp;</div>
            <h5 style={{ textAlign: "center" }}>
              Voulez vous retirer ce produit de la vente?
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={this.inactiveArticle}
              disabled={this.state.laodingInactiveArticle}
            >
              Oui{" "}
              {this.state.laodingInactiveArticle && (
                <i className="fa fa-spinner fa-spin"></i>
              )}
            </Button>
            <Button variant="secondary" onClick={this.hideInactiveModal}>
              Annuler
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

Sales.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Sales);
