import React from "react";
import { Link, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { deleteUserData } from "../../actions/userActions";

function Logout(props) {
  if (props.userData.length > 0) {
    props.deleteUserData(props.userData[0].id);
  }

  if (sessionStorage.getItem("userData")) {
    sessionStorage.removeItem("userData");
  }

  return (
    <>
      <Redirect to="/login" />
    </>
  );
}

Logout.propTypes = {
  userData: PropTypes.array.isRequired,
  deleteUserData: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, { deleteUserData })(Logout);
