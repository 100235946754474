import React, { Component } from "react";

export class Termsandconditions extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container tnc-template">
        <div className="clearfix">&nbsp;</div>
        <div className="clearfix">&nbsp;</div>
        <h3 className="text-center">Termes &amp; Conditions</h3>
        <div className="devider"></div>
        <h5>Dernière modification : 10/06/2020</h5>
        <p>
          Le Site met en relation des Vendeurs et des Acheteurs souhaitant
          vendre des articles de mode et cosmétiques neufs ou de seconde main.
        </p>
        <p>
          Le Site agit comme intermédiaire entre l'Acheteur et le Vendeur et
          permet la mise d’annonces à travers son Site. Lorsqu'un Utilisateur
          achète un Article, celui-ci convient de l’ensemble des conditions de
          ventes avec le vendeur et ils effectuent la livraison et le paiement à
          leurs convenance sans l’implication des services du Site.
        </p>
        <p>
          Le Site joue seulement un rôle d'intermédiaire et ne saurait être tenu
          responsable des litiges ou toutes autres implications des opérations
          de ventes
        </p>
        <p>
          Les présentes Conditions Générales ont pour objet de définir les
          conditions d’utilisation du Site par les Utilisateurs.
        </p>
        <h5>Définitions</h5>
        <p>Site : désigne le site Web à l’adresse www.dzdressing.com</p>
        <p>
          Articles : Produits mis en vente par un Vendeur sur le Site dont la
          circulation n’est pas interdite en vertu du droit algérien applicable.
        </p>
        <p>
          Utilisateur : âgé de 19 ans révolus ou représentant légal d'une
          personne morale, qui utilise les services du site, qu’il soit inscrit
          ou non sur le Site.{" "}
        </p>
        <p>
          Acheteur : Utilisateur qui désire acheter ou qui a acheté un ou
          plusieurs Article(s)
        </p>
        <p>
          Vendeur : Utilisateur qui désire vendre ou qui a vendu un ou plusieurs
          Article(s).
        </p>
        <p>
          Dressing : L’ensemble des articles proposés à la vente sur DZdressing
          par un même utilisateur.
        </p>
        <p>
          Comptes de Site : Les comptes sur Facebook, Instagram, Google+,
          Twitter, Pinterest, Youtube et autres réseaux sociaux, où des
          informations sont fournies à propos du Site et des Services qu’il
          propose, en partageant les contenus publiés par les Utilisateurs.
        </p>
        <p>
          Données Personnelles : Les informations relatives à un personne, qui
          est ou peut être identifiée, directement ou indirectement, par
          référence à un numéro d’identification ou à un ou plusieurs critères
          qui lui est/sont propre(s)
        </p>
        <p>
          Adresse mail de contact : Adresse Mail indiqué dans la page de contact
          du site.
        </p>
        <h5>Article 1. Inscription sur le Site</h5>
        <p>
          Pour s’inscrire sur le Site, l’Utilisateur devra créer un compte à
          l’aide de son compte Facebook son un formulaire d’inscription. Pour
          compléter son profil sur le Site, L’Utilisateur fournira des
          informations complètes et exactes sur le Site.
        </p>
        <p>
          Après l’inscription, le Compte de l’Utilisateur est créé. Un
          Utilisateur peut modifier son Compte d’Utilisateur à tout moment. La
          suppression d’un compte se fait sur demande à envoyer à notre site en
          utilisant l’adresse mail de contact.
        </p>
        <p>
          L’Utilisateur s’engage, par ailleurs, à actualiser dans les meilleurs
          délais les informations fournies. Il est seul responsable des
          conséquences éventuelles de l’absence d’actualisation de ces
          informations.
        </p>
        <p>
          L’Utilisateur s’engage à ne communiquer son email et son mot de passe
          en aucun cas et supportera seul les conséquences de l’utilisation de
          son compte, même à son insu, par une personne utilisant son compte.
        </p>
        <p>
          L’Utilisateur s’engage à ne pas s’enregistrer sous un pseudonyme
          portant atteinte aux droits d’un tiers, ni à un droit de propriété
          intellectuelle, à une marque déposée ou à une dénomination sociale.{" "}
        </p>
        <p>
          L’Utilisateur s’engage à ne pas porter atteinte au respect de la vie
          privée des autres Utilisateurs et à ne pas leur envoyer de message,
          d’objet, de documentation notamment publicitaire, par quelque moyen
          que ce soit (notamment par voie postale, téléphonique ou
          électronique). En cas de non respect, le site se réserve le droit
          déclencher toutes les procédures qu’il jugera utiles.
        </p>
        <p>
          L’Utilisateur s’engage à ne pas employer sur le Site, des propos
          contraires aux bonnes mœurs, injurieux, diffamatoires ou pouvant
          porter atteinte à la réputation des autres Utilisateurs. En cas de
          non-respect de cette obligation, Le site se réserve le droit de
          suspendre le compte de l’Utilisateur sans notification préalable. En
          cas de récidive, Le site se réserve le droit de clôturer
          définitivement le compte de l’Utilisateur et de déclencher toutes les
          procédures qu’il jugera utiles.
        </p>
        <h5>Article 2 : Confidentialité</h5>
        <h6>2.1 Dispositions générales</h6>
        <p>
          Le site s’engage à recueillir et utiliser les Données Personnelles des
          Visiteurs ou des Utilisateurs dans le strict respect de la loi
          algérienne.
        </p>
        <p>
          En utilisant le Site, Utilisateurs et Visiteurs acceptent que leurs
          Données Personnelles soient traitées de la manière et aux fins
          indiquées dans les présentes. Les Utilisateurs sont présumés avoir lu
          et compris la présente Politique de Confidentialité, après l’avoir
          acceptée lors de leur inscription. La Politique de Confidentialité est
          disponible sur le Site à tout moment, et peut également être consulté
          à partir de ce même Site.{" "}
        </p>
        <p>
          Les Utilisateurs seront toujours informés par avance de la mise en
          œuvre des futurs changements et/ou compléments à la présente Politique
          de Confidentialité, et qui seront publiés sur le Site. La présente
          Politique de Confidentialité s’applique de plein droit aux Visiteurs
          qui utilisent le Site.
        </p>
        <p>
          Les Utilisateurs et/ou les Visiteurs doivent noter que le Site est
          susceptible de contenir des liens vers d’autres sites Web de
          personnes, de sociétés ou d’organismes, et que DZdressing n’est pas
          responsable du contenu de ces sites ni de leurs politiques de
          confidentialité. Ces tiers peuvent recueillir les Données Personnelles
          des Consommateurs et/ou des Visiteurs lorsqu’ils consultent leurs
          publicités et/ou cliquent dessus. En outre, avant de soumettre des
          informations les concernant, les Utilisateurs et/ou les Visiteurs sont
          invités à examiner les règlements, la politique de confidentialité et
          les autres documents des sites Web en question.
        </p>
        <h6>2.2 Recueil et stockage des données personnelles</h6>
        <p>
          Le site recueille, stocke et utilise les Données Personnelles
          suivantes des Utilisateurs :
        </p>
        <p>
          - Au moment de l’inscription ou de la modification du compte: Nom
          d’utilisateur, numéro de téléphone, ville et adresse électronique.
        </p>
        <p>- Au moment de l’utilisation des Services : Article référencé.</p>
        <p>
          Le site recueille, stocke et utilise les Données Personnelles des
          Visiteurs en se basant sur des cookies, comme indiqué ci-dessous.
        </p>
        <h6>2.3 Objectifs de l’utilisation des données personnelles</h6>
        <p>Le site utilise les Données Personnelles aux fins suivantes :</p>
        <p>
          Pour fournir les Services à un Utilisateur et/ou un Visiteur. Afin
          d’utiliser certains Services proposés sur le Site, un Utilisateur doit
          fournir des Données Personnelles indispensables pour pouvoir utiliser
          des Services spécifiques ;
        </p>
        <p>A des fins d’authentification ;</p>
        <p>
          Pour fournir les Services de manière efficace. Le site veille à ce que
          l’utilisation du Site et de ses Services soit efficace.{" "}
        </p>
        <p>
          Pour assurer l’amélioration et le développement continus des Services,
          et pour fournir à un Utilisateur et/ou un Visiteur les offres de
          Service les mieux adaptées à ses besoins ;
        </p>
        <p>
          Pour respecter les règlements (lesquels peuvent inclure des
          obligations légales de conservation des données) ;
        </p>
        <p>
          Un Utilisateur et/ou un Visiteur peut s’opposer à tout moment et pour
          des raisons légitimes au traitement des données décrit ci-dessus en
          envoyant un courriel à l’adresse mail de contact. En fonction de la
          nature du traitement faisant l’objet de l’opposition, l’Utilisateur ou
          le Visiteur peut se voir de ce fait empêché d’utiliser tout ou partie
          des Services.
        </p>
        <h6>2.4 Protection des données personnelles</h6>
        <p>
          Le site garantit que les Données Personnelles fournies par un
          Utilisateur et/ou un Visiteur sont protégées contre toute activité
          illégale, telle que l’utilisation abusive des Données Personnelles, la
          modification ou la destruction des Données Personnelles, l’usurpation
          d’identité, la fraude. La divulgation de l'identité d'un Utilisateur à
          un autre n'est dévoilée qu'en cas de procédure entre ces derniers.
        </p>
        <p>
          Les Utilisateurs et les Visiteurs s’engagent à ne pas divulguer leurs
          Données Personnelles, ni les Données Personnelles d’un tiers si
          celles-ci ont été mises à leur disposition, et doivent informer
          l’administration du site immédiatement en cas de violations.
        </p>
        <h6>2.5 Cookies</h6>
        <p>
          Le Site utilise des cookies pour distinguer les Utilisateurs/Visiteurs
          des autres utilisateurs du Site afin d’aider le site à améliorer leur
          visite lorsqu’ils naviguent sur le Site.
        </p>
        <h5>Article 3. Vendre sur le site </h5>
        <h6>3.1 Articles mis en vente </h6>
        <p>
          Le Vendeur s’engage à ce que tout Article mis en vente corresponde à
          un bien matériel disponible immédiatement dont il dispose de la pleine
          et entière propriété et capacité de vente. Le Vendeur s’engage à ce
          que les Articles ne comportent pas de défauts : tache visible, article
          troué ou déchiré ect.
        </p>
        <p>
          Le Vendeur s’engage à ce que tout article de beauté (maquillage et
          soins) mis en vente corresponde à un produit non ouvert dans son
          emballage d’origine.
        </p>
        <p>
          Le Vendeur s'engage à fournir une photo réelle de son Article mis en
          vente.{" "}
        </p>
        <p>
          Le Vendeur s’engage à décrire l’Article sur le Site. La description de
          l’Article doit correspondre à ses caractéristiques effectives. Le
          Vendeur est le seul responsable de la description des articles.{" "}
        </p>
        <p>
          Le site se réserve le droit de solliciter au Vendeur qu’il modifie
          et/ou supprime son Annonce et/ou de le faire directement, notamment en
          cas d’erreur concernant la catégorie de l’article, ou en cas de
          renseignement des coordonnées dans l’annonce.
        </p>
        <p>
          Le Vendeur détermine le Prix de vente de l’Article. Le prix d’achat en
          boutique n’est pas obligatoire. Si le Vendeur ne connait pas le prix
          d’achat, il peut se contenter de mettre une estimation.
        </p>
        <p>
          Le site s’engage à diffuser l’Annonce du Vendeur sur le Site sans
          limitation de durée, sous réserve du respect par le Vendeur des
          présentes Conditions Générales.{" "}
        </p>
        <p>
          Le Vendeur accepte que son Article soit diffusé sur les sites et les
          supports partenaires du site.{" "}
        </p>
        <h6>3.2 Déroulement d’une transaction (Vente / Achat/ échange) </h6>
        <p>
          Le déroulement de la transaction est à définir entre le vendeur et
          l’acheteur. Les accords et les modalités de vente/achat sont conclus
          directement entre l’Acheteur et le Vendeur. Le site n’a aucune
          responsabilité quant au dénouement de l’opération.
        </p>
        <h6>3.3 Signaler un article ou un dressing </h6>
        <p>
          Si vous remarquez un contenu désagréable ou ne respectant pas les
          règles imposées par le site, vous avez la possibilité de le signaler
          en cliquant sur le bouton ‘ signaler ‘. Vous pouvez également nous
          prévenir en utilisant l’adresse mail de contact.
        </p>
        <p></p>
        <h5>Article 4. Responsabilités</h5>
        <p>
          Le site ne peut être tenu pour responsable ni du contenu des Annonces,
          ni des actions (ou absence d’action) des Utilisateurs, ni des Articles
          mis en vente.
        </p>
        <p>
          Le site ne peut être tenu responsable du caractère diffamatoire,
          injurieux ou contraire aux bonnes mœurs des messages adressés par un
          Utilisateur.
        </p>
        <p>
          Le site n’intervient pas dans la transaction entre Acheteurs et
          Vendeurs. En conséquence, Le site n’exerce aucun contrôle sur la
          qualité des Articles répertoriés, l’exactitude du contenu des annonces
          des utilisateurs.
        </p>
        <p>
          Le site se réserve le droit de suspendre, sans préavis ni indemnité et
          sans engager sa responsabilité, l’accès au Site, temporairement ou
          définitivement. Il ne garantit pas que le Site sera accessible sans
          interruption. Il pourra interrompre l’accès au Site pour des raisons
          notamment de maintenance et en cas d’urgence.
        </p>
        <p>
          Le site n'est pas responsable de l’utilisation frauduleuse par un
          tiers et des conséquences éventuelles de l’identifiant et/ou du mot de
          passe de l’Utilisateur.
        </p>
        <p>
          Le site n'est pas responsable de l’utilisation frauduleuse par un
          tiers et des conséquences éventuelles de l’identifiant et/ou du mot de
          passe de l’Utilisateur.
        </p>
        <h5>Article 5. Dispositions diverses</h5>
        <p>
          Les présentes Conditions Générales sont valables à compter du 10 Juin
          2020.
        </p>
        <p>
          Dans l’hypothèse où l’une ou plusieurs des stipulations des présentes
          Conditions Générales serai(en)t écartée(s) par une disposition
          législative ou réglementaire ou par une décision de justice, toutes
          les autres dispositions demeureraient applicables.
        </p>
        <p>
          En accédant sur le Site, l’Utilisateur reconnaît avoir pris
          connaissance, compris et accepté sans réserve l’intégralité des
          présentes Conditions Générales qui régissent avec force obligatoire
          l’ensemble des relations entre Le site, les visiteurs et les
          utilisateurs. Cette acceptation prend effet à compter de la date
          d’inscription sur le Site de l’Utilisateur et vaut pour chaque
          opération et Annonce. Les présentes Conditions Générales prévalent sur
          tout document contractuel ou non contractuel propre au Vendeur ou
          propre à l’Acheteur.
        </p>
        <p>
          Le site a la possibilité de modifier les présentes Conditions
          Générales en fonction de l’évolution de son offre et du marché.
          L’Utilisateur s’engage donc à consulter régulièrement les Conditions
          Générales du Site pour prendre connaissance des modifications y ayant
          été apportées. Tout refus d’acceptation des Conditions modifiées devra
          être notifié en utilisant l’adresse mail de contact.
        </p>
        <p>
          Le présent contrat est conclu entre Le site et l’Utilisateur pour une
          durée indéterminée. Il est résiliable par simple notification à tout
          moment et sans motif particulier, par l’une ou l’autre des parties en
          présence.
        </p>
        <p>
          Le Site, son contenu, ses articles, ses textes illustrations, ses
          photographies et images sont la propriété de DZdressing. Il est
          interdit de reproduire, représenter et/ou exploiter tout ou partie du
          Site, de son contenu, de son catalogue, de ses textes illustrations,
          de ses photographies et images sans autorisation préalable de
          DZdressing.
        </p>
        <p>
          Pour toutes questions concernant ces conditions générales
          d'utilisation ou le Site, contactez-nous en utilisant l’adresse mail
          de contact{" "}
        </p>
        <div className="devider"></div>
        <div className="clearfix">&nbsp;</div>
      </div>
    );
  }
}

export default Termsandconditions;
