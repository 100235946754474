import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Flashmessage from "./Flashmessage";
import { deleteFlashMessage } from "../../actions/flashMessages";

class FlashMessageList extends Component {
  render() {
    const messages = this.props.messages.map((message) => (
      <Flashmessage
        key={message.id}
        message={message}
        deleteFlashMessage={this.props.deleteFlashMessage}
      />
    ));
    return <div>{messages}</div>;
  }
}

FlashMessageList.propTypes = {
  messsages: PropTypes.array,
  deleteFlashMessage: PropTypes.func.isRequired,
};

const messageToProps = (state) => {
  return {
    messages: state.flashMessages,
  };
};

export default connect(messageToProps, { deleteFlashMessage })(
  FlashMessageList
);
