import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Header from "./components/templates/Header";
import Footer from "./components/templates/Footer";
import PageRenderer from "./components/PageRenderer";
import Editarticle from "./components/pages/Editarticle";
import Article from "./components/pages/Article";
import Profile from "./components/pages/Profile";
import Search from "./components/pages/Search";
import Mostfollowedusers from "./components/pages/Mostfollowedusers";
import Vendors from "./components/pages/Vendors";
import FlashMessageList from "./components/templates/FlashMessageList";
import Modal from "react-modal";

Modal.setAppElement("#root");
window.BASE_API_URL = "https://www.dzdressing.com/api/";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <FlashMessageList />
        <Switch>
          <Route path="/editarticle/:articlecode" component={Editarticle} />
          <Route path="/article/:articlecode" component={Article} />
          <Route path="/profile/:profile_id" component={Profile} />
          <Route path="/search" component={Search} />
          <Route path="/mostfollowedusers" component={Mostfollowedusers} />
          <Route
            path="/mostlikedarticles"
            render={(props) => <Search mostLikedTab={true} {...props} />}
          />
          <Route path="/vendors" component={Vendors} />
          <Route path="/:page" component={PageRenderer} />
          <Route path="/" render={() => <Redirect to={"/home"} />} />
          <Route component={() => 404} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
