import React, { Component } from "react";

class OrderStatus extends Component {
  render() {
    const order = this.props.order;
    const showStatusAction = this.props.showStatusAction;
    const statusTrans = {
      pending_review: "En cours de validation",
      waiting_dispatch: "En attente d'expédition",
      dispatched: "Expédié",
      delivered: "Livré",
      confirmed: "Livraison confirmée",
      canceled: "Annulé",
    };

    if (order) {
      return (
        <>
          {order.status != "delivered" &&
          order.status != "confirmed" &&
          order.status != "canceled" ? (
            <a
              onClick={() => showStatusAction(order)}
              style={{ borderBottom: "1px dotted" }}
            >
              {statusTrans[order.status]}
            </a>
          ) : (
            <span className="status-block">{statusTrans[order.status]}</span>
          )}
        </>
      );
    } else return <div></div>;
  }
}

export default OrderStatus;
