import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Alert } from "react-bootstrap";
import NumberFormat from "react-number-format";

export class PurchaseStep3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userData: [],
    };
  }

  componentDidMount() {
    let udata = [];

    if (this.props.userData) {
      udata = this.props.userData;
      this.setState({ userData: udata });
    }

    this.isValidated = this.isValidated.bind(this);
  }

  componentWillMount() {
    this.isValidated = this.isValidated.bind(this);
  }

  isValidated() {
    return false;
  }

  render() {
    return (
      <div className="row">
        <div className="col-sm-12 offset-md-1 offset-lg-1 col-md-10 col-lg-10">
          <Alert className="alert alert-success text-center">
            <h4>Mabrouk ! Commande confirmée !</h4>
            <p>
              Le montant à payer est de :{" "}
              <strong>
                <NumberFormat
                  value={this.props.article && this.props.article.sale_price}
                  displayType={"text"}
                  thousandSeparator=" "
                  className="mainPrice"
                  renderText={(value) => <>{value} DA</>}
                />
              </strong>
            </p>

            <p>L'adresse enregistrée pour la livraison est la suivante:</p>
            <p>
              {this.props.orderData.username}
              <br />
              {this.props.orderData.address_1}
              {/*  <br />
              {this.props.orderData.postcode} */}
              <br />
              {this.props.orderData.cityName}
              <br />
              Numéro de téléphone: {this.props.orderData.phone_number}
            </p>
            <p>
              <small>
                Vous pouvez annuler votre commande dans <b>Mes achats</b>
              </small>
            </p>
          </Alert>
          <div>
            <Button
              className="btn btn-secondary"
              onClick={() => this.props.purchaseModalClosed()}
            >
              Fermez
            </Button>
            <Link
              to="/home"
              className="btn btn-primary float-right"
              onClick={() => {
                this.props.purchaseModalClosed();
              }}
            >
              Continuer vos achats
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default PurchaseStep3;
