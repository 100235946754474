import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Moment from "moment";
import "moment/locale/fr";
import { Table, Modal, Button, Alert } from "react-bootstrap";
import NumberFormat from "react-number-format";
import StarRatingComponent from "react-star-rating-component";

class Orders extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      userInfo: [],
      userData: [],
      orders: [],
      page: 1,
      loading: true,
      showCancelOrder: false,
      laodingCancelOrder: false,
      showConfirmDelivery: false,
      laodingConfirmDelivery: false,
      showRatingForm: false,
      commentPosting: false,
      selectedOrder: [],
      commentValid: false,
      commentText: "",
      ratings: 0,
    };
  }

  componentDidMount() {
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata) {
      this.setState(
        {
          userData: udata,
        },
        () => this.getOrders()
      );
    } else {
      this.setState({ isLoggedIn: false });
    }
  }

  getOrders = () => {
    let params = {
      page: this.state.page,
    };

    this.setState({ loading: true });

    axios
      .get(window.BASE_API_URL + "public/orders/get_orders", {
        params: {
          user_id: this.state.userData.user.user_id,
        },
        headers: {
          Authorization: this.state.userData.auth_key,
        },
      })
      .then((response) => {
        this.setState({
          orders: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showCancelOrder = (order) => {
    this.setState({ selectedOrder: order, showCancelOrder: true });
  };

  hideCancelOrder = () => {
    this.setState({ showCancelOrder: false });
  };

  showConfirmDelivery = (order) => {
    this.setState({ selectedOrder: order, showConfirmDelivery: true });
  };

  hideConfirmDelivery = () => {
    this.setState({ showConfirmDelivery: false });
  };

  showRatingForm = (order) => {
    this.setState({
      selectedOrder: order,
      showRatingForm: true,
      commentAdded: false,
      commentText: "",
      commentValid: false,
      ratings: 0,
    });
  };

  hideRatingForm = (order) => {
    this.setState({ showRatingForm: false });
  };

  cancelOrder = () => {
    this.setState({ laodingCancelOrder: true });
    let orderId = this.state.selectedOrder.order_id;
    axios
      .post(
        window.BASE_API_URL + "public/orders/update_status",
        {
          order_id: orderId,
          new_status: "canceled",
        },
        {
          headers: {
            Authorization: this.state.userData.auth_key,
          },
        }
      )
      .then((response) => {
        this.setState(
          {
            laodingCancelOrder: false,
            orders: [],
          },
          () => {
            this.getOrders();
            this.hideCancelOrder();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  confirmDelivery = () => {
    this.setState({ laodingCancelOrder: true });
    let orderId = this.state.selectedOrder.order_id;
    axios
      .post(
        window.BASE_API_URL + "public/orders/update_status",
        {
          order_id: orderId,
          new_status: "confirmed",
        },
        {
          headers: {
            Authorization: this.state.userData.auth_key,
          },
        }
      )
      .then((response) => {
        this.setState(
          {
            laodingConfirmDelivery: false,
            orders: [],
          },
          () => {
            this.getOrders();
            this.hideConfirmDelivery();
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onStarClick = (nextValue) => {
    let isValid = false;
    if (nextValue > 0) {
      isValid = true;
    }

    this.setState({ ratings: nextValue, commentValid: isValid });
  };

  setCommentText = (e) => {
    this.setState({ commentText: e.currentTarget.value });
  };

  addComment = () => {
    if (this.state.ratings > 0) {
      this.setState({ commentPosting: true });

      let udata = [];

      if (this.props.userData.length > 0) {
        udata = this.props.userData[0].userData;
        console.log(udata);
      }

      axios
        .post(
          window.BASE_API_URL + "public/users/add_rating",
          {
            rating: this.state.ratings,
            comment: this.state.commentText,
            user_id: this.state.selectedOrder.seller_id,
            order_id: this.state.selectedOrder.order_id,
          },
          {
            headers: {
              Authorization: udata.auth_key,
            },
          }
        )
        .then((response) => {
          this.setState(
            {
              commentAdded: true,
              commentPosting: false,
            },
            () => {
              this.getOrders();
            }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    if (this.state.isLoggedIn === false) {
      return <Redirect to="/login" />;
    }

    const userData = this.state.userData;
    let statusTrans = {
      pending_review: "En cours de validation",
      waiting_dispatch: "En attente d'expédition",
      dispatched: "Expédié",
      delivered: "Livré",
      confirmed: "Livraison confirmée",
      canceled: "Annulé",
    };

    return (
      <div>
        <div className="page-wrapper shop-profile">
          <div className="container">
            <div className="row">
              {/* <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3 mob-hide">
                <UserProfileCard
                  userInfo={this.state.userData.user}
                  ajunkahi="thisisi"
                />
              </div> */}
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <section id="orders">
                  <h2 style={{ borderBottom: "1px solid rgba(0,0,0,0.2)" }}>
                    Mes achats
                  </h2>
                  <Table striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>ID</th>
                        <th>Article</th>
                        <th>Vendeur</th>
                        <th>Prix</th>
                        <th>Quantité</th>
                        <th>Prix total</th>
                        <th>Date de commande</th>
                        <th>Statut</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.orders &&
                        this.state.orders.map((order, index) => (
                          <tr key={"ord-" + index}>
                            <td>{index + 1}</td>
                            <td>#{order.order_id}</td>
                            <td>
                              <Link to={"/article/" + order.product_code}>
                                {order.title}
                              </Link>
                            </td>
                            <td>
                              <Link
                                to={() => {
                                  let encodeUserID = new Buffer(
                                    order.seller_id
                                  ).toString("base64");
                                  return "/profile/" + encodeUserID;
                                }}
                              >
                                {order.seller_username}
                              </Link>
                            </td>
                            <td>
                              <NumberFormat
                                value={order.amount}
                                displayType={"text"}
                                thousandSeparator=" "
                                className="mainPrice"
                                renderText={(value) => (
                                  <span className="price-nowrap">
                                    {value} DA
                                  </span>
                                )}
                                decimalScale={0}
                              />
                            </td>
                            <td>{order.quantity}</td>
                            <td>
                              <NumberFormat
                                value={order.total_amount}
                                displayType={"text"}
                                thousandSeparator=" "
                                className="mainPrice"
                                renderText={(value) => (
                                  <span className="price-nowrap">
                                    {value} DA
                                  </span>
                                )}
                                decimalScale={0}
                              />
                            </td>
                            <td>
                              {Moment(
                                order.order_date,
                                "YYYY-MM-DD HH:II:SS"
                              ).format("D MMM YYYY")}
                            </td>
                            <td>
                              <span className="status-block">
                                {statusTrans[order.status]}
                              </span>
                              {order.status != "canceled" &&
                              order.status != "delivered" &&
                              order.status != "confirmed" ? (
                                <button
                                  onClick={() => this.showCancelOrder(order)}
                                  className="btn btn-sm btn-primary status-btn"
                                >
                                  {" "}
                                  Annuler la commande
                                </button>
                              ) : null}
                              {order.status == "delivered" &&
                              order.status != "confirmed" ? (
                                <button
                                  onClick={() =>
                                    this.showConfirmDelivery(order)
                                  }
                                  className="btn btn-sm btn-primary status-btn"
                                >
                                  {" "}
                                  Confirmer la livraison
                                </button>
                              ) : null}
                              {order.status == "confirmed" &&
                                order.rating_id == null && (
                                  <button
                                    onClick={() => this.showRatingForm(order)}
                                    className="btn btn-sm btn-primary status-btn"
                                  >
                                    Donnez votre avis
                                  </button>
                                )}
                            </td>
                          </tr>
                        ))}
                      {!this.state.orders && (
                        <tr>
                          <td colSpan="8">
                            Aucune commande n'a encore été passée
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <div className="text-center load-more-btn menu-list">
                    {this.state.loading && (
                      <div>
                        Chargement <i className="fa fa-spinner fa-spin"></i>
                      </div>
                    )}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.showCancelOrder}
          onHide={this.hideCancelOrder}
          className="cancel-modal"
          centered
        >
          <Modal.Body>
            <div>&nbsp;</div>
            <h5 style={{ textAlign: "center" }}>
              Voulez vous annuler cette commande?
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={this.cancelOrder}
              disabled={this.state.laodingCancelOrder}
            >
              Oui{" "}
              {this.state.laodingCancelOrder && (
                <i className="fa fa-spinner fa-spin"></i>
              )}
            </Button>
            <Button variant="secondary" onClick={this.hideCancelOrder}>
              Annuler
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showConfirmDelivery}
          onHide={this.hideConfirmDelivery}
          className="confirm-modal"
          centered
        >
          <Modal.Body>
            <div>&nbsp;</div>
            <h5 style={{ textAlign: "center" }}>
              Avez-vous reçu le colis du vendeur?
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={this.confirmDelivery}
              disabled={this.state.laodingConfirmDelivery}
            >
              Oui{" "}
              {this.state.laodingConfirmDelivery && (
                <i className="fa fa-spinner fa-spin"></i>
              )}
            </Button>
            <Button variant="secondary" onClick={this.hideConfirmDelivery}>
              Annuler
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={this.state.showRatingForm}
          onHide={this.hideRatingForm}
          className="ratings-modal"
          size="lg"
          centered
        >
          <Modal.Header closeButton>
            Laisser un avis sur le vendeur{" "}
            {this.state.selectedOrder.seller_username}
          </Modal.Header>
          <Modal.Body>
            <div className="feedback-section padd">
              <div className="rating-feedback">
                {this.state.commentAdded ? (
                  <div>
                    <Alert variant="success">
                      Merci de nous avoir donné votre précieux avis !
                    </Alert>
                    <div className="rating-div">
                      <label></label>
                      <div className="rating-block">
                        <button
                          className="btn btn-primary"
                          onClick={this.hideRatingForm}
                        >
                          Fermer
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="rating-divwrap-main">
                    <div className="rating-div">
                      <label>Note:</label>
                      <div className="rating-block">
                        <StarRatingComponent
                          name="rate2"
                          renderStarIcon={() => <i className="fa fa-star"></i>}
                          renderStarIconHalf={() => (
                            <i className="fa fa-star"></i>
                          )}
                          starCount={5}
                          value={this.state.ratings}
                          emptyStarColor="#cfcfcf"
                          onStarClick={this.onStarClick}
                        />
                      </div>
                    </div>
                    <div className="rating-div">
                      <label>Commentaire:</label>
                      <div className="rating-block">
                        <textarea
                          className="form-control comment-box"
                          onChange={this.setCommentText}
                          maxLength="260"
                          value={this.state.commentText}
                        ></textarea>
                      </div>
                    </div>
                    <div className="rating-div">
                      <label></label>
                      <div className="rating-block">
                        <button
                          className="btn btn-primary"
                          onClick={this.addComment}
                          disabled={!this.state.commentValid}
                        >
                          Enregistrer
                        </button>
                        {this.state.commentPosting && (
                          <i className="fa fa-spinner fa-spin"></i>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

Orders.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Orders);
