import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import { addFlashMessage } from "../../actions/flashMessages";
import { UserListBox } from "./UserListBox";
import StarRatingComponent from "react-star-rating-component";

class UserProfileCard extends Component {
  constructor(props) {
    super(props);

    this.state = this.initialState;
  }

  get initialState() {
    return {
      isLoggedIn: true,
      buttonLoading: false,
      following: false,
      totalFollowers: 0,
      loggedInUser: this.props.userData.length
        ? this.props.userData[0].userData.user
        : [],
      userData: [],
      followings: [],
      followingsEnd: false,
      followingsPage: 1,
      followingsLoading: false,
      followers: [],
      followersEnd: false,
      followersPage: 1,
      followersLoading: false,
      followersVisible: false,
      followingsVisible: false,
    };
  }

  componentDidMount() {
    if (this.props.userData.length > 0) {
      this.setState({
        userData: this.props.userData[0].userData,
        following: this.props.userInfo ? this.props.userInfo.following : false,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState(this.initialState);
    if (this.props.userData.length > 0) {
      this.setState({
        userData: this.props.userData[0].userData,
        following: newProps.userInfo ? newProps.userInfo.following : false,
      });
    }
  }

  follow = (followTo = 0) => {
    if (this.state.buttonLoading == true) return;

    let udata = [];
    let followUrl = window.BASE_API_URL + "public/users/follow";

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (followTo == 0) {
      followTo = this.props.userInfo.user_id;
    }

    if (udata.user) {
      this.setState({ buttonLoading: true });

      let data = {
        user_id: this.props.userInfo.user_id,
        follower_id: this.state.loggedInUser.user_id,
      };

      axios
        .post(followUrl, data, {
          headers: {
            Authorization: udata.auth_key,
          },
        })
        .then((response) => {
          this.setState({ goToProfile: true, buttonLoading: false });

          if (response.data.status == "ok" && response.data.followers) {
            this.setState({
              totalFollowers: response.data.followers.total,
              following: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  unfollow = (followedId = 0) => {
    if (this.state.buttonLoading == true) return;

    let udata = [];
    let followUrl = window.BASE_API_URL + "public/users/unfollow";

    if (followedId == 0 && this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
      followedId = this.props.userInfo.user_id;
    }

    if (followedId) {
      this.setState({ buttonLoading: true });

      let data = {
        user_id: this.props.userInfo.user_id,
        follower_id: this.state.loggedInUser.user_id,
      };

      axios
        .post(followUrl, data, {
          headers: {
            Authorization: udata.auth_key,
          },
        })
        .then((response) => {
          this.setState({ goToProfile: true, buttonLoading: false });

          if (response.data.status == "ok" && response.data.followers) {
            this.setState({
              totalFollowers: response.data.followers.total,
              following: false,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  getFollowers = () => {
    let params = {};

    if (this.state.followersEnd) return;

    this.setState({ followersLoading: true });

    params.page = this.state.followersPage;
    params.items = 10;
    params.profile_id = this.props.userInfo.user_id;
    params.user_id = this.state.loggedInUser.user_id
      ? this.state.loggedInUser.user_id
      : 0;

    axios
      .get(window.BASE_API_URL + "public/users/get_followers", {
        params: params,
      })
      .then((response) => {
        if (this.state.followersPage > 1) {
          if (
            response.data.followers != undefined &&
            response.data.followers.length > 0
          ) {
            this.setState((prevState) => ({
              followers: prevState.followers.concat(response.data.followers),
              followersLoading: false,
            }));
          } else {
            this.setState({
              followersLoading: false,
              followersEnd: true,
            });
          }
        } else {
          this.setState({
            followers: response.data.followers,
            followersLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showFollowers = () => {
    this.setState({ followersVisible: true }, () => {
      document.getElementById("modal-body").addEventListener("scroll", () => {
        this.trackScrollingFollowers();
      });
    });
    this.getFollowers();
  };

  hideFollwers = () => {
    document
      .getElementById("modal-body")
      .removeEventListener("scroll", this.trackScrollingFollowers);
    this.setState({ followersVisible: false });
  };

  getFollowings = () => {
    let params = {};

    if (this.state.followingsEnd) return;

    this.setState({ followingsLoading: true });

    params.page = this.state.followingsPage;
    params.items = 10;
    params.profile_id = this.props.userInfo.user_id;
    params.user_id = this.state.loggedInUser.user_id
      ? this.state.loggedInUser.user_id
      : 0;

    axios
      .get(window.BASE_API_URL + "public/users/get_followings", {
        params: params,
      })
      .then((response) => {
        if (this.state.followingsPage > 1) {
          if (
            response.data.followings != undefined &&
            response.data.followings.length > 0
          ) {
            this.setState((prevState) => ({
              followings: prevState.followings.concat(response.data.followings),
              followingsLoading: false,
            }));
          } else {
            this.setState({
              followingsLoading: false,
              followingsEnd: true,
            });
          }
        } else {
          this.setState({
            followings: response.data.followings,
            followingsLoading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showFollowings = () => {
    this.setState({ followingsVisible: true }, () => {
      document.getElementById("modal-body").addEventListener("scroll", () => {
        this.trackScrollingFollowings();
      });
    });
    this.getFollowings();
  };

  hideFollowings = () => {
    this.setState({ followingsVisible: false });
    document
      .getElementById("modal-body")
      .removeEventListener("scroll", this.trackScrollingFollowers);
  };

  isBottom(el) {
    let modBody = document.getElementById("modal-body");
    return (
      el.getBoundingClientRect().bottom <=
      modBody.offsetHeight + modBody.scrollTop
    );
  }

  trackScrollingFollowers = () => {
    const wrappedElement = document.getElementById("followers-body");

    if (
      wrappedElement &&
      this.isBottom(wrappedElement) &&
      this.state.followersEnd === false &&
      this.state.followersLoading === false
    ) {
      this.setState({ followersPage: this.state.followersPage + 1 }, () => {
        this.getFollowers();
      });
    }
  };

  trackScrollingFollowings = () => {
    const wrappedElement = document.getElementById("followings-body");
    if (
      wrappedElement &&
      this.isBottom(wrappedElement) &&
      this.state.followingsEnd === false &&
      this.state.followingsLoading === false
    ) {
      this.setState({ followingsPage: this.state.followingsPage + 1 }, () => {
        this.getFollowings();
      });
    }
  };

  render() {
    const userData = this.props.userInfo;

    if (userData) {
      let encodeUserID = new Buffer(userData.user_id).toString("base64");
      let followBtn = null;

      if (
        !this.state.loggedInUser ||
        (this.state.loggedInUser &&
          this.state.loggedInUser.user_id != userData.user_id)
      ) {
        if (this.state.following === true) {
          followBtn = (
            <button
              type="button"
              className={
                "footer-hm-chef-bx" +
                (this.state.buttonLoading ? " loading" : "")
              }
              onClick={this.unfollow}
            >
              <i className="fas fa-check"></i> Abonné
            </button>
          );
        } else {
          followBtn = (
            <button
              type="button"
              className={
                "footer-hm-chef-bx" +
                (this.state.buttonLoading ? " loading" : "")
              }
              onClick={this.follow}
            >
              S'abonner
            </button>
          );
        }
      }

      return (
        <>
          {/* userData.username && (
            <h3 className="profile-name-mob">
              {userData.username.toUpperCase()}{" "}
            </h3>
          ) */}
          <div className="chef-details">
            <div className="chef-info prof">
              <div
                className="chef-img"
                style={{
                  backgroundImage:
                    "url(" +
                    (userData.profile_photo
                      ? userData.profile_photo
                      : require("../../assets/images/defoul-photo.jpg")) +
                    ")",
                }}
              >
                {/* <img
                  src={
                    userData.profile_photo
                      ? userData.profile_photo
                      : require("../../assets/images/defoul-photo.jpg")
                  }
                  className="img-responsive"
                  alt=""
                /> */}
              </div>
              <h4>
                <Link to={"/profile/" + encodeUserID}>
                  {userData.username && (
                    <span>{userData.username.toUpperCase()} </span>
                  )}
                </Link>
              </h4>
              <div className="reviewrating-min">
                <div className="tabl-cel">
                  <StarRatingComponent
                    name="rate2"
                    renderStarIcon={() => <i className="fa fa-star"></i>}
                    renderStarIconHalf={() => <i className="fa fa-star"></i>}
                    starCount={5}
                    value={userData.average_ratings}
                    emptyStarColor="#cfcfcf"
                    editing={false}
                  />
                </div>
                <div className="text-str tabl-cel">
                  ({userData.total_ratings} avis)
                </div>
              </div>
              {/* <div className="location">
                {userData.articles_count} Articles en vente
              </div> 
              {!this.state.loggedInUser ||
              (this.state.loggedInUser &&
                this.state.loggedInUser.user_id != userData.user_id) ? (
                <button
                  type="button"
                  className="footer-hm-chef-bx"
                  onClick={this.follow}
                >
                  S'abonner
                </button>
              ) : null} */}
              {followBtn}
            </div>
            <div className="followers-block">
              <ul>
                <li>
                  <span>Articles</span>
                  <b>{userData.articles_count}</b>
                </li>
                <li className="line"></li>
                <li onClick={this.showFollowers}>
                  <span>Abonnés</span>
                  <b>
                    {this.state.totalFollowers
                      ? this.state.totalFollowers
                      : userData.follower_count}
                  </b>
                </li>
                <li className="line"></li>
                <li onClick={this.showFollowings}>
                  <span>Suivis</span>
                  <b>{userData.following_count}</b>
                </li>
              </ul>
            </div>
          </div>
          <Modal
            show={this.state.followersVisible}
            onHide={this.hideFollwers}
            className="followers-modal"
            id="followers-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Abonnés</Modal.Title>
            </Modal.Header>
            <Modal.Body id="modal-body">
              <div id="followers-body">
                {this.state.followers &&
                  this.state.followers.map((profile, index) => (
                    <div className="row" key={"fl-" + index}>
                      <UserListBox
                        userProfile={profile}
                        userData={this.state.userData}
                      />
                    </div>
                  ))}

                {this.state.followersLoading && (
                  <div className="text-center">
                    Chargement <i className="fa fa-spinner fa-spin"></i>
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={this.state.followingsVisible}
            onHide={this.hideFollowings}
            className="followings-modal"
            id="followings-modal"
            size="lg"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Suivies</Modal.Title>
            </Modal.Header>
            <Modal.Body id="modal-body">
              <div id="followings-body">
                {this.state.followings &&
                  this.state.followings.map((profile, index) => (
                    <div className="row" key={"fl-" + index}>
                      <UserListBox
                        userProfile={profile}
                        userData={this.state.userData}
                      />
                    </div>
                  ))}

                {this.state.followingsLoading && (
                  <div className="text-center">
                    Chargement <i className="fa fa-spinner fa-spin"></i>
                  </div>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </>
      );
    } else {
      return <div></div>;
    }
  }
}

UserProfileCard.propTypes = {
  userData: PropTypes.array.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, { addFlashMessage })(UserProfileCard);
