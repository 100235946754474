import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Moment from "moment";

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      notifications: [],
    };
  }

  componentDidMount() {
    //let udata = JSON.parse(sessionStorage.getItem("userData"));
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    if (udata) {
      axios
        .get(
          window.BASE_API_URL +
            "public/users/get_user_notifications/" +
            udata.user.user_id
        )
        .then((response) => {
          this.setState({
            notifications: response.data,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    //let udata = JSON.parse(sessionStorage.getItem("userData"));
    let udata = [];

    if (this.props.userData.length > 0) {
      udata = this.props.userData[0].userData;
    }

    let user = [];
    if (udata) {
      user = udata.user;
    }

    if (this.state.notifications) {
      return (
        <div>
          {this.state.notifications &&
            this.state.notifications.map((note, index) => (
              <div className="notification-box" key={"notig-" + index}>
                <div className="user-img">
                  <img
                    src={user.profile_photo}
                    className="img-responsive"
                    alt=""
                  />
                </div>
                <div className="notification-info">
                  <h6>
                    {note.note_date &&
                      Moment(note.note_date, "YYYY-MM-DD HH:II:SS").format(
                        "LT - DD MMM YYYY"
                      )}
                  </h6>
                  {note.title && (
                    <h5 style={{ fontSize: "1.1em", marginBottom: 0 }}>
                      {note.title}
                    </h5>
                  )}
                  <p dangerouslySetInnerHTML={{ __html: note.description }}></p>
                </div>
              </div>
            ))}
        </div>
      );
    } else {
      return (
        <div>
          <div classNames="notification-box">
            <div classNames="notification-info">
              <p>No new notification for you</p>
            </div>
          </div>
        </div>
      );
    }
  }
}

Notifications.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Notifications);
