import React, { Component } from "react";
import Swiper from "swiper";

export class HomeSlider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      slides: this.props.slides
        ? this.props.slides
        : [require("../../assets/images/banner-default-slide.jpg")],
      virtualData: {
        slides: [],
      },
    };
  }

  componentDidMount() {
    const self = this;
    const swiper = new Swiper(".home-slider", {
      autoplay: {
        delay: 3000,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      virtual: {
        slides: self.state.slides,
        renderExternal(data) {
          self.setState({
            virtualData: data,
          });
        },
      },
    });
  }

  render() {
    const slides = this.props.slides;

    if (slides) {
      return (
        <>
          <div className="swiper-container home-slider">
            <div className="swiper-wrapper">
              {slides.map((slide, index) => (
                <div
                  className="swiper-slide"
                  key={index}
                  style={{ left: `${this.state.virtualData.offset}px` }}
                >
                  <img src={slide} alt="" />
                </div>
              ))}
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="swiper-container home-slider">
            <div className="swiper-wrapper">
              {this.state.slides.map((slide, index) => (
                <div
                  className="swiper-slide"
                  key={index}
                  style={{ left: `${this.state.virtualData.offset}px` }}
                >
                  <img src={slide} alt="" />
                </div>
              ))}
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </>
      );
    }
  }
}

export default HomeSlider;
