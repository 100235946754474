import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import UserDetailsCard from "../templates/UserDetailsCard";
import HomeSlider from "../templates/HomeSlider";
import HomeFeaturedArticles from "../templates/HomeFeaturedArticles";
import FeaturedProfile from "../templates/FeaturedProfile";
import "../../assets/css/swiper.min.css";

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: true,
      userInfo: this.props.userData ? this.props.userData : [],
      homeData: [],
      articles: [],
    };
  }

  componentDidMount() {
    let udata = [];
    let paramData = {};

    if (this.props.userData.length) {
      udata = this.props.userData[0].userData;
      this.setState({ isLoggedIn: true, userInfo: udata });
      paramData.user_id = udata.user.user_id;
    } else {
      this.setState({ isLoggedIn: false });
    }

    axios
      .get(window.BASE_API_URL + "public/home/get_data", {
        params: paramData,
      })
      .then((response) => {
        this.setState({
          homeData: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    window.scrollTo(0, 0);

    console.log(process.env);
  }

  render() {
    return (
      <div>
        <ul className="mobile-menu-icon">
          <li>
            <Link
              to={{
                pathname: "/search",
                search: "?type=new",
                hash: "topbar",
              }}
            >
              <img
                src={require("../../assets/images/dress2-mm-b.png")}
                alt="J’did"
                className="blue"
              />
              <span>J'did</span>
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: "/search",
                search: "?type=used",
                hash: "topbar",
              }}
            >
              <img
                src={require("../../assets/images/dress1-mm-b.png")}
                alt="Ocaz"
                className="pink"
              />
              <span>Ocaz</span>
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: "/search",
                search: "?sort=most_recent",
                hash: "topbar",
              }}
            >
              <img
                src={require("../../assets/images/dress4-mm-b.png")}
                alt="Nouveautés"
                className="green"
              />
              <span>Nouveautés</span>
            </Link>
          </li>
          <li style={{ width: "25.5%", marginLeft: "-3%" }}>
            <Link
              to={{
                pathname: "/mostlikedarticles",
                search: "?sort=most_loved",
                hash: "topbar",
              }}
            >
              <img
                src={require("../../assets/images/heart-mm-b.png")}
                alt="Coups de cœur"
                className="orange"
              />
              <span>Coups de cœur</span>
            </Link>
          </li>
        </ul>

        <div className="banner-home">
          <HomeSlider
            slides={this.state.homeData.banners && this.state.homeData.banners}
          />
        </div>

        <div className="features-block">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="feature-block feature-phara-1">
                  <div className="feature-icon feature-icon1">&nbsp;</div>
                  <p>
                    {this.state.homeData.total_articles
                      ? this.state.homeData.total_articles
                      : 0}{" "}
                    Articles En Ventes
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="feature-block feature-phara-2">
                  <div className="feature-icon feature-icon2">&nbsp;</div>
                  <p>
                    {this.state.homeData.total_vendors
                      ? this.state.homeData.total_vendors
                      : 0}{" "}
                    Vendeurs
                  </p>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="feature-block brd-none feature-phara-3">
                  <div className="feature-icon feature-icon3">&nbsp;</div>
                  <p>0% Commission</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-10">
              {this.state.homeData.ads && this.state.homeData.ads.mobile_l_1 ? (
                <div className="mobile-offer">
                  {this.state.homeData.ads.mobile_l_1.type == "image" && (
                    <img
                      src={this.state.homeData.ads.mobile_l_1.image}
                      alt="speed-clock"
                    />
                  )}
                  {this.state.homeData.ads.mobile_l_1.type == "google" &&
                    this.state.homeData.ads.mobile_l_1.ad_code}
                </div>
              ) : null}
              <div className="top-filter" style={{ display: "none" }}>
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 plr8">
                    <div className="search-input-block form-group">
                      <input type="text" placeholder="Search Product Name..." />
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 plr8">
                    <div className="form-group select-block">
                      <select>
                        <option>Main Categories</option>
                        <option>Categories 1</option>
                        <option>Categories 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 plr8">
                    <div className="form-group select-block">
                      <select>
                        <option>Product Categories</option>
                        <option>Categories 1</option>
                        <option>Categories 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 plr8">
                    <div className="form-group select-block">
                      <select>
                        <option>Sub Categories</option>
                        <option>Categories 1</option>
                        <option>Categories 2</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-2 col-lg-1 col-xl-1 plr8">
                    <div className="search-input-block form-group">
                      <input type="text" placeholder="Select Location" />
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2 plr8">
                    <div className="search-input-block form-group">
                      <input type="text" placeholder="Select Location" />
                    </div>
                  </div>
                  <div className="col-6 col-sm-6 col-md-2 col-lg-1 col-xl-1 plr8">
                    <div className="listing-btns">
                      <a href="listing.html">
                        <button className="order-now-btn">Search</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="boutique-main-section mmlr">
                <h3 className="text-center" style={{ marginTop: "20px" }}>
                  <Link className="disabled-link" to={"/home"}>
                    Notre sélection vendeurs
                  </Link>
                </h3>
                <div className="devider"></div>
                <div className="row">
                  {this.state.homeData.featured_profiles &&
                    this.state.homeData.featured_profiles.map(
                      (userData, index) => (
                        <div
                          className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3 mplr"
                          key={"feat-prof-" + index}
                        >
                          <FeaturedProfile profileData={userData} />
                        </div>
                      )
                    )}
                </div>
              </div>

              <div className="mobile-offer">
                {this.state.homeData.ads &&
                this.state.homeData.ads.mobile_l_2 ? (
                  <div className="mobile-offer">
                    {this.state.homeData.ads.mobile_l_2.type == "image" && (
                      <img
                        src={this.state.homeData.ads.mobile_l_2.image}
                        alt="speed-clock"
                      />
                    )}
                    {this.state.homeData.ads.mobile_l_2.type == "google" &&
                      this.state.homeData.ads.mobile_l_2.ad_code}
                  </div>
                ) : null}
              </div>

              <h3 className="text-center">
                <Link
                  to={{
                    pathname: "/search",
                    search: "?type=new",
                    hash: "topbar",
                  }}
                >
                  Découvrez les articles disponibles
                </Link>
              </h3>
              <p className="text-center">
                {this.state.homeData.total_articles
                  ? this.state.homeData.total_articles
                  : 0}{" "}
                Articles disponibles
              </p>
              <div className="devider"></div>
              <div className="product-box mmlr">
                {this.state.articles && (
                  <HomeFeaturedArticles articles={this.state.articles} />
                )}
              </div>
            </div>
            <div className="hide-991 col-sm-12 col-md-2 col-lg-2">
              {this.state.homeData.ads &&
              this.state.homeData.ads.sidebar_l_1 ? (
                <div className="add-box text-center">
                  {this.state.homeData.ads.sidebar_l_1.type == "image" && (
                    <img
                      src={this.state.homeData.ads.sidebar_l_1.image}
                      alt="ad"
                    />
                  )}
                  {this.state.homeData.ads.sidebar_l_1.type == "google" &&
                    this.state.homeData.ads.sidebar_l_1.ad_code}
                </div>
              ) : null}

              {this.state.homeData.ads &&
              this.state.homeData.ads.sidebar_l_2 ? (
                <div className="add-box text-center">
                  {this.state.homeData.ads.sidebar_l_2.type == "image" && (
                    <img
                      src={this.state.homeData.ads.sidebar_l_2.image}
                      alt="ad"
                    />
                  )}
                  {this.state.homeData.ads.sidebar_l_2.type == "google" &&
                    this.state.homeData.ads.sidebar_l_2.ad_code}
                </div>
              ) : null}

              {this.state.homeData.ads &&
              this.state.homeData.ads.sidebar_l_3 ? (
                <div className="add-box text-center">
                  {this.state.homeData.ads.sidebar_l_3.type == "image" && (
                    <img
                      src={this.state.homeData.ads.sidebar_l_3.image}
                      alt="ad"
                    />
                  )}
                  {this.state.homeData.ads.sidebar_l_3.type == "google" &&
                    this.state.homeData.ads.sidebar_l_3.ad_code}
                </div>
              ) : null}

              {this.state.homeData.ads &&
              this.state.homeData.ads.sidebar_l_4 ? (
                <div className="add-box text-center">
                  {this.state.homeData.ads.sidebar_l_4.type == "image" && (
                    <img
                      src={this.state.homeData.ads.sidebar_l_4.image}
                      alt="ad"
                    />
                  )}
                  {this.state.homeData.ads.sidebar_l_4.type == "google" &&
                    this.state.homeData.ads.sidebar_l_4.ad_code}
                </div>
              ) : null}

              {this.state.homeData.ads &&
              this.state.homeData.ads.sidebar_l_5 ? (
                <div className="add-box text-center">
                  {this.state.homeData.ads.sidebar_l_5.type == "image" && (
                    <img
                      src={this.state.homeData.ads.sidebar_l_5.image}
                      alt="ad"
                    />
                  )}
                  {this.state.homeData.ads.sidebar_l_5.type == "google" &&
                    this.state.homeData.ads.sidebar_l_5.ad_code}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {
  userData: PropTypes.array.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, {})(Home);
