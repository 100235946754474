import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import { Formik } from "formik";
import * as Yup from "yup";
import Error from "../templates/Errors";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FacebookLoginButton from "../FacebookLoginButton";
import { addFlashMessage } from "../../actions/flashMessages";
import { addUserData } from "../../actions/userActions";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("l’adresse mail n’est pas valide")
    .required("")
    .max(255, "Must be shorter than 255 characters"),
  password: Yup.string().required(""),
});

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      serverError: "",
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { userData } = this.props;

    if (this.state.isLoggedIn === true) {
      return <Redirect to="/home" />;
      //window.location.href = "/";
    }
    console.log(userData);
    if (userData.length > 0) {
      //sessionStorage.getItem("userData")) {
      return <Redirect to="/home" />;
    }

    return (
      <div>
        <div className="sin-up-wrapper login">
          <div className="container">
            <div className="sin-up-content-block">
              <div className="sin-up-head">Se connecter</div>
              <div className="sin-up-sub">
                Veuillez vous connecter à votre compte.
              </div>
              <Formik
                initialValues={{
                  email: "",
                  password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setSubmitting(true);
                  this.setState({ serverError: "" });
                  let self = this;
                  axios
                    .post(
                      window.BASE_API_URL + "public/users/validate_auth",
                      values
                    )
                    .then((response) => {
                      if (response.data.status == "ok") {
                        resetForm();
                        /* sessionStorage.setItem(
                          "userData",
                          JSON.stringify(response.data)
                        ); */

                        self.setState({ isLoggedIn: true });

                        /* this.props.addFlashMessage({
                          type: "success",
                          message: "Welcome!",
                        }); */

                        this.props.addUserData({
                          userData: response.data,
                        });
                      } else if (response.data.status == "err") {
                        setSubmitting(false);
                        this.setState({
                          serverError:
                            "L'email ou le mot de passe est invalide",
                        });
                        console.log(response.data);
                      }
                      console.log(response);
                    })
                    .catch((err) => {
                      //this.setState({ serverError: err });
                      console.log(err);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit}>
                    {this.state.serverError && (
                      <div className="alert alert-danger">
                        {this.state.serverError}
                      </div>
                    )}
                    <div className="form-group">
                      <div className="form-group-label">
                        Adresse mail<span className="labe-red-top">*</span>
                      </div>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Adresse mail"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <Error touched={touched.email} message={errors.email} />
                    </div>
                    <div className="form-group">
                      <div className="form-group-label">
                        Mot de passe<span className="labe-red-top">*</span>
                      </div>
                      <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Mot de passe"
                        value={values.password}
                        onChange={handleChange}
                      />
                      <Error
                        touched={touched.password}
                        message={errors.password}
                      />
                    </div>
                    {/* <div className="check-box">
                      <p>
                        <input
                          id="filled-in-box"
                          className="filled-in"
                          type="checkbox"
                        />
                        <label for="filled-in-box">Remember me</label>
                      </p>
                    </div> */}
                    <Link to={"/forgotpassword"} className="forget-pw">
                      Mot de passe oublié?
                    </Link>
                    <div className="clearfix"></div>
                    <div className="sign-up-button-main">
                      <button
                        className="sign-up-butt"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        SE CONNECTER
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
              <div className="dont-have-account">
                Ou
                <ul className="social-login no-list">
                  <li>
                    <FacebookLoginButton />
                    {/* <a className="facebook" href="#">
                    <i className="fab fa-facebook"></i>SE CONNECTER AVEC
                    FACEBOOK
                  </a> */}
                  </li>
                </ul>
              </div>
              <div className="clearfix">&nbsp;</div>
              <div className="clearfix">
                <Link
                  to={"/register"}
                  className="forget-pw"
                  style={{ fontSize: "14px" }}
                >
                  Pas encore inscrit(e)?? créer un compte.
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  userData: PropTypes.array.isRequired,
  addFlashMessage: PropTypes.func.isRequired,
  addUserData: PropTypes.func.isRequired,
};

const stateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

export default connect(stateToProps, { addFlashMessage, addUserData })(Login);
